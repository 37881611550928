export const MediumTypes = {
  TYPE_PRINT: 'Print',
  TYPE_DIGITAL: 'Digital',
  TYPE_SOCIAL: 'Social',
  TYPE_STREET_TEAM: 'Street Team',
  TYPE_OOO: 'Out of Home'
};

export const ReachTypes = {
  POPULATION: 'Population'
};
export const MediakitLevel = {
  MEDIUM: 'MEDIUM',
  PROPERTY: 'PROPERTY',
  COLLECTION: 'COLLECTION',
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT'
};

export const MediakitLevelValues = {
  MEDIUM: 'App\\Models\\Mediakit\\Medium\\MediumType',
  PROPERTY: 'App\\Models\\Mediakit\\Property\\PropertyType',
  COLLECTION: 'App\\Models\\Mediakit\\Collection\\CollectionType',
  PRODUCT: 'App\\Models\\Mediakit\\Product\\ProductType',
  VARIANT: 'App\\Models\\Mediakit\\ProductVariant\\ProductVariantType'
};

export const MediakitLevelInfo = {
  MEDIUM: {
    label: 'Medium'
  },
  PROPERTY: {
    label: 'Property'
  },
  COLLECTION: {
    label: 'Collection'
  },
  PRODUCT: {
    label: 'Product'
  },
  VARIANT: {
    label: 'Variant'
  }
};

export const MediakitLevelSelections = Object.values(MediakitLevel).map(
  level => ({
    key: level,
    value: MediakitLevelValues[level],
    label: MediakitLevelInfo[level].label
  })
);
