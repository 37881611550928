import { getJsonItem, setJsonItem } from '@/utils/helpers';

const LOCAL_STORAGE_TAKEOVER_BUYER_ID = 'takeover-buyer-id';
const LOCAL_STORAGE_SUBMITTED_ORDER = 'submitted-order';
const AD_SHOP_CART_ID = 'ad-shop-cart-id';

const state = {
  adShopThemeColor: null,
  campaignName: null,
  adShopCartId: localStorage.getItem(AD_SHOP_CART_ID),
  takeoverBuyerId: localStorage.getItem(LOCAL_STORAGE_TAKEOVER_BUYER_ID),
  slug: null,
  submittedOrder: getJsonItem(LOCAL_STORAGE_SUBMITTED_ORDER)
};

const mutations = {
  setAdShopCartId(state, id) {
    state.adShopCartId = id;

    if (id) {
      localStorage.setItem(AD_SHOP_CART_ID, id);
    } else {
      localStorage.removeItem(AD_SHOP_CART_ID);
    }
  },
  setTakeoverBuyerId(state, id) {
    state.takeoverBuyerId = id;

    if (id) {
      localStorage.setItem(LOCAL_STORAGE_TAKEOVER_BUYER_ID, id);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_TAKEOVER_BUYER_ID);
    }
  },
  setSubmittedOrder(state, order) {
    state.submittedOrder = order;

    if (order) {
      setJsonItem(LOCAL_STORAGE_SUBMITTED_ORDER, order);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_SUBMITTED_ORDER);
    }
  },
  setAdShopThemeColor(state, color) {
    state.adShopThemeColor = color;
  },
  setCampaignName(state, campaign) {
    state.campaignName = campaign;
  },
  setSlug(state, slug) {
    state.slug = slug;
  }
};

const getters = {
  adShopCartId: state => {
    return state.adShopCartId;
  },
  adShopThemeColor: state => {
    return state.adShopThemeColor;
  },
  campaignName: state => {
    return state.campaignName;
  },
  slug: state => {
    return state.slug;
  },
  submittedOrder: state => {
    return state.submittedOrder;
  },
  takeoverBuyerId: state => {
    return state.takeoverBuyerId === 'null' ? null : state.takeoverBuyerId;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
