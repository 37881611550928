<template>
  <div class="account-info-item">
    <div v-if="name" :class="{ 'primary-item': !!name }">{{ name }}</div>
    <div :class="{ 'primary-item': !name }">{{ email }}</div>

    <el-button
      class="account-button"
      type="primary"
      size="medium"
      plain
      @click="$emit('click-account-button')"
    >
      My Account
    </el-button>

    <div class="mt-2 text-center">
      <a v-if="!isLoggingOut" @click="logout">Log Out</a>
      <div v-else>Logging Out...</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      isLoggingOut: false
    };
  },

  methods: {
    async logout() {
      this.isLoggingOut = true;
      await this.$store.dispatch('auth/logout');
      this.$router.replace('/auth/login');
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.account-info-item {
  width: 100%;
  padding: 1.5em;

  .primary-item {
    font-weight: bold;
    font-size: 1.15em;
  }

  .account-button {
    width: 100%;
    margin-top: 1em;
  }
}
</style>
