
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RateSheetInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RateSheet"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_default"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"date_start"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"date_end"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quantity"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"rates"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RateInfo"},"directives":[]}]}}]}}],"loc":{"start":0,"end":168}};
    doc.loc.source = {"body":"#import \"./RateInfoFragment.graphql\"\n\nfragment RateSheetInfo on RateSheet {\n  id\n  type\n  is_default\n  date_start\n  date_end\n  quantity\n  rates {\n    ...RateInfo\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./RateInfoFragment.graphql").definitions));


      module.exports = doc;
    
