
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DigitalChecklistAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DigitalChecklist"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isSynced"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isVariantSynced"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"impressions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"variantExists"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"variantEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"productEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"collectionEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isActiveStatus"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"creativeStatus"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":212}};
    doc.loc.source = {"body":"fragment DigitalChecklistAttributes on DigitalChecklist {\n  id\n  isSynced\n  isVariantSynced\n  impressions\n  variantExists\n  variantEnabled\n  productEnabled\n  collectionEnabled\n  isActiveStatus\n  creativeStatus\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
