<script>
import {
  copyProduct,
  createProduct,
  deleteProduct,
  updateProduct
} from '@/graphql/mediakit/productMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],
  props: {
    collectionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isCopying: false,
      isDeleting: false
    };
  },

  mutation() {
    return {
      mutations: {
        createProduct(input) {
          input = this.mapInput(input);
          input.collection_id = this.collectionId;

          return this.post(createProduct, { input });
        },
        updateProduct(product, input) {
          input = this.mapInput(input);

          // Optimistic response is challenging here because we're overloading typeEdges...
          // Need to refactor the way we're saving products / variants

          return this.post(updateProduct, { id: product.id, input });
        },
        async copyProduct(product, input) {
          input = this.mapInput(input);

          this.isCopying = true;
          await this.post(copyProduct, { id: product.id, input });
          this.isCopying = false;
        },
        async deleteProduct(id) {
          this.isDeleting = true;

          try {
            await this.post(deleteProduct, { id });
          } catch (e) {
            this.isDeleting = false;
            throw e;
          }
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        collectionId: this.collectionId
      }
    };
  },

  methods: {
    mapInput(product) {
      const fields = {
        collection_id: String,
        campus_id: String,
        sku: String,
        name: String,
        is_enabled: Boolean,
        auto_inject: JSON,
        typeEdges: {
          _map: type => type.type.value
        },
        description: String,
        primary_photo_id: String,
        photos: {
          _map: photo => photo.id
        },
        additionalFiles: {
          _map: file => file.id
        },
        additional_information: String,
        additional_attribute_columns: Array,
        fulfillmentMethods: {
          _map: fm => fm.id
        },
        variants: {
          id: String,
          sku: String,
          migrateSku: Boolean,
          name: String,
          is_enabled: Boolean,
          is_bookable_ad_shop: Boolean,
          is_read: Boolean,
          rates: JSON,
          quantity: Number,
          unit: {
            id: String,
            name: String
          },
          attributes: JSON,
          enabled_rate_classes: Array,
          rateSheets: {
            id: String,
            type: String,
            date_start: Date,
            date_end: Date,
            is_default: Boolean,
            quantity: Number,
            rates: {
              id: String,
              rate_class: String,
              quantity: Number,
              price: Number
            }
          }
        }
      };

      // Enable Super fields if allowed by this user
      if (this.$can('manage_super_objects')) {
        fields.is_super = Boolean;
        fields.variants.is_super = Boolean;
      }

      return this.castFields(product, fields);
    }
  }
};
</script>
