import Fingerprint from 'fingerprintjs2';
import { cookie } from '@/utils/cookie';
import { sleep } from '@/utils/helpers';

export async function getFingerprint() {
  await sleep(3000);

  Fingerprint.get(components => {
    const murmur = Fingerprint.x64hash128(
      components
        .map(function(pair) {
          return pair.value;
        })
        .join(),
      36
    );

    cookie('fingerprint', murmur);
  });
}

// TODO: Need to implement this if we get rid of cookies (or no longer trust them to track users)
export async function sessionHeaders() {
  return {
    'session-UUID': cookie('session-uuid'),
    fingerprint: await getFingerprint()
  };
}
