import {
  currency,
  date,
  datetime,
  month,
  percent,
  shortCurrency,
  week,
  year
} from '@/utils/filters';

export const ReportType = { SUMMARY: 'Summary', DETAILS: 'Details' };

export const ReportAggregate = {
  COUNT: 'COUNT',
  DATE: 'DATE',
  GROUP_CONCAT: 'GROUP_CONCAT',
  MONTH: 'MONTH',
  NONE: 'NONE',
  SUM: 'SUM',
  WEEK: 'WEEK',
  YEAR: 'YEAR'
};

export const ReportFormat = {
  CURRENCY: 'currency',
  DATE: 'date',
  DATETIME: 'datetime',
  FLOAT: 'float',
  INTEGER: 'integer',
  MONTH: 'month',
  PERCENT: 'percent',
  WEEK: 'week',
  YEAR: 'year'
};

export const CountableReportFormats = [
  ReportFormat.CURRENCY,
  ReportFormat.INTEGER,
  ReportFormat.FLOAT
];

export const ReportExportDimensions = [
  {
    value: {
      keyField: { key: 'customer.id', value: 'customer_id' },
      labelField: {
        key: 'CONCAT(customer.buyer_id, " - ", customer__buyer.name)',
        value: 'name'
      },
      relations: ['customer.buyer']
    },
    label: 'Customers'
  },
  {
    value: {
      keyField: { key: 'customer.rep.id', value: 'rep_id' },
      labelField: { key: 'customer.rep.email', value: 'rep_email' }
    },
    label: 'Customer Reps'
  }
];

export function formatField(value, format) {
  if (typeof format === 'function') {
    return format(value);
  }

  switch (format) {
    case ReportFormat.CURRENCY:
      return this.settings.isShortCurrency
        ? shortCurrency(value)
        : currency(value);

    case ReportFormat.DATE:
      return date(value);

    case ReportFormat.WEEK:
      return week(value);

    case ReportFormat.MONTH:
      return month(value);

    case ReportFormat.YEAR:
      return year(value);

    case ReportFormat.DATETIME:
      return datetime(value);

    case ReportFormat.PERCENT:
      return percent(value);

    default:
      return value;
  }
}
