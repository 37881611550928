<script>
import { getSuppliers } from '@/graphql/supplier/getSuppliers.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    pager: {
      type: Object,
      required: true
    }
  },

  query(props) {
    return {
      query: getSuppliers,
      variables: {
        ...props.pager
      }
    };
  }
};
</script>
