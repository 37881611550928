import Vue from 'vue';
import { findIndex, intersection as arrayIntersection } from 'lodash';
import { download } from '@/vendor/download';

window._env.API_URL =
  window._env.APP_VERSION === 'local-version'
    ? window.location.protocol + '//' + window.location.host
    : process.env.VUE_APP_API_URL;

Vue.prototype.$console = window.console;
Vue.prototype.$apiUrl = window._env.API_URL || '';

export const UrlParamFormat = {
  JSON: 'json',
  BASE64: 'base64',
  BASE64_JSON: 'base64_json',
  TEXT: 'text'
};

export function urlSafeBase64Decode(str) {
  return atob(str.replace('-', '+').replace('_', '/'));
}

/**
 * Gets a Query String parameter of the current request URL
 *
 * @param key
 * @param format
 * @returns {string|string[]}
 */
export function urlParams(key, format) {
  let params = new URLSearchParams(location.search);

  if (key) {
    let value = params.get(key);

    let decoded;

    try {
      switch (format) {
        case UrlParamFormat.BASE64:
          return urlSafeBase64Decode(value);

        case UrlParamFormat.JSON:
          return JSON.parse(value);

        case UrlParamFormat.BASE64_JSON:
          decoded = urlSafeBase64Decode(value);

          return decoded ? JSON.parse(decoded) : decoded;

        default:
          return value;
      }
    } catch (e) {
      // Just ignore the error for URL params
      return null;
    }
  } else {
    return params.getAll();
  }
}

/**
 * Sleep function to be used in conjuction with async await:
 *
 * eg: await sleep(5000);
 *
 * @param delay
 * @returns {Promise<any>}
 */
export function sleep(delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

/**
 * A standard way to render a renderless VueJS component
 *
 * @param createElement
 * @param props
 * @returns {*}
 */
export function renderless(createElement, props) {
  if (this.$scopedSlots.default) {
    let elements = this.$scopedSlots.default(props);

    if (Array.isArray(elements)) {
      return createElement('div', elements);
    } else {
      return elements;
    }
  }
}

/**
 * Returns an object containing all the keys that exist in both objects
 *
 * @param o1
 * @param o2
 * @returns {string[]}
 */
export function intersection(o1, o2) {
  let intersection = {};

  let keys = arrayIntersection(Object.keys(o1), Object.keys(o2));

  for (let key of keys) {
    intersection[key] = o1[key];
  }

  return intersection;
}

/**
 * Returns the first element in an object
 * (typically used to grab an element in an object when you know there is only one element)
 * REMEMBER: Objects in javascript are unordered so do not rely on the order of the elements
 * in the object
 *
 * @param obj
 * @returns {*}
 */
export function firstElement(obj) {
  return obj[Object.keys(obj)[0]];
}

/**
 * Returns the first key in an object
 *
 * @param {Object} obj
 * @returns {String}
 */
export function firstKey(obj) {
  return Object.keys(obj)[0];
}

/**
 * Transform each entry of an Object into a different value
 *
 * @param obj
 * @param mapFn
 * @returns {any}
 */
export function objectMap(obj, mapFn) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => mapFn(key, value))
  );
}

/**
 * Removes the element from the array in a way that is reactive for VueJS
 *
 * @param array
 * @param e - the element to remove
 * @returns {*|T[]}
 */
export function remove(array, e) {
  let index = findIndex(array, e);

  if (index >= 0) {
    return array.splice(index, 1)[0];
  }

  return false;
}

/**
 * Convert a string to camelCase
 *
 * @param str
 * @returns {*}
 */
export function toCamelCase(str) {
  return str
    .replace(/[^A-Z0-9\s-_]/i, '')
    .replace(/^([A-Z])|[\s-_](\w)/g, function(match, p1, p2) {
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();
    });
}

/**
 * Display an Element UI error message
 *
 * @param msg
 * @param options
 */
export function error(msg, options) {
  Vue.prototype.$message.error({
    message: msg,
    duration: 7000,
    showClose: true,
    ...options
  });
}

/**
 * Catch an error, log to the console and display Element UI error message
 *
 * @param e
 * @param msg
 * @param options
 */
export function catchError(e, msg, options) {
  let errorMsg = msg || e.message;

  // TODO: should probably make this more robust...
  errorMsg = errorMsg.replace(/^GraphQL error: /i, '');

  error(errorMsg, options);

  // TODO we may want to disable reporting caught messages in production?
  console.error(e);
}

/**
 * Formats a GraphQL Error Message to make it human readable
 *
 * @param e
 * @returns {*}
 */
export function formatGraphQLError(e) {
  let message = e.message.replace(/^GraphQL error: /, ''); // Don't need the users knowing we use GraphQL

  // Attempt to parse as JSON
  let errorsJson = tryParseJSON(message);
  if (errorsJson) {
    // Concatenate messages with newlines
    message = '';
    errorsJson.forEach(function(messageElement) {
      let messageLine =
        typeof messageElement === 'string'
          ? messageElement
          : messageElement.message;

      message += messageLine + '\n';
    });
  }

  return message;
}

/**
 * Returns an array of GraphQL Error Messages and optionally filters them by Category Type
 *
 * @param graphQLErrors
 * @param type
 * @returns {null|*}
 */
export function getGraphQLErrorMessages(graphQLErrors, type) {
  if (graphQLErrors) {
    // Return the list of error messages from a the list of GraphQL Errors w/ category DataValidationWarning
    const errors = graphQLErrors
      .map(e => {
        if (type) {
          if (e.extensions.category === type) {
            return e.message;
          }
        } else {
          return e.message;
        }
      })
      .filter(e => !!e);

    return errors.length > 0 ? errors : null;
  }

  return null;
}

/**
 * Attempts to safely parse a JSON object, returns false if unsuccessful
 *
 * @param jsonString
 * @returns {*}
 */
export function tryParseJSON(jsonString) {
  try {
    let o = JSON.parse(jsonString);

    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    // do nothing
  }

  return false;
}

/**
 * Reads the raw data from an image file, when it is ready it resolves with the reader
 * Note: Set reader.result as the src of an <img> tag to render the preview
 *
 * @param file File
 * @returns {Promise<any>}
 */
export function previewImg(file) {
  return new Promise(resolve => {
    let reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        resolve(reader);
      },
      false
    );

    reader.readAsDataURL(file);
  });
}

/**
 * Gets the file type
 * @param file
 */

export function getImageType(file) {
  let type = '';

  if (file.mime) {
    type = file.mime.split('/').pop();
  } else if (file.filename) {
    type = file.filename.split('.').pop();
  }

  return type;
}

export function range(start, end) {
  const range = [];
  const ascending = start < end;
  const length = Math.abs(start - end) + 1;

  for (var i = 0; i < length; i++) {
    range.push(i + (ascending ? start : end));
  }

  return ascending ? range : range.reverse();
}

export function elementHasExitedParentsViewport(
  el,
  parent,
  scrollTop,
  previousScrollTop,
  offsetPercentage = 0.3
) {
  const elRect = el.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();
  const isScrollingUp = scrollTop < previousScrollTop;

  const hasExitedGoingUp =
    elRect.bottom < parentRect.top + offsetPercentage * elRect.height ||
    scrollTop === 0;
  const hasExitedGoingDown = elRect.top > parentRect.top + 10;

  return isScrollingUp ? hasExitedGoingDown : hasExitedGoingUp;
}

/**
 * Downloads a file from a response object w/ a file attachment
 *
 * @param response
 * @param filename
 * @returns {XMLHttpRequest | boolean}
 */
export function downloadFileResponse(response, filename = '') {
  const contentDisposition = getResponseHeader(
    response,
    'content-disposition',
    ''
  );

  const contentType = getResponseHeader(response, 'content-type', '');

  const match = contentDisposition.match(/filename="([^"]+)"/);

  filename = filename || (match && match[1]) || 'download.pdf';

  return download(response.data || response.blob(), filename, contentType);
}

export function getResponseHeader(response, header, defaultValue) {
  if (response.headers) {
    if (typeof response.headers.get === 'function') {
      return response.headers.get(header) || defaultValue;
    } else {
      return response.headers[header] || defaultValue;
    }
  }
}

/**
 * Resolve the optimized transcoded file URL if it is available
 * @param file
 * @param returnObject If true, returns the whole file object instead of the URL
 * @returns {null|*}
 */
export function optimizedFile(file, returnObject) {
  if (file && typeof file === 'object') {
    let optimized = file;

    if (file.transcodes) {
      if (file.transcodes.compress) {
        optimized = file.transcodes.compress;
      } else if (file.transcodes.mp4) {
        optimized = file.transcodes.mp4;
      }
    }

    return returnObject ? optimized : optimized.url;
  }

  return file;
}
