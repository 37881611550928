<script>
import Vue from 'vue';
import { getUserNotifications } from '@/graphql/user/getUserNotifications.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default Vue.component('UserNotificationsQuery', {
  mixins: [QueryHelper],
  props: {
    pager: { type: Object, default: null }
  },
  query(props) {
    return {
      query: getUserNotifications,
      variables: {
        ...props.pager
      }
    };
  }
});
</script>
