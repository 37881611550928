import { attention, cancel, clock, disable, ok } from '@/vendor/icons';

export const CreativeStatus = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING',
  PENDING_BUYER: 'PENDING_BUYER',
  PENDING_SUPPLIER: 'PENDING_SUPPLIER',
  REJECTED: 'REJECTED'
};

export const CreativeStatusIcons = {
  ACCEPTED: {
    icon: ok,
    class: 'text-green hover:text-dark-green',
    name: 'Accepted'
  },

  PENDING: {
    icon: attention,
    class: 'text-yellow hover:text-dark-yellow',
    name: 'Pending Creative'
  },

  PENDING_BUYER: {
    icon: clock,
    class: 'text-blue hover:text-dark-blue',
    name: 'Pending Buyer Acceptance'
  },

  PENDING_SUPPLIER: {
    icon: clock,
    class: 'text-blue hover:text-dark-blue',
    name: 'Pending Publisher Acceptance'
  },

  REJECTED: {
    icon: disable,
    class: 'text-red hover:text-dark-red',
    name: 'Rejected'
  }
};

export const CreativeStatusInfo = {
  EMPTY: {
    icon: clock,
    class: 'text-dark-silver',
    name: 'Awaiting Upload'
  },
  ACCEPTED: {
    icon: clock,
    class: 'text-dark-silver',
    name: 'Accepted'
  },
  PENDING: {
    icon: ok,
    class: 'text-yellow',
    name: 'Pending',
    warning: true
  },
  PENDING_BUYER: {
    class: 'text-yellow',
    name: 'Pending Buyer Review',
    warning: true
  },
  PENDING_SUPPLIER: {
    class: 'text-yellow',
    name: 'Pending Publisher Review',
    warning: true
  },
  REJECTED: {
    icon: cancel,
    class: 'text-red',
    name: 'Rejected',
    warning: true
  }
};
