<script>
import {
  applyMediakitTemplate,
  registerSupplier,
  updateSupplierAccount
} from '@/graphql/supplier/supplierMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        // eslint-disable-next-line
        registerSupplier(SupplierRegistrationInfo) {
          return this.post(registerSupplier, {
            input: SupplierRegistrationInfo
          });
        },
        // eslint-disable-next-line
        updateSupplierAccount(input) {
          return this.post(updateSupplierAccount, { input });
        },
        applyMediakitTemplate(supplierId) {
          this.post(applyMediakitTemplate, {
            supplier_id: supplierId || this.supplier.id
          });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  },

  computed: {
    supplier() {
      return this.$store.getters['auth/supplier'];
    }
  }
};
</script>
