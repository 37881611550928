
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeatureAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Feature"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_enabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeatureInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Feature"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeatureAttributes"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"featurables"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FeaturableAttributes"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeaturableAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Featurable"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"featurable_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"featurable_type"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":271}};
    doc.loc.source = {"body":"fragment FeatureAttributes on Feature {\n  id\n  name\n  is_enabled\n  created_at\n}\n\nfragment FeatureInfo on Feature {\n  ...FeatureAttributes\n  featurables {\n    ...FeaturableAttributes\n  }\n}\n\nfragment FeaturableAttributes on Featurable {\n  featurable_id\n  featurable_type\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
