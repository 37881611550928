import AccountingRoutes from './accounting';
import AdminRoutes from './admin';
import AdShopRoutes from './adshop';
import AuthRoutes from './auth';
import BuyerRoutes from '@/modules/Buyer/routes';
import DemoRoutes from '@/modules/Demo/routes';
import FullPageRoutes from './fullPage';
import PageNotFound from '@/components/Core/PageNotFound';
import PreviewDigitalInstall from '@/components/Core/PreviewDigitalInstall';
import SupplierRoutes from './supplier';
import v4Routes from './v4';
import Vue from 'vue';
import store from '@/store';
import { clearAuthorizationToken } from '@/utils/authorization';

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAdmin']) {
        next({ name: 'admin' });
      } else if (store.getters['auth/isSupplier']) {
        next({
          name: 'supplier.dashboard'
        });
      } else if (store.getters['auth/isBuyer']) {
        next({ name: 'buyer.dashboard' });
      } else if (store.getters['auth/isAuthenticatedGuest']) {
        // TODO: Something is redirecting guests to the home path (at least from the DC analyze page), we need to stop this from happening
        console.error(
          'Authenticated Guest was routed to home. TODO: Figure out why we are getting redirected to here'
        );
        clearAuthorizationToken();
        next({ name: 'auth.logout' });
      } else {
        if (store.getters['auth/isAuthenticated']) {
          Vue.prototype.$message.error(
            'Your account is not properly setup as a Publisher or Buyer. Please contact us for help setting up your account.'
          );
          next({ name: 'auth.logout' });
        } else {
          next({ name: 'auth.login' });
        }
      }
    }
  },
  AdShopRoutes('/shop'),
  AuthRoutes('/auth'),
  AdminRoutes('/admin'),
  SupplierRoutes('/supplier'),
  ...FullPageRoutes(''),
  ...BuyerRoutes('/buyer'),
  ...AccountingRoutes('/'),
  {
    path: '/preview-digital-install/:property_id',
    name: 'digital.preview',
    component: PreviewDigitalInstall
  },
  v4Routes,
  {
    path: '*',
    component: PageNotFound
  },
  DemoRoutes('/demo')
];

export default routes;
