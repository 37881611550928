<template>
  <icon :icon="icon" spin />
</template>

<script>
import { arrowsCw } from '@/vendor/icons';

export default {
  data() {
    return {
      icon: arrowsCw
    };
  }
};
</script>
