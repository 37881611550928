<script>
import { createAttributeColumn } from '@/graphql/mediakit/attributeColumnMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        createAttributeColumn(input) {
          return this.post(createAttributeColumn, { input });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
