export const RoleSlugs = {
  BUYER_ADMIN: 'buyer_admin',
  BUYER_EDIT: 'buyer_edit',
  BUYER_COLLABORATE: 'buyer_collaborate',
  BUYER_VIEW: 'buyer_view',
  PUB_ADMIN: 'pub_admin',
  PUB_EDIT: 'pub_edit',
  PUB_COLLABORATE: 'pub_collaborate',
  PUB_VIEW: 'pub_view',
  EDITORIAL_ADMIN: 'editorial_admin',
  SUPER_ADMIN: 'super_admin'
};

export const RoleEnums = {
  buyer_admin: 'BUYER_ADMIN',
  buyer_edit: 'BUYER_EDIT',
  buyer_collaborate: 'BUYER_COLLABORATE',
  buyer_view: 'BUYER_VIEW',
  pub_admin: 'PUB_ADMIN',
  pub_edit: 'PUB_EDIT',
  pub_collaborate: 'PUB_COLLABORATE',
  pub_view: 'PUB_VIEW',
  editorial_admin: 'EDITORIAL_ADMIN',
  super_admin: 'SUPER_ADMIN'
};

export const BuyerRoleType = {
  BUYER_ADMIN: 'BUYER_ADMIN',
  BUYER_EDIT: 'BUYER_EDIT',
  BUYER_COLLABORATE: 'BUYER_COLLABORATE',
  BUYER_VIEW: 'BUYER_VIEW'
};

export const BuyerRoleNames = {
  BUYER_ADMIN: 'Admin',
  BUYER_EDIT: 'Edit',
  BUYER_COLLABORATE: 'Collaborate',
  BUYER_VIEW: 'View'
};

export const BuyerRoleSelections = Object.values(BuyerRoleType).map(value => ({
  value,
  name: BuyerRoleNames[value]
}));

export const SupplierRoleType = {
  PUB_ADMIN: 'PUB_ADMIN',
  PUB_EDIT: 'PUB_EDIT',
  PUB_COLLABORATE: 'PUB_COLLABORATE',
  PUB_VIEW: 'PUB_VIEW',
  EDITORIAL_ADMIN: 'EDITORIAL_ADMIN'
};

export const SupplierRoleNames = {
  PUB_ADMIN: 'Admin',
  PUB_EDIT: 'Edit',
  PUB_COLLABORATE: 'Collaborate',
  PUB_VIEW: 'View',
  EDITORIAL_ADMIN: 'Editorial Admin'
};

export const SupplierRoleSelections = Object.values(SupplierRoleType)
  .filter(value => value !== SupplierRoleType.EDITORIAL_ADMIN)
  .map(value => ({
    value,
    name: SupplierRoleNames[value]
  }));
