<script>
import {
  approveOrder,
  cancelAllLineItems,
  deleteOrder,
  exportSupplierOrders,
  rejectOrder,
  requestChanges,
  resetApprovalStatus,
  resumeAllLineItems,
  supplierSetOrderNote
} from '@/graphql/order/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        approveOrder(order_id) {
          this.refetchCache('getSupplierOrders');
          return this.post(approveOrder, { order_id });
        },
        rejectOrder(order_id, note) {
          return this.post(rejectOrder, { order_id, note });
        },
        requestChanges(order_id, note) {
          return this.post(requestChanges, { order_id, note });
        },
        resetApprovalStatus(order_id) {
          return this.post(resetApprovalStatus, { order_id });
        },
        cancelAllLineItems(order) {
          return this.post(cancelAllLineItems, { id: order.id });
        },
        resumeAllLineItems(order) {
          return this.post(resumeAllLineItems, { id: order.id });
        },
        setOrderNote(id, note) {
          return this.post(supplierSetOrderNote, { order_id: id, note });
        },
        exportOrders(filter) {
          return this.post(exportSupplierOrders, { filter });
        },
        deleteOrder(id) {
          this.refetchCache('getSupplierOrders');
          return this.post(deleteOrder, { id });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
