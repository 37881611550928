import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '@/apollo/fragmentTypes';
import {
  authorizationLink,
  errorLink,
  httpLink,
  pusherLink
} from '@/apollo/links';
import { ApolloLink } from 'apollo-link';
import { PUSHER } from '@/constants';

Vue.use(VueApollo);

const apolloLinks = [errorLink, authorizationLink, httpLink];

if (PUSHER.ENABLED) {
  apolloLinks.push(pusherLink());
}

const link = ApolloLink.from(apolloLinks);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    fragmentMatcher,
    addTypename: true,
    dataIdFromObject: object => {
      let id;

      switch (object.__typename) {
        case 'SearchTool':
          id = object.key;
          break;
        default:
          id = object.id;
          break;
      }

      if (id) {
        return object.__typename + ':' + id;
      } else {
        return null;
      }
    }
  }),
  connectToDevTools: true
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
