<script>
import { MutationHelper } from '@/components/Core/Helpers';
import {
  createSuperTag,
  deleteSuperTag,
  updateSuperTag
} from '@/graphql/admin/superMediaKitMutations.graphql';

export default {
  mixins: [MutationHelper],
  props: {},
  mutation() {
    return {
      mutations: {
        createSuperTag(input) {
          return this.post(createSuperTag, { input });
        },
        deleteSuperTag(id) {
          return this.post(deleteSuperTag, { id });
        },
        updateSuperTag(id, input) {
          return this.post(updateSuperTag, { id, input });
        }
      }
    };
  }
};
</script>
