<template>
  <div
    id="the-account-bar"
    class="shadow text-white h-full w-full relative flex items-center justify-between px-6"
  >
    <div class="flex items-center">
      <div class="h-full">
        <slot name="logo">
          <router-link
            class="cursor-pointer flex h-full text-3xl"
            :class="logoClass"
            :to="linkTo"
            tag="li"
          >
            <icon :icon="flytedeskLogo" />
          </router-link>
        </slot>
      </div>
      <div class="px-2 mt-1 text-lg">
        <slot name="title" />
      </div>
    </div>
    <div class="p-0 h-full flex items-center justify-center text-md">
      <slot>
        <template v-if="$can('do_anything')">
          <div class="flex items-center">
            <div class="flex">
              <account-info-button :button-class="buttonClass" />
            </div>
          </div>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import { flytedeskLogo } from '@/vendor/icons';

import AccountInfoButton from '@/components/Auth/AccountInfoButton';

export default {
  components: {
    AccountInfoButton
  },
  props: {
    linkTo: {
      type: Object,
      default: () => ({
        name: 'admin'
      })
    },
    isAdmin: Boolean,
    logoClass: {
      type: String,
      default: 'text-white'
    }
  },
  data() {
    return {
      flytedeskLogo
    };
  },
  computed: {
    buttonClass() {
      return this.isAdmin ? 'button-charcoal' : 'button-white-blue';
    }
  }
};
</script>
