import PusherLink from '@/apollo/pusherLink';
import { PUSHER } from '@/constants';
import store from '@/store';
import {
  getAuthorizationHeader,
  setAuthorizationToken
} from '@/utils/authorization';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'node-fetch';
import Pusher from 'pusher-js';
import uuid from 'uuid';

Pusher.logToConsole = false;
Pusher.log = process.env.VUE_APP_PUSHER_DEBUG === 'true' ? console.log : null;

export const pusherLink = () =>
  new PusherLink({
    pusher: new Pusher(PUSHER.API_KEY, {
      cluster: PUSHER.CLUSTER,
      authEndpoint: `${window._env.API_URL}/graphql/subscriptions/auth`,
      auth: {
        headers: getAuthorizationHeader()
      }
    })
  });

const AppSessionId = uuid();

// XXX: To fix missing fetch dependency
global.fetch = fetch;
export const httpLink = createUploadLink({
  uri: `${window._env.API_URL}/api/graphql`
});

// Add the JWT Authorization headers and set the JWT Token when authenticated
export const authorizationLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      ...getAuthorizationHeader(),
      AppSessionId,
      // _env is imported from index.html
      AppVersion: window._env.APP_VERSION
    }
  });

  return forward(operation).map(data => {
    const headers = operation.getContext().response.headers;

    const jwtBearerToken = headers.get('X-JwtBearerToken');

    if (jwtBearerToken) {
      setAuthorizationToken(jwtBearerToken);
    }

    const appVersionMismatch = headers.get('AppVersionMismatch');

    if (appVersionMismatch) {
      store.commit('common/appVersionMismatch', appVersionMismatch);
    }

    return data;
  });
});

export const errorLink = onError(({ networkError }) => {
  if (networkError) {
    // console.error(`[Network error]: ${networkError}`);

    if (networkError.statusCode === 401) {
      // Remove our cached token as we are no longer authenticated
      setAuthorizationToken(null);
    }
  }
});
