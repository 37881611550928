<script>
import { updateBuyerAccount } from '@/graphql/buyer/mutations.graphql';
import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        updateBuyerAccount(buyerId, input) {
          this.post(updateBuyerAccount, { buyer_id: buyerId, input });
        }
      }
    };
  }
};
</script>
