<template>
  <div class="flex">
    <div class="block p-3 flex-1 w-2/5">
      <loader-content>
        <loader-box height="450px" />
      </loader-content>
    </div>

    <div class="block p-3 w-3/5">
      <loader-content>
        <loader-heading />
        <loader-text :lines="4" />
      </loader-content>
    </div>
  </div>
</template>

<script>
import {
  LoaderBox,
  LoaderContent,
  LoaderHeading
} from '@/components/Core/Loaders';

// Bug w/ ES6 loader, cannot find LoaderText for some reason (when importing w/ statement above)
import LoaderText from '@/components/Core/Loaders/LoaderText';

export default {
  components: {
    LoaderBox,
    LoaderContent,
    LoaderHeading,
    LoaderText
  }
};
</script>
