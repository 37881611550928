<script>
import {
  createBuyerAccount,
  loginUserMutation
} from '@/graphql/auth/loginMutation.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        loginUser(input) {
          this.post(loginUserMutation, { input });
        },
        createBuyerAccount(input) {
          this.post(createBuyerAccount, {
            input: {
              ...input,

              // We want to ignore confirm password
              confirm_password: undefined
            }
          });
        }
      }
    };
  }
};
</script>
