<script>
import { Me } from '@/graphql/auth/me.graphql';
import { QueryHelper } from '@/components/Core/Helpers';
import { AccountType } from '@/constants';
import { map } from 'lodash';

export default {
  mixins: [QueryHelper],
  props: {
    fetchPolicy: {
      type: String,
      default: 'no-cache'
    }
  },

  query() {
    return {
      query: Me
    };
  },

  methods: {
    resolveAccount() {
      let data = this.result.data.me;
      if (!data) return false;

      let requiredAccountType = false;

      if (location.href.match(/\/v3\/(auth\/)?supplier/i)) {
        // This user does not have the correct account type
        if (data.suppliers.length === 0) {
          return false;
        }

        requiredAccountType = AccountType.SUPPLIER;
      } else if (location.href.match(/\/v3\/(auth\/)?buyer/i)) {
        // This user does not have the correct account type
        if (data.buyers.length === 0) {
          return false;
        }
        requiredAccountType = AccountType.BUYER;
      }

      // If same user, try to switch to the user's last account selected
      let previousAccount = JSON.parse(
        window.localStorage.getItem('current_account')
      );

      // If user has no active account when visiting the ad shop then switch to a BUYER account
      if (location.href.match(/\/v3\/shop/i) && !previousAccount) {
        if (!data.buyers.length > 0) {
          return false;
        }
        requiredAccountType = AccountType.BUYER;
      }

      // If the user had a previous account saved, set that as the current account
      if (previousAccount) {
        // If previous account was a different user account, this is invalid
        if (previousAccount.user_id !== data.id) {
          previousAccount = null;
        }

        // If the previous account is not the correct type for the current page,
        // then it is invalid
        if (
          requiredAccountType &&
          previousAccount.account.type !== requiredAccountType
        ) {
          previousAccount = null;
        }

        // If the account is valid, set it as the current account
        if (previousAccount) {
          this.setCurrentAccount(previousAccount.account);
        }
      }

      // If the account was not resolved by a previous account, lets log them in as their first
      // available account
      if (!data.currentAccount) {
        if (!requiredAccountType) {
          if (data.suppliers.length > 0) {
            requiredAccountType = AccountType.SUPPLIER;
          } else if (data.buyers.length > 0) {
            requiredAccountType = AccountType.BUYER;
          }
        }

        let resolvedAccount;

        if (requiredAccountType === AccountType.SUPPLIER) {
          resolvedAccount = {
            type: AccountType.SUPPLIER,
            id: data.suppliers[0].id
          };
        } else if (requiredAccountType === AccountType.BUYER) {
          resolvedAccount = {
            type: AccountType.BUYER,
            id: data.buyers[0].id
          };
        }

        // If the account was resolved, log them in otherwise return false
        if (resolvedAccount) {
          return this.setCurrentAccount(resolvedAccount) !== null;
        } else {
          return false;
        }
      }
    },
    setCurrentAccount(account) {
      let data = this.result.data.me;
      switch (account.type) {
        case AccountType.SUPPLIER:
          var newSupplier = data.suppliers.find(
            supplier => supplier.id === account.id
          );

          if (newSupplier) {
            data.supplier = newSupplier;
            data.buyer = null;
            data.features = (data.features || []).concat(
              map(data.supplier.features, 'name')
            );
          } else {
            account = null;
          }
          break;

        case AccountType.BUYER:
          var newBuyer = data.buyers.find(buyer => buyer.id === account.id);

          if (newBuyer) {
            data.buyer = newBuyer;
            data.supplier = null;
            data.features = (data.features || []).concat(
              map(data.buyer.features, 'name')
            );
          } else {
            account = null;
          }
          break;

        case AccountType.ADMIN:
        default:
          // How to handle logging in as super admin (or no pub / buyer)?
          account = null;
          break;
      }

      data.currentAccount = account;
    },
    setResult(result) {
      if (result.error) {
        console.error(result.error);
        this.$message.error(result.error.message);
      }

      this.result = result;
      this.resolveAccount();

      this.$emit('result', result);
      this.$emit('data', this.resultData);
    }
  }
};
</script>
