import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/routes';
import qs from 'qs';

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.VUE_APP_PUBLIC_PATH,
  mode: 'history',
  routes,
  parseQuery: qs.parse,
  stringifyQuery: a => {
    let str = qs.stringify(a);

    return str ? '?' + str : '';
  }
});

export default router;
