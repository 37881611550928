<script>
import {
  createFulfillmentMethod,
  deleteFulfillmentMethod,
  updateFulfillmentMethod
} from '@/graphql/fulfillment/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        createFulfillmentMethod(input) {
          input = this.mapInput(input);

          return this.post(createFulfillmentMethod, { input });
        },
        updateFulfillmentMethod(original, input) {
          input = this.mapInput(input);

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateMediakit: {
                __typename: 'MediakitMutation',
                mutateFulfillmentMethod: {
                  __typename: 'FulfillmentMethodMutation',
                  update: true,
                  fulfillmentMethod: {
                    __typename: 'FulfillmentMethod',
                    ...original,
                    ...input
                  }
                }
              }
            }
          };

          return this.post(updateFulfillmentMethod, { id: original.id, input });
        },
        deleteFulfillmentMethod(id) {
          return this.post(deleteFulfillmentMethod, { id });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  },

  methods: {
    mapInput(input) {
      return this.castFields(input, {
        name: String,
        display_name: String,
        description: String,
        requires_assets: Boolean,
        requires_text: Boolean,
        requires_shipping: Boolean,
        requires_printing: Boolean,
        requires_acceptance: Boolean,
        recommendedFileTypes: Array,
        shipping_address: String,
        deadline_days: Number,
        price: Number
      });
    }
  }
};
</script>
