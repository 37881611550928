<script>
import { getAdShopCart } from '@/graphql/cart/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  query() {
    return {
      query: getAdShopCart,
      variables: {
        adShopSlug: this.$route.params.shopSlug,
        cartId: this.cartId
      },
      onResult(cart) {
        if (
          !this.$route.params.cart_id ||
          this.$route.params.cart_id === 'share'
        ) {
          // Save this to local storage for later retrieval
          this.$store.commit('adShop/setAdShopCartId', cart.id);

          this.$router.push({
            name: this.$route.name,
            params: { shopSlug: this.$route.params.shopSlug, cart_id: cart.id },
            query: this.$route.query
          });
        }
      }
    };
  },

  computed: {
    cartId() {
      if (this.$route.params.cart_id === 'share') {
        return '';
      } else {
        return (
          this.$route.params.cart_id ||
          this.$store.getters['adShop/adShopCartId']
        );
      }
    }
  }
};
</script>
