<script>
import { getCustomerReps } from '@/graphql/customer/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  name: 'CustomerRepsQuery',

  mixins: [QueryHelper],

  props: {
    buyerId: {
      type: String,
      required: true
    }
  },

  query() {
    return {
      query: getCustomerReps,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        filter: {
          'buyer.id': this.buyerId
        }
      }
    };
  }
};
</script>
