import pager from '../includes/pager';

export const state = {
  ...pager.state
};

const mutations = {
  ...pager.mutations
};

const actions = {
  ...pager.actions
};

const getters = {
  ...pager.getters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
