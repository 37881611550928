<template>
  <el-button :class="buttonClass" :size="size">
    <icon :icon="theIcon" spin :class="iconClass" />
    <slot />
  </el-button>
</template>

<script>
import Vue from 'vue';
import { arrowsCw } from '@/vendor/icons';

export default Vue.component('loadingButton', {
  props: {
    // The Icon to use
    icon: { type: Object, default: null },
    // The class to apply to the icon (typically to change icon size)
    iconClass: { type: String, default: null },
    buttonClass: {
      type: String,
      default: () => 'w-full'
    },
    size: {
      type: String,
      default: null
    }
  },

  computed: {
    theIcon() {
      return this.icon || arrowsCw;
    }
  }
});
</script>
