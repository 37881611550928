import { dashboard, infoCircled, ok } from '@/vendor/icons';

export default store => {
  let verifications;

  verifications = {
    name: 'Verifications',
    component: 'buyer.verifications',
    icon: ok
  };

  return [
    {
      name: 'Dashboard',
      component: 'buyer.dashboard',
      icon: dashboard
    },
    verifications,
    {
      name: 'Help',
      href: 'https://www.flytedesk.com/buyer-support',
      target: '_blank',
      icon: infoCircled
    }
  ];
};
