<template>
  <div id="loading-app">
    <icon :icon="flytedeskLogo" class="logo" />
    <div class="progress">
      <div class="progress-box">
        <div class="progress-bar" :style="{ width: percent + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { flytedeskLogo } from '@/vendor/icons';

export default {
  data() {
    return {
      flytedeskLogo,
      percent: 0
    };
  },

  mounted() {
    let loadingIntervalId = setInterval(() => {
      if (this.percent < 100) {
        this.percent += 10;
      } else {
        clearInterval(loadingIntervalId);
      }
    }, 500);
  }
};
</script>

<style lang="scss" type="text/scss" scoped>
@import '~@/scss/_variables';

#loading-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress {
  margin-top: 1.5em;
}

.logo {
  color: $color-blue;
  font-size: 4em;
}

.progress-box {
  width: 25em;
  height: 1em;
  border-radius: 10px;
  overflow: hidden;

  .progress-bar {
    background: $color-blue;
    width: 0;
    transition: all 0.5s;
    height: 100%;
  }
}
</style>
