import { cancel, clock, ok } from '@/vendor/icons';

export const VerificationStatus = {
  UNVERIFIED: 'UNVERIFIED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED'
};

export const VerificationStatusInfo = {
  EMPTY: {
    icon: clock,
    class: 'text-dark-silver',
    name: 'Awaiting Upload'
  },
  UNVERIFIED: {
    icon: clock,
    class: 'text-dark-red',
    name: 'Unverified',
    warning: true
  },
  PENDING: {
    icon: clock,
    class: 'text-dark-red',
    name: 'Pending Approval',
    warning: true
  },
  VERIFIED: {
    icon: ok,
    class: 'text-dark-silver',
    name: 'Verified',
    date: true
  },
  REJECTED: {
    icon: cancel,
    class: 'text-yellow',
    name: 'Rejected',
    date: true,
    warning: true
  }
};
