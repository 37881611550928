const PageSupplierAccountingReports = () =>
  import(
    /* webpackChunkName: 'AccountingReports' */
    '@/components/Supplier/Accounting/PageSupplierAccountingReports'
  );
const SupplierArAgingReport = () =>
  import(
    /* webpackChunkName: 'AccountingReports' */
    '@/components/Supplier/Accounting/Reports/ArAgingReport/SupplierArAgingReport'
  );
const SupplierSalesSummaryReport = () =>
  import(
    /* webpackChunkName: 'AccountingReports' */
    '@/components/Supplier/Accounting/Reports/SalesSummaryReport/SupplierSalesSummaryReport'
  );
const SupplierTransactionReport = () =>
  import(
    /* webpackChunkName: 'AccountingReports' */
    '@/components/Supplier/Accounting/Reports/TransactionReport/SupplierTransactionReport'
  );
const SupplierCreditCardTransactionReport = () =>
  import(
    /* webpackChunkName: 'AccountingReports' */
    '@/components/Supplier/Accounting/Reports/TransactionReport/SupplierCreditCardTransactionReport'
  );

export default () => {
  return [
    {
      path: 'accounting/reports',
      name: 'supplier.accounting.reports',
      component: PageSupplierAccountingReports,
      redirect: { name: 'supplier.accounting.ar-aging-report' },
      meta: {
        feature: 'accounting-reports'
      },
      children: [
        {
          path: 'ar-aging',
          name: 'supplier.accounting.ar-aging-report',
          component: SupplierArAgingReport
        },
        {
          path: 'sales-summary',
          name: 'supplier.accounting.sales-summary-report',
          component: SupplierSalesSummaryReport
        },
        {
          path: 'transaction',
          name: 'supplier.accounting.transaction-report',
          component: SupplierTransactionReport
        },
        {
          path: 'cc-transaction',
          name: 'supplier.accounting.cc-transaction-report',
          component: SupplierCreditCardTransactionReport
        }
      ]
    }
  ];
};
