<template>
  <section>
    <div
      class="flex w-full py-4 px-3 text-dark-silver justify-between hover:bg-light-gray rounded p-1 cursor-pointer"
      @click="onClick"
    >
      <div class="mr-4 self-center" @click.stop>
        <el-tooltip
          effect="light"
          :content="notification.is_read ? 'Mark Unread' : 'Mark Read'"
          @click.stop
        >
          <el-checkbox :value="!notification.is_read" @change="onCheck" />
        </el-tooltip>
      </div>
      <div class="flex flex-col flex-1">
        <div class="text-dark-gray">{{ notification.title }}</div>
        <div class="mb-2">{{ notification.content }}</div>
        <div class="text-xs">
          {{ notification.created_at | datetime('MM/DD/Y [at] h:mm:ss a') }}
        </div>
      </div>
      <div
        class="self-start -mt-3 py-1 px-3 text-dark-silver hover:text-gunmetal"
        @click.stop="$emit('archive')"
      >
        <icon :icon="cancelIcon" class="text-xxs" />
      </div>
    </div>
    <hr v-if="hasBottomBorder" class="my-3" />
  </section>
</template>

<script>
import { cancel as cancelIcon } from '@/vendor/icons';
import { datetime } from '@/utils/filters';

export default {
  props: {
    hasBottomBorder: Boolean,
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      datetime,
      cancelIcon
    };
  },
  methods: {
    onClick() {
      const data = this.notification.data;

      if (this.notification.is_read) {
        this.$emit('close');
      } else {
        this.$emit('toggle-read', true);

        setTimeout(() => {
          this.$emit('close');
        }, 1000);
      }

      if (data && data.route_name) {
        const name = data.route_name;
        const params = data.params || {};
        const query = data.query || {};

        this.$router.push({
          name,
          params,
          query
        });
      }
    },
    onCheck(event) {
      this.$emit('toggle-read', !event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $color-gray;

  &::after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color-blue;
    border: none;
    left: 23%;
    top: 22%;
    display: block;
  }
}

/deep/ .el-checkbox__inner {
  background-color: $color-gray;
  border: none;
  border-radius: 50%;

  &::after {
    display: none;
  }
}
</style>
