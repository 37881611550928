<template>
  <apollo-query
    v-if="theQuery && !isSkipped"
    v-bind="$props"
    :query="theQuery"
    :variables="theVariables"
    @result="setResult"
    @error="$emit('error', $event)"
  >
    <template slot-scope="props">
      <slot
        v-bind="props"
        :is-loading="setLoadingState(props.isLoading)"
        :data="resultData"
      />
    </template>
  </apollo-query>
</template>

<script>
import { ApolloQuery } from 'vue-apollo';
import { firstElement } from '@/utils/helpers';

/**
 * Works exactly the same as ApolloQuery component, with some extended functionality.
 *
 * Add a query option to your export default in the extending component, and return a query object like:
 *
 * export default {
 *   query () {
 *     return {
 *       query: myGraphQLQuery,
 *       variables: {
 *         ...
 *       }
 *     };
 *   }
 * }
 *
 * You can also set the query and variables by passing in query / variable props on the component. You can use all the props / events
 * that are available in the ApolloQuery component
 *
 * For the default slot, scope-slot has an additional data prop that is accessible in the slot-scope of child components.
 *    - slot-scope, also is passed all the data the ApolloQuery component normally passes
 *
 * @data event: Optionally use the data event to send the data to a callback. This fires whenever there is a new payload.
 *   - There are also the @error and @result events that ApolloQuery normally emits
 *
 *
 * <my-query @data="myCallback">
 *   <template slot-scope="{isLoading, data: myData}">
 *     <div>My Content here</div>
 *   </template>
 * </my-query>
 *
 */
export default {
  props: {
    ...ApolloQuery.props,
    query: { type: [String, Object], default: null },
    variables: { type: Object, default: null },
    loading: Boolean
  },
  data() {
    return {
      result: null
    };
  },
  /**
   * Override this query() method with your own query and variables when extending via a component,
   */
  query() {
    return {
      query: this.query,
      variables: this.variables
    };
  },
  computed: {
    isSkipped() {
      let query = this.getQuery();

      if (query.skip) {
        return query.skip.call(this);
      }

      return false;
    },
    theQuery() {
      let query = this.getQuery();
      if (query) {
        return query.query;
      }
      return false;
    },
    theVariables() {
      let query = this.getQuery();

      if (query && query.variables) {
        return {
          ...query.variables,
          ...this.variables
        };
      } else {
        return {
          ...this.variables
        };
      }
    },
    resultData() {
      if (!this.result || !this.result.data) {
        return null;
      }
      let query = this.getQuery();
      if (query && query.update) {
        return query.update(this.result);
      }
      // Return the first property in the object (should always be the query name)
      return firstElement(this.result.data);
    }
  },
  methods: {
    setLoadingState(isLoading) {
      this.$emit('update:loading', !!isLoading);
      return !!isLoading;
    },
    getQuery() {
      return this.$options.query.call(this, this.$props);
    },
    setResult(result) {
      if (result.error) {
        this.$message.error(result.error.message);
      }

      this.result = result;

      let query = this.getQuery();

      if (query.onResult) {
        query.onResult.call(this, this.resultData, this.result);
      }

      this.$emit('result', result);
      this.$emit('data', this.resultData);
    }
  }
};
</script>
