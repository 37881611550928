
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderSubLineItemAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderSubLineItem"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_hidden"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderSubLineItemRelations"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OrderSubLineItem"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserAttributes"},"directives":[]}]}}]}}],"loc":{"start":0,"end":268}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/UserFragments.graphql\"\n\nfragment OrderSubLineItemAttributes on OrderSubLineItem {\n  id\n  name\n  description\n  amount\n  is_hidden\n  created_at\n}\n\nfragment OrderSubLineItemRelations on OrderSubLineItem {\n  user {\n    ...UserAttributes\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/UserFragments.graphql").definitions));


      module.exports = doc;
    
