export const state = {
  pager: {
    sort: {},
    filter: {},
    perPage: 10,
    page: 1
  }
};

const mutations = {
  setPager(state, value) {
    // Reset the page to 1 if the filter has changed
    if (value.filter) {
      for (let key of Object.keys(value.filter)) {
        if (state.pager.filter[key] != value.filter[key]) {
          value.page = 1;
          break;
        }
      }
    }

    state.pager = { ...state.pager, ...value };
  }
};

const actions = {};

const getters = {
  pager: state => {
    return state.pager;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
