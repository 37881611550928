export const DiscountType = {
  // AMOUNT: 'AMOUNT',
  PERCENT: 'PERCENT'
};

export const DiscountTypes = {
  // AMOUNT: {
  //   name: DiscountType.AMOUNT,
  //   value: '$',
  //   label: 'Amount off'
  // },
  PERCENT: {
    name: DiscountType.PERCENT,
    value: '%',
    label: 'Percent Off'
  }
};
