<script>
import { getUser } from '@/graphql/user/getUser.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  query() {
    return {
      query: getUser
    };
  }
};
</script>
