<script>
import { getCampuses } from '@/graphql/campuses/campusQueries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    sort: { type: Object, default: null },
    filter: { type: Object, default: null },
    supplierFilter: { type: Object, default: null },
    selections: { type: Array, default: () => [] },
    pager: { type: Object, default: null }
  },
  query(props) {
    return {
      query: getCampuses,
      variables: {
        sort: props.sort,
        filter: props.filter,
        supplierFilter: props.supplierFilter,
        selected: props.selections,
        ...props.pager
      }
    };
  }
};
</script>
