<template>
  <div>
    <takeover-mutation>
      <template v-slot="{ takeoverBuyer, takeoverSupplier }">
        <div v-if="!isTakingOver">
          <div v-if="isSupplier" class="filter-bar flex mb-5">
            <div class="flex-grow">
              <form autocomplete="off">
                <custom-el-select
                  class="w-full"
                  select-class="w-full"
                  :value="supplierFilter.organization_type"
                  :options="organizationTypes"
                  clearable
                  placeholder="All Organizations"
                  @input="saveFilter({ organization_type: $event })"
                />
              </form>
            </div>
            <div class="flex-grow"></div>
          </div>

          <el-autocomplete
            ref="autocomplete"
            v-model="keywords"
            class="w-full"
            :debounce="100"
            :fetch-suggestions="getSuggestions"
            :placeholder="`Search ${isBuyer ? 'advertisers' : 'suppliers'}...`"
            value-key="name"
            @select="
              takeoverAccount(
                isBuyer ? takeoverBuyer : takeoverSupplier,
                $event
              )
            "
          >
            <icon
              v-if="listItems.length === 0"
              slot="suffix"
              :icon="loadingIcon"
              spin
            />

            <div
              slot-scope="{ item }"
              :class="{ 'bg-light-red': item.is_test }"
            >
              {{ item.display_name }}
            </div>
          </el-autocomplete>
        </div>

        <div v-else>
          Hold on to your butts...
          <icon :icon="loadingIcon" spin />
        </div>
      </template>
    </takeover-mutation>
  </div>
</template>

<script>
import CustomElSelect from '@/components/Custom/CustomElSelect';
import { TakeoverMutation } from '@/components/Mutations';
import { OrganizationType } from '@/constants';
import { getAuthorizationHeader } from '@/utils/authorization';
import { arrowsCw as loadingIcon } from '@/vendor/icons';

export default {
  components: {
    CustomElSelect,
    TakeoverMutation
  },
  props: {
    takeoverType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isTakingOver: false,
      suggestionId: 0,
      listItems: [],
      supplierFilter: {
        organization_type: [],
        ...this.getSavedFilter()
      },
      keywords: '',
      loadingIcon
    };
  },
  computed: {
    isBuyer() {
      return this.takeoverType === 'buyer';
    },
    isSupplier() {
      return this.takeoverType === 'supplier';
    },
    organizationTypes() {
      return Object.values(OrganizationType).map(value => {
        return {
          value
        };
      });
    },
    takeoverRedirect() {
      return this.isBuyer ? '/v3/buyer/dashboard' : '/v3/supplier/dashboard';
    }
  },
  watch: {
    takeoverType() {
      // Clear the input search any time the takeover type changes
      this.clear();
    }
  },
  mounted() {
    this.focus();
    this.loadList();
  },
  methods: {
    input() {
      return this.$refs.autocomplete;
    },

    async loadList(filter) {
      // Load list of suppliers or buyers based on the takeover type
      const type = this.isBuyer ? 'buyers' : 'suppliers';

      if (this.isSupplier) {
        filter = { ...filter, ...this.supplierFilter };
      }
      const results = await fetch(
        process.env.VUE_APP_API_URL +
          `/admin/takeover/${type}?filter=` +
          JSON.stringify(filter),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthorizationHeader()
          }
        }
      ).then(res => res.json());

      if (results?.data) {
        this.listItems = results.data;
      } else {
        this.listItems = [];
      }
    },

    /**
     * Asynchronously load (if not already loaded) the search results and filter them
     * based on the queryString
     */
    async getSuggestions(keywords, callback) {
      const suggestionId = ++this.suggestionId;

      await this.loadList({ keywords });

      if (suggestionId !== this.suggestionId) {
        return;
      }

      // Highlight the first search result after they are rendered
      this.$nextTick(() => {
        if (this.input()) {
          this.input().highlight(0);
        }
      });

      callback(this.listItems);
    },

    /**
     * Takeover the Supplier or Buyer account that was selected
     *
     * @param mutation
     * @param account
     */
    async takeoverAccount(mutation, account) {
      this.isTakingOver = true;

      // Clear the takeoverBuyerId, at least
      this.$store.commit('adShop/setTakeoverBuyerId', null);

      // Remove the cart id
      this.$store.commit('adShop/setAdShopCartId', null);

      // Clear the authenticated user in v4 SPA
      this.$store.commit('auth/clearAuthenticatedUser');

      await mutation(account.id);

      // XXX: For now just reload the page until we properly can reset all data for takeover
      window.location = this.takeoverRedirect;
    },

    getSavedFilter() {
      return JSON.parse(localStorage.getItem('takeover-filter'));
    },
    saveFilter(update) {
      this.supplierFilter = { ...this.supplierFilter, ...update };
      localStorage.setItem(
        'takeover-filter',
        JSON.stringify(this.supplierFilter)
      );
    },

    focus() {
      if (this.input()) {
        this.input().handleFocus();
      }
    },

    clear() {
      if (this.input()) {
        this.input().$refs.input.clear();
      }
    },

    blur() {
      if (this.input()) {
        this.input().close();
        this.input().handleBlur();
      }
    }
  }
};
</script>
