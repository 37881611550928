<script>
import { renderless } from '@/utils/helpers';
import { apolloClient } from '@/vue-apollo';
import { NetworkStatus } from 'apollo-client';

export default {
  props: {
    showErrorMessage: { type: Boolean, default: true },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // The Observable Apollo Client watchQuery object that is subscribed to for query results
      watchedQuery: null,
      // The result of the most recent query event
      result: null,
      // The data inside the result of the most recent query event
      resultData: null,
      // True when loading query results from the network
      isLoading: true
    };
  },
  computed: {
    theQuery() {
      return this.getQueryOption('query');
    },
    theVariables() {
      return this.getQueryOption('variables');
    }
  },
  watch: {
    async theVariables() {
      this.setLoadingState(true);
      await this.watchedQuery.refetch(this.theVariables);
      this.setLoadingState(false);
    }
  },
  mounted() {
    if (!this.getQueryOption('query')) {
      throw new Error(
        'You must set the Query\'s Option "query" in the Component utilizing the QueryMixin'
      );
    }

    this.fetchQuery();
  },
  methods: {
    fetchQuery() {
      this.setLoadingState(true);

      const options = {
        query: this.theQuery,
        variables: this.theVariables,
        ...this.options
      };

      this.watchedQuery = apolloClient.watchQuery(options);

      this.watchedQuery.subscribe(this.setResult, this.onError);
    },
    setLoadingState(isLoading) {
      this.isLoading = !!isLoading;
      this.$emit('update:loading', this.isLoading);
    },
    getQueryOption(key = null) {
      const query = this.$options.query.call(this, this.$props);

      return key ? query[key] : query;
    },
    onError(error) {
      console.error(error);

      if (this.showErrorMessage) {
        this.$message.error(error.message);
      }

      this.$emit('error', error);
    },
    setResult(result) {
      if (result.stale) {
        // If the result is stale, we need to refetch our data from the cache
        return this.watchedQuery.refetch(this.theVariables);
      } else if (result.networkStatus === NetworkStatus.ready) {
        // The Result is up to date and nothing is loading from the network,
        // therefore we have the most recent result!

        this.result = result;

        if (result.error) {
          // TODO: Can this ever get here? Errors should always be thrown...
          this.onError(result.error);
        } else {
          let queryUpdate = this.getQueryOption('update');

          this.resultData = queryUpdate
            ? queryUpdate(this.result)
            : this.result.data;

          this.$emit('data', this.resultData);
        }

        this.$emit('result', this.result);
        this.setLoadingState(false);
      }
    }
  },
  render(c) {
    return renderless.call(this, c, {
      data: this.resultData,
      result: this.result,
      isLoading: this.isLoading
    });
  }
};
</script>
