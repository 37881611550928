import axios from 'axios';
import { getAuthorizationHeader } from '@/utils/authorization';

export function configureAxios() {
  axios.defaults.baseURL = `${window._env.API_URL}/`;
  axios.interceptors.request.use(JWTAuthInterceptor);
}

export const JWTAuthInterceptor = config => {
  config.headers = {
    ...config.headers,
    ...getAuthorizationHeader(),
    // _env is imported from index.html
    AppVersion: window._env.APP_VERSION
  };

  return config;
};
