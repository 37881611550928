import moment from 'moment';
import { datetimeDb } from '@/utils/filters';

export function sortColumns(col, pager) {
  pager = pager || this.pager;
  if (col.column) {
    pager.sort = [
      {
        column: col.column.sortBy || col.column.property,
        order: col.order
      }
    ];
  } else {
    pager.sort = [];
  }
}

export function filterDateBetween(start, end) {
  let startDate = start ? datetimeDb(moment(start).startOf('day')) : undefined;
  let endDate = end ? datetimeDb(moment(end).endOf('day')) : undefined;

  return {
    '>=': startDate,
    '<=': endDate
  };
}

export const sortFilter = {
  sortColumns,
  filterDateBetween
};
