<script>
import { getCampaignTrackerSearchTools } from '@/graphql/admin/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  query() {
    return {
      query: getCampaignTrackerSearchTools
    };
  }
};
</script>
