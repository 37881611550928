import {
  arrowsCw,
  attention,
  cancel,
  disable,
  ok,
  playCircleO
} from '@/vendor/icons';

export const PaymentMethodName = {
  CREDIT: 'Credit Card',
  INVOICE: 'Invoice'
};

export const OrderStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  COMPLETE: 'COMPLETE',
  CANCELED: 'CANCELED'
};

export const OrderStatusValues = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled'
};

export const OrderApprovalStatus = {
  APPROVED: 'APPROVED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED'
};

export const BillingPreference = {
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID',
  PREPAID_IN_FULL: 'PREPAID_IN_FULL'
};

export const BillingPreferenceLabel = {
  PREPAID: 'Prepay Monthly',
  POSTPAID: 'Postpay Monthly',
  PREPAID_IN_FULL: 'Prepay In Full'
};

export const BillingPreferences = Object.values(BillingPreference).map(bp => ({
  label: BillingPreferenceLabel[bp],
  value: bp
}));

export const NationalBillingPreferences = Object.values(BillingPreference)
  .filter(bp => bp !== 'PREPAID_IN_FULL')
  .map(bp => ({
    label: BillingPreferenceLabel[bp],
    value: bp
  }));

export const OrderStatusIcons = {
  APPROVED: {
    icon: playCircleO,
    class: 'text-blue hover:text-dark-blue',
    value: 'Approved',
    name: 'Active'
  },

  CANCELED: {
    icon: cancel,
    class: 'text-red hover:text-dark-red',
    value: 'Canceled',
    name: 'Canceled'
  },

  COMPLETE: {
    icon: ok,
    class: 'text-green hover:text-dark-green',
    value: 'Complete',
    name: 'Complete'
  },

  CHANGES_REQUESTED: {
    icon: arrowsCw,
    class: 'text-yellow hover:text-dark-yellow',
    value: 'Changes Requested',
    name: 'Changes Requested'
  },

  PENDING: {
    icon: attention,
    class: 'text-yellow hover:text-dark-yellow',
    value: 'Pending',
    name: 'Pending Approval'
  },

  REJECTED: {
    icon: disable,
    class: 'text-red hover:text-dark-red',
    value: 'Rejected',
    name: 'Rejected'
  }
};
