<script>
import { getAdShopProduct } from '@/graphql/adshop/queries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    productId: {
      type: String,
      required: true
    }
  },

  query() {
    return {
      query: getAdShopProduct,
      variables: {
        productId: this.productId,
        slug: this.$store.getters['adShop/slug']
      }
    };
  }
};
</script>
