export const AdGroupRateType = {
  CPM: {
    name: 'CPM',
    value: 'CPM'
  },
  FLAT_RATE: {
    name: 'Flat Rate',
    value: 'FLAT_RATE'
  }
};

export const AdGroupMetric = {
  IMPRESSIONS: {
    name: 'Impressions',
    value: 'IMPRESSIONS'
  },
  VIEWABLE_IMPRESSIONS: {
    name: 'Viewable Impressions',
    value: 'VIEWABLE_IMPRESSIONS'
  },
  FULL_IMPRESSIONS: {
    name: 'Full Impressions',
    value: 'FULL_IMPRESSIONS'
  }
};
