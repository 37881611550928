<template>
  <div class="flex">
    <div class="block p-3 flex-1">
      <loader-content>
        <template v-if="grid">
          <loader-grid />
        </template>

        <template v-else>
          <loader-heading />
          <loader-text :lines="4" />
        </template>
      </loader-content>
    </div>
  </div>
</template>

<script>
import {
  LoaderContent,
  LoaderGrid,
  LoaderHeading
} from '@/components/Core/Loaders';

// Bug w/ ES6 loader, cannot find LoaderText for some reason (when importing w/ statement above)
import LoaderText from '@/components/Core/Loaders/LoaderText';

export default {
  components: {
    LoaderContent,
    LoaderGrid,
    LoaderHeading,
    LoaderText
  },
  props: {
    grid: Boolean,
    menuWidth: {
      type: Number,
      default: 15
    }
  },
  computed: {
    style() {
      return {
        width: this.menuWidth + 'em'
      };
    }
  }
};
</script>
