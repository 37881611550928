
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AdGroupAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AdGroup"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"impressions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"priority"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"charge_type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"charge_metric"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"charge_rate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"payout_type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"payout_metric"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"payout_rate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":189}};
    doc.loc.source = {"body":"fragment AdGroupAttributes on AdGroup {\n  id\n  name\n  impressions\n  priority\n  status\n  charge_type\n  charge_metric\n  charge_rate\n  payout_type\n  payout_metric\n  payout_rate\n  created_at\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
