<script>
import { MutationHelper } from '@/components/Core/Helpers';
import {
  takeoverBuyer,
  takeoverSupplier
} from '@/graphql/auth/takeoverMutations.graphql';

export default {
  mixins: [MutationHelper],
  mutation() {
    return {
      mutations: {
        async takeoverBuyer(id) {
          return this.post(takeoverBuyer, { id });
        },
        async takeoverSupplier(id) {
          return this.post(takeoverSupplier, { id });
        }
      }
    };
  }
};
</script>
