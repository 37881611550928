<template>
  <div class="flex flex-col">
    <div v-if="title || $slots.default" class="text-dark-gray font-semibold">
      <slot>{{ title }}</slot>
    </div>
    <div class="text-xs text-dark-silver leading-normal">
      {{ subTitle }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    }
  }
};
</script>
