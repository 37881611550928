<template>
  <div id="preview-digital-install">
    <div v-if="supplier" class="p-10">
      <digital-ad-units-query
        :property-id="$route.params.property_id"
        :loading.sync="isLoading"
        @data="property = $event.supplier.property"
      />
      <h1>
        Digital Script Tag Installation Preview for
        {{ supplier.name }}
      </h1>

      <div v-if="property" class="mt-5">
        <h3>Found {{ adUnits.length }} Ad Units</h3>

        <div class="my-5">
          <div>3rd Party Tag</div>
          <div ref="thirdPartyLocationA" class="mt-3"></div>
        </div>

        <template v-for="adUnit in adUnits">
          <div :key="adUnit.id" class="ad-unit p-5">
            <div class="ad-unit-name font-bold text-md mb-4">
              {{ adUnit.unitName }}
            </div>
            <div
              class="flytead"
              :class="{
                [adUnit.id]: true,
                [adUnit.class_tag]: true
              }"
              :style="adUnit.style"
            >
              <div class="p-1 text-red">No Ads Running</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <template v-else>
      <el-alert
        type="error"
        title="You must be authenticated as a Publisher to preview a Digital Script Tag Installation"
      />
    </template>

    <div class="m-10">
      <div>3rd Party Footer</div>
      <div ref="thirdPartyLocationB" class="mt-3"></div>
    </div>
  </div>
</template>

<script>
import DigitalAdUnitsQuery from '@/components/Queries/Digital/DigitalAdUnitsQuery';

export default {
  components: { DigitalAdUnitsQuery },
  data() {
    return { isLoading: false, property: null };
  },
  computed: {
    supplier() {
      return this.$store.getters['auth/supplier'];
    },
    thirdPartyTagUrl() {
      return process.env.VUE_APP_3P_TAG_SRC;
    },
    adUnits() {
      let adUnits = [];

      for (let collection of this.property.collections) {
        for (let product of collection.products) {
          let class_tag = '';

          // Resolve the class tag for the Product
          for (let typeEdge of product.typeEdges) {
            if (typeEdge.type.class_tag) {
              class_tag = typeEdge.type.class_tag;
              break;
            }
          }

          for (let variant of product.variants) {
            let style = {};

            let dims = variant.attributes && variant.attributes.Dimensions;

            if (dims) {
              style.width = dims.width || '100%';
              style.height = dims.height || 0;
              style.padding = dims.width_ratio
                ? (100 * dims.height_ratio) / dims.width_ratio
                : 0;

              if (dims.max_width) {
                style.maxWidth = dims.max_width;
              }

              if (dims.max_height) {
                style.maxHeight = dims.max_height;
              }
            }

            adUnits.push({
              ...variant,
              unitName:
                collection.name + '/' + product.name + '/' + variant.name,
              auto_inject: product.auto_inject,
              class_tag,
              style
            });
          }
        }
      }

      return adUnits;
    }
  },
  mounted() {
    this.scriptTag();

    setTimeout(() => {
      this.inject3pTag('thirdPartyLocationA');
    }, 1000);

    setTimeout(() => {
      this.inject3pTag('thirdPartyLocationB');
    }, 2000);
  },
  methods: {
    inject3pTag(ref) {
      let thirdPartyTag = document.createElement('script');
      thirdPartyTag.src = this.thirdPartyTagUrl;
      window.$fdsk = window.$fdsk || {};
      window.$fdsk.run = window.$fdsk.run || [];
      window.$fdsk.run.push(function() {
        window.$fdsk.loadInlineAd(
          '940e1753-b279-4dc6-962b-bcc4d8335f33',
          '940e1753-f7cf-4012-b6f2-fc127a27b6e3'
        );
      });
      this.$refs[ref].after(thirdPartyTag);
    },
    scriptTag() {
      let j = document.createElement('script');
      j.id = 'flytedigital';
      j.async = true;
      j.src =
        process.env.VUE_APP_SCRIPT_TAG_SRC +
        '#' +
        this.$route.params.property_id;

      if (process.env.VUE_APP_DIGITAL_REMOTE_URL) {
        window.DIGITAL_REMOTE_URL = process.env.VUE_APP_DIGITAL_REMOTE_URL;
      }

      document.head.appendChild(j);
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

#preview-digital-install {
  .flytead {
    border: 1px dashed black;

    &.flytead-is-collapsed {
      display: flex !important;
      opacity: 0.5;
      border-color: gray;
    }
  }
}
</style>
