import { FeatureFlags } from '@/constants';
import {
  basket,
  chartLine as analyticsIcon,
  dashboard as dashboardIcon,
  dollar,
  helpCircled,
  ok,
  print,
  tag,
  user
} from '@/vendor/icons';

export default store => {
  const isAdShopAccessEnabled = store.getters['auth/feature'](
    FeatureFlags.AD_SHOP_ACCESS
  );

  const isV4AdsEnabled = store.getters['auth/feature'](FeatureFlags.V4_ADS);

  const isMediaKitBuilderEnabled = store.getters['auth/feature'](
    FeatureFlags.MEDIA_KIT_BUILDER
  );

  const isAccountingReportsEnabled = store.getters['auth/feature'](
    FeatureFlags.ACCOUNTING_REPORTS
  );

  const isAccountingInvoicesEnabled = store.getters['auth/feature'](
    FeatureFlags.ACCOUNTING_INVOICES
  );

  const isCustomerManagementEnabled = store.getters['auth/feature'](
    FeatureFlags.CUSTOMER_MANAGEMENT
  );

  const isSupplierDigitalDashboardEnabled = store.getters['auth/feature'](
    FeatureFlags.SUPPLIER_DIGITAL_DASHBOARD
  );

  const isPlacementSettingsEnabled = store.getters['auth/feature'](
    FeatureFlags.PLACEMENT_SETTINGS
  );

  let adShopSlug =
    store.state.auth.supplier &&
    store.state.auth.supplier.adShop &&
    store.state.auth.supplier.adShop.slug;

  let orders = [];
  let verifications = [];
  let billing = [];
  let products = [];
  let customers = [];
  let adShop = [];
  let analytics = [];
  let help = [];

  // Children of products
  let mediakit = [];
  let discounts = [];
  let digitalPreview = [];

  // ORDERS
  orders.push({
    name: 'Orders',
    icon: print,
    component: 'supplier.ads'
  });

  // DASHBOARD
  const dashboard = {
    name: 'Dashboard',
    icon: dashboardIcon,
    component: 'supplier.dashboard'
  };

  verifications.push({
    name: 'Verifications',
    component: isV4AdsEnabled ? 'supplier.ads' : 'supplier.verifications',
    icon: ok
  });

  const billingChildren = [
    {
      name: 'Ads',
      component: 'supplier.run-dates'
    }
  ];

  if (isAccountingInvoicesEnabled) {
    billingChildren.push({
      name: 'Invoices',
      component: 'supplier.invoices'
    });
  }

  if (isAccountingReportsEnabled) {
    billingChildren.push({
      name: 'Reports',
      component: 'supplier.accounting.reports'
    });
  }

  // Invoices / Ads Billing pages
  billing.push({
    name: 'Billing',
    icon: dollar,
    component: 'supplier.billing',
    children: billingChildren
  });

  // PRODUCTS CHILDREN
  let mediakitConfig = {
    name: 'Media Kit'
  };

  if (isMediaKitBuilderEnabled) {
    mediakitConfig.component = 'supplier.mediakit';
  } else {
    mediakitConfig.tooltip = 'Media kits are currently under construction.';
    mediakitConfig.disabled = true;
  }

  mediakit.push(mediakitConfig);

  discounts.push({
    name: 'Discounts',
    component: 'supplier.discounts'
  });

  if (store.getters['auth/can']('preview_digital')) {
    digitalPreview.push({
      name: 'Digital Preview',
      component: 'supplier.mediakit.digital-preview'
    });
  }

  const placementSettings = [];

  if (isPlacementSettingsEnabled) {
    placementSettings.push({
      name: 'Digital Ad Manager',
      component: 'supplier.mediakit.placements'
    });
  }

  // PRODUCTS
  products.push({
    name: 'Products',
    href: '',
    icon: tag,
    children: [
      ...mediakit,
      ...discounts,
      ...digitalPreview,
      ...placementSettings
    ]
  });

  // CUSTOMERS
  if (isCustomerManagementEnabled) {
    let customersConfig = {
      name: 'Customers',
      icon: user
    };

    customersConfig.component = 'supplier.customers';

    customers.push(customersConfig);
  }

  // AD SHOP
  if (isAdShopAccessEnabled) {
    adShop.push({
      name: 'Ad Shop',
      icon: basket,
      route: {
        name: 'adShop',
        params: {
          shopSlug: adShopSlug
        }
      }
    });
  }

  // Supplier Digital Analytics Dashboard
  if (isSupplierDigitalDashboardEnabled) {
    analytics.push({
      name: 'Digital Analytics',
      icon: analyticsIcon,
      route: {
        name: 'supplier.analytics.digital'
      }
    });
  }

  // HELP
  help.push({
    name: 'Help',
    icon: helpCircled,
    children: [
      {
        name: 'Support',
        href: 'https://www.flytedesk.com/publisher-support',
        target: '_blank'
      },
      {
        name: 'New Releases',
        href: 'https://www.flytedesk.com/publishers/updates',
        target: '_blank'
      },
      {
        name: 'Feedback',
        href: 'https://www.flytedesk.com/feedback',
        target: '_blank'
      }
    ]
  });

  // Hide these nav items for Influencer organizations
  if (
    store.getters['auth/isInfluencer'] &&
    !store.getters['auth/can']('takeover_suppliers')
  ) {
    billing = [];
    products = [];
    customers = [];
    adShop = [];
  }

  return [
    dashboard,
    ...orders,
    ...verifications,
    ...billing,
    ...products,
    ...customers,
    ...adShop,
    ...analytics,
    ...help
  ];
};
