export default rootPath => {
  return {
    path: `${rootPath}`,
    name: 'demo',
    redirect: {
      name: 'demo.audience'
    },
    component: () =>
      import(
        /* webpackChunkName: 'Demo' */
        '@/modules/Demo/LayoutDemo'
      ),
    children: [
      {
        path: 'audience',
        name: 'demo.audience',
        component: () =>
          import(
            /* webpackChunkName: 'Demo' */
            '@/modules/Demo/PageDemoAudience'
          )
      }
    ]
  };
};
