export const InviteStatus = {
  INVITE_SENT: 'INVITE_SENT',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED'
};

export const InviteLabels = {
  INVITE_SENT: 'Invitation Sent',
  REQUESTED: 'Access Requested'
};
