import store from '@/store';
import Vue from 'vue';

const TheAuthLayout = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/TheAuthLayout'
  );
const PageCompleteRegistration = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageCompleteRegistration'
  );
const PageCreateUser = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageCreateUser'
  );
const PageForgotPassword = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageForgotPassword'
  );
const PageNewPassword = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageNewPassword'
  );
const PageLogin = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageLogin'
  );
const PageFindCampus = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageFindCampus'
  );
const PageSignupForm = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageSignupForm'
  );
const PageJoinAccount = () =>
  import(
    /* webpackChunkName: 'Auth', webpackPrefetch: true */ '@/components/Auth/PageJoinAccount'
  );

export default rootPath => {
  return {
    path: rootPath,
    component: TheAuthLayout,
    redirect: { name: 'auth.login' },
    beforeEnter: async (to, from, next) => {
      if (to.name !== 'auth.logout' && store.getters['auth/isAuthenticated']) {
        // Rare condition where a user account gets created w/o a role assigned. This should not happen,
        // but mistakes can be (and have previously been) made
        if (!store.getters['auth/hasARoleAssigned']) {
          Vue.prototype.$message.error({
            message:
              'You do not have a Role assigned to your account. Please contact us for help.',
            showClose: true,
            duration: 0
          });
          next();
        } else {
          Vue.prototype.$message.warning('You already are signed in.');
          next({ name: 'home' });
        }
      } else {
        next();
      }
    },
    children: [
      {
        path: 'logout',
        name: 'auth.logout',
        beforeEnter(to, from, next) {
          store.dispatch('auth/logout').then(() => {
            next({ name: 'auth.login' });
          });
        }
      },
      {
        path: 'login',
        name: 'auth.login',
        component: PageLogin,
        meta: {
          title: 'Sign In'
        }
      },
      {
        path: 'forgot-password',
        name: 'auth.forgotPassword',
        component: PageForgotPassword,
        meta: {
          title: 'Reset'
        }
      },
      {
        path: 'new-password',
        name: 'auth.newPassword',
        props: route => ({
          email: route.query.email,
          token: route.query.token
        }),
        component: PageNewPassword,
        meta: {
          title: 'Reset'
        }
      },
      {
        path: 'buyer/signup',
        meta: { title: 'Sign Up' },
        redirect: { name: 'auth.createBuyerUser' },
        component: { template: '<router-view />' },
        children: [
          {
            path: 'create-account',
            name: 'auth.createBuyerUser',
            props: route => ({
              email: route.query.email,
              inviteId: route.query.invite,
              organization: route.query.buyer
            }),
            component: PageCreateUser,
            meta: { title: 'Sign Up' }
          }
        ]
      },
      {
        path: 'supplier/signup',
        meta: {
          title: 'Sign Up'
        },
        redirect: { name: 'auth.findCampus' },
        component: { template: '<router-view />' },
        children: [
          {
            path: 'create-account',
            name: 'auth.createSupplierUser',
            props: route => ({
              email: route.query.email,
              inviteId: route.query.invite,
              organization: route.query.supplier_org
            }),
            component: PageCreateUser,
            meta: {
              title: 'Sign Up'
            }
          },
          {
            path: 'findcampus',
            name: 'auth.findCampus',
            component: PageFindCampus,
            meta: {
              title: 'Sign Up'
            }
          },
          {
            path: 'myInfo',
            name: 'auth.signupForm',
            component: PageSignupForm,
            beforeEnter(to, from, next) {
              if (!store.getters['auth/signupInfo']) {
                next({
                  name: 'auth.findCampus'
                });
              }
              next();
            },
            meta: {
              title: 'Sign Up'
            }
          },
          {
            path: 'join',
            name: 'auth.joinAccount',
            component: PageJoinAccount,
            beforeEnter(to, from, next) {
              if (!store.getters['auth/signupInfo']) {
                next({
                  name: 'auth.findCampus'
                });
              }
              next();
            },
            meta: {
              title: 'Join Account'
            }
          }
        ]
      },
      {
        path: 'complete-registration/:userId/:data',
        meta: { title: 'Complete Registration' },
        name: 'auth.completeRegistration',
        component: PageCompleteRegistration
      }
    ]
  };
};
