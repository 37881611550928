<script>
import { getInvoiceTerms } from '@/graphql/common/terms.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  query() {
    return {
      query: getInvoiceTerms
    };
  }
};
</script>
