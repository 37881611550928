import { AccountType } from '@/constants';

const BuyerAds = () =>
  import(
    /* webpackChunkName: 'Buyer', webpackPrefetch: true */ '@/modules/Buyer/Ads/BuyerAds'
  );
const CampaignsTable = () =>
  import(
    /* webpackChunkName: 'Buyer', webpackPrefetch: true */ '@/modules/Buyer/Campaign/CampaignsTable'
  );
const PageBuyerDashboard = () =>
  import(
    /* webpackChunkName: 'Buyer', webpackPrefetch: true */ '@/modules/Buyer/PageBuyerDashboard'
  );
const PageBuyerVerifications = () =>
  import(
    /* webpackChunkName: 'BuyerVerifications', webpackPrefetch: true */ '@/modules/Buyer/Verifications/PageBuyerVerifications'
  );
const BuyerSettingsOrganizationTab = () =>
  import(
    /* webpackChunkName: 'Buyer', webpackPrefetch: true */ '@/modules/Buyer/BuyerSettingsOrganizationTab'
  );
const BuyerSettingsTeamTab = () =>
  import(
    /* webpackChunkName: 'Buyer', webpackPrefetch: true */ '@/modules/Buyer/BuyerSettingsTeamTab'
  );
const SettingsAccountTab = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Core/Settings/SettingsAccountTab'
  );
const SettingsLayout = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Core/Settings/SettingsLayout'
  );
const TheBuyerLayout = () =>
  import(
    /* webpackChunkName: 'Buyer', webpackPrefetch: true */ '@/modules/Buyer/TheBuyerLayout'
  );

export default rootPath => {
  return [
    {
      path: rootPath,
      component: TheBuyerLayout,
      meta: {
        auth: true,
        type: AccountType.BUYER
      },
      children: [
        {
          path: 'dashboard',
          name: 'buyer.dashboard',
          redirect: { name: 'buyer.dashboard.ads' },
          component: PageBuyerDashboard,
          meta: {
            title: 'Buyer Dashboard'
          },
          children: [
            {
              path: 'campaigns',
              name: 'buyer.dashboard.campaigns',
              component: CampaignsTable
            },
            {
              path: 'ads',
              name: 'buyer.dashboard.ads',
              component: BuyerAds
            }
          ]
        },
        {
          path: 'settings',
          name: 'buyer.settings',
          redirect: {
            name: 'buyer.settings.account'
          },
          component: SettingsLayout,
          meta: {
            title: 'Settings'
          },
          children: [
            {
              path: 'account',
              name: 'buyer.settings.account',
              component: SettingsAccountTab
            },
            {
              path: 'organization',
              name: 'buyer.settings.organization',
              component: BuyerSettingsOrganizationTab
            },
            {
              path: 'team',
              name: 'buyer.settings.team',
              component: BuyerSettingsTeamTab
            }
          ]
        },
        {
          path: 'verifications',
          name: 'buyer.verifications',
          component: PageBuyerVerifications,
          meta: {
            title: 'Verifications'
          }
        }
      ]
    }
  ];
};
