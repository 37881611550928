
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScheduleInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Schedule"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScheduleAttributes"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"quantityLimit"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuantityLimitInfo"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"scheduleType"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ScheduleTypeAttributes"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ScheduleAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Schedule"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"schedule_type_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"upcomingDates"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":370}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/QuantityLimitInfoFragment.graphql\"\n#import \"@/graphql/fragments/ScheduleTypeFragments.graphql\"\n\nfragment ScheduleInfo on Schedule {\n  ...ScheduleAttributes\n  quantityLimit {\n    ...QuantityLimitInfo\n  }\n  scheduleType {\n    ...ScheduleTypeAttributes\n  }\n}\n\nfragment ScheduleAttributes on Schedule {\n  id\n  schedule_type_id\n  upcomingDates\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/QuantityLimitInfoFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/ScheduleTypeFragments.graphql").definitions));


      module.exports = doc;
    
