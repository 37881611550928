export function setJsonItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getJsonItem(key) {
  const json = localStorage.getItem(key);

  if (json) {
    try {
      return JSON.parse(json);
    } catch (error) {
      console.error(`Failed parsing local storage key: ${key}`);
    }
  }

  return null;
}
