/*
 |-------------------------------------------------------------------------------
 | Colors                                    https://tailwindcss.com/docs/colors
 |-------------------------------------------------------------------------------
 |
 | Here you can specify the colors used in your project. To get you started,
 | we've provided a generous palette of great looking colors that are perfect
 | for prototyping, but don't hesitate to change them for your project. You
 | own these colors, nothing will break if you change everything about them.
 |
 | We've used literal color names ("red", "blue", etc.) for the default
 | palette, but if you'd rather use functional names like "primary" and
 | "secondary", or even a numeric scale like "100" and "200", go for it.
 |
 */

// ************  WARNING *****************************************
// ****  Each additional color adds significant overhead to   ****
// ****  bundle size and to compilation time. Add new colors  ****
// ****  sparingly.                                           ****
// ***************************************************************

module.exports = {
  transparent: 'transparent',

  // Blacks / Whites / Grays
  white: '#FFFFFF',
  'light-gray': '#F0F1F5',
  'very-light-gray': '#EEF0F5',
  'light-silver': '#E0E4EB',
  silver: '#E8EAEF',
  gray: '#CDD4E3',
  pewter: '#b1babf',
  'dark-silver': '#788193',
  gunmetal: '#475466',
  charcoal: '#5A5A5A',
  'dark-gray': '#333',

  // Blues
  'baby-blue': '#dfeffa',
  blue: '#5FAEE1',
  'dark-blue': '#3498D9',

  // Greens
  'light-green': '#D1EEDC',
  green: '#75C992',
  'dark-green': '#3E9D5F',

  // Reds
  'light-red': '#FBDFDE',
  red: '#E67878',
  'dark-red': '#E15B5B',

  // Purples
  'light-purple': '#e9e3f5',
  purple: '#9575CD',

  // Yellows
  yellow: '#F4D142',
  'dark-yellow': '#FDC72B'
};
