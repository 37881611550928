<script>
import Vue from 'vue';
import { subscribeToNotifications } from '@/graphql/user/newNotification.graphql';
import { SubscriptionHelper } from '@/components/Core/Helpers';

export default Vue.component('UserNewNotificationSubscription', {
  mixins: [SubscriptionHelper],
  subscription() {
    return {
      document: subscribeToNotifications,
      after(query, { subscriptionData }) {
        const me = query && query.me;

        if (!me) {
          return query || {};
        }

        const pendingNotifications = [...me.pendingNotifications];
        if (subscriptionData.data.newNotification) {
          pendingNotifications.push(subscriptionData.data.newNotification);
        }

        return {
          me: {
            __typename: 'User',
            id: query.me.id,
            pendingNotifications
          }
        };
      }
    };
  }
});
</script>
