import Vue from 'vue';

function emitBubble(typeArg, target, eventInit) {
  return (target || this.$el).dispatchEvent(
    new Event(typeArg, { bubbles: true, ...eventInit })
  );
}

export default {
  install() {
    Vue.prototype.$emitBubble = emitBubble;
  }
};
