import { extend } from 'lodash';

export const VueCookie = {
  install(Vue) {
    Vue.prototype.$cookie = cookie;
  }
};

export function cookie(key, value, o) {
  if (arguments.length > 1) {
    o = extend(
      {},
      {
        raw: false,
        domain: null,
        path: '/',
        expires: 365,
        secure: false
      },
      o
    );

    if (value === null) {
      o.expires = -1;
    } else if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    if (typeof o.expires === 'number') {
      let d = new Date();
      d.setDate(d.getDate() + o.expires);
      o.expires = d.toUTCString();
    }

    return (document.cookie = [
      encodeURIComponent(key),
      '=',
      o.raw ? String(value) : encodeURIComponent(String(value)),
      o.expires ? '; expires=' + o.expires : '', // use expires attribute, max-age is not supported by IE
      o.path ? '; path=' + o.path : '',
      o.domain ? '; domain=' + o.domain : '',
      o.secure ? '; secure' : ''
    ].join(''));
  }

  // key and possibly options given, get cookie...
  o = value || {};

  let decode = function(s) {
    if (o.raw) {
      return s;
    }
    s = decodeURIComponent(s);

    return s.match(/^{/) ? JSON.parse(s) || s : s;
  };

  let result = new RegExp(
    '(?:^|; )' + encodeURIComponent(key) + '=([^;]*)'
  ).exec(document.cookie);

  return result ? decode(result[1]) : null;
}
