<script>
import { cloneDeep, extend, get, upperFirst } from 'lodash';
import {
  firstElement,
  formatGraphQLError,
  getInputFields,
  readQuery,
  remove,
  renderless,
  updateQuery
} from '@/utils/helpers';

export default {
  props: {
    mutation: { type: String, default: null },
    mutating: Boolean,
    variables: { type: Object, default: null },
    showErrorMessage: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      // An Object explaining how to append the result to a list of data in the cache
      appendTo: null,
      // An Apollo Client flag weather to wait for all refetches to finish before completing the mutation
      awaitRefetchQueries: true,
      // An Object explaining how to delete a record from a list of data in the cache
      deleteFrom: null,
      // The GraphQL selections to refetch (used to lookup the Query names to refetch)
      refetchQueries: [],
      // The expected response from the server to apply immediately
      optimisticResponse: null,
      loading: false,
      error: null
    };
  },

  computed: {
    mutationMethods() {
      let mutation = this.getMutation();

      let methods = {};

      for (let methodName of Object.keys(mutation.mutations)) {
        let method = mutation.mutations[methodName];

        if (typeof mutation.mutations[methodName] !== 'function') {
          methodName = 'mutate' + upperFirst(methodName);

          if (!this[methodName]) {
            throw new Error(
              `You must implement the ${methodName} method in your component`
            );
          }

          method = this[methodName];
        }

        // Assign the mutation callback method to the method name which will be bound as a prop in the slot-scope
        methods[methodName] = method.bind(this);
      }

      return methods;
    }
  },

  mounted() {
    this.$emit('mounted', this.mutationMethods);
  },

  methods: {
    readQuery,
    updateQuery,
    getInputFields,

    getMutation() {
      return this.$options.mutation.call(this);
    },

    handleError(e, options) {
      let reportedMessage = formatGraphQLError(e);

      if (this.showErrorMessage) {
        this.$message.error({
          message: reportedMessage,
          duration: 10000,
          showClose: true,
          ...options
        });
      }

      this.error = e;
      this.loading = false;
      this.$emit('error', reportedMessage, e);
      this.$emit('always', null);
      this.$emit('mutating', false);
      this.$emit('update:mutating', false);
    },

    handleDone({ data }) {
      this.loading = false;
      this.$emit('data', firstElement(data));
      this.$emit('done', data);
      this.$emit('always', data);
      this.$emit('mutating', false);
      this.$emit('update:mutating', false);
    },

    /**
     * A helper method to add a record to a cached record list
     *
     * @param queryName - The name of the query you want to update
     * @param path - The callback method to resolve the list of records from the query results. Should return
     *               an Array of records you wish to append to.
     * @param responsePath - the callback method to resolve the item to append to the list
     */
    appendToCache(queryName, path, responsePath) {
      let query = this.getQueryByName(queryName);

      if (!query) {
        throw new Error(
          'Unable to locate the query with the name ' + queryName
        );
      }

      // if path is not set, assume the top level object is the Array to append to
      if (!path) {
        path = data => firstElement(data);
      }

      this.appendTo = {
        query,
        path,
        responsePath
      };
    },

    /**
     * A helper method to delete cached records out of a list.
     *
     * @param queryName - The name of the query you want to update
     * @param path - The callback method to resolve the list of records from the query results. Should return
     *               an Array of records containing the record to delete
     * @param iteratee (optional) - the iteratee to match against the record list
     */
    deleteFromCache(queryName, path, iteratee) {
      let query = this.getQueryByName(queryName);

      if (!query) {
        throw new Error(
          'Unable to locate the query with the name ' + queryName
        );
      }

      if (!path) {
        path = data => firstElement(data);
      }

      this.deleteFrom = {
        query,
        path,
        iteratee
      };
    },

    // TODO need the typename mapping somehow.... preferably dynamic
    setOptimisticResponse(originalData, newData, field) {
      this.optimisticResponse = { ...originalData, ...newData };
      this.field = field;
    },

    mapOptimisticResponse(mutation, fieldName, optimisticFields) {
      let selectionSet = mutation.definitions.find(
        def => def.kind === 'OperationDefinition'
      ).selectionSet;
      let optimisticResponse = {};
      parseSelectionSet(optimisticResponse, selectionSet);

      function parseSelectionSet(field, selectionSet) {
        selectionSet.selections.forEach(selection => {
          if (selection.name.value === fieldName) {
            field[selection.name.value] = optimisticFields;
          } else if (selection.selectionSet) {
            field[selection.name.value] = {};
            parseSelectionSet(
              field[selection.name.value],
              selection.selectionSet
            );
          } else {
            field[selection.name.value] = true;
          }
        });
      }

      return optimisticResponse;
    },

    /**
     * Refetch cached queries matching the given Selection Set name or names
     *
     * Example: For the given Query, a the name would be getCampaigns
     *
     * query getCampaigns {
     *   campaigns {
     *     id
     *   }
     * }
     *
     *        the refetchQuery for the mutation to complete
     * @param names
     */
    refetchCache(names) {
      if (Array.isArray(names)) {
        names.forEach(name => {
          this.refetchQueries = this.refetchQueries.concat(
            this.getQueriesByName(name)
          );
        });
      } else {
        let queries = this.getQueriesByName(names);
        this.refetchQueries = this.refetchQueries.concat(queries);
      }
    },

    cached(name, path) {
      const query = this.getQueryByName(name);

      if (!query) {
        throw new Error(`The query with the name ${name} was not found`);
      }

      const result = this.readQuery(query);

      if (path) {
        if (typeof path === 'string') {
          return get(result, path);
        } else if (typeof path === 'function') {
          return path(result);
        } else {
          throw new Error('Unexpected path object type: ' + typeof path);
        }
      }

      return result;
    },

    cachedArray(name, path) {
      const cachedResult = this.cached(name, path);

      if (!cachedResult) {
        throw new Error(
          `Error removing cached item: There was no data found in the query ${name} at the path ${path}`
        );
      }

      if (!Array.isArray(cachedResult)) {
        throw new Error(
          `Error removing cached item: The data in query ${name} at path ${path} was not an Array`
        );
      }

      return cachedResult;
    },

    /**
     * Retrieves a cached record set for the given query name and finds the data at the given path.
     * The data at the given path must be an array. The given item will be pushed on to the array
     * and the result array will be returned
     *
     * @param name
     * @param path
     * @param item
     * @return Array
     **/
    cachedAddItem(name, path, item) {
      const cachedArray = this.cachedArray(name, path);

      cachedArray.push(item);

      return cachedArray;
    },

    /**
     * Retrieves a cached record set for the given query name and finds the data at the given path.
     * The data at the given path must be an array, and using the given iteratee, this method will remove
     * the matching record from the array of data, and return the array.
     *
     * @param name
     * @param path
     * @param iteratee
     * @return Array
     **/
    cachedRemoveItem(name, path, iteratee) {
      const cachedArray = this.cachedArray(name, path);

      remove(cachedArray, iteratee);

      return cachedArray;
    },

    /**
     * Looks up a Query Watcher by the name of the query
     */
    getQueryByName(name) {
      let cache = this.$apollo.provider.defaultClient.cache;

      for (let watch of cache.watches) {
        let definitions = watch.query.definitions;

        for (let definition of definitions) {
          if (definition.name.value === name) {
            return {
              query: watch.query,
              variables: watch.variables
            };
          }
        }
      }

      return null;
    },

    getQueriesByName(name) {
      let cache = this.$apollo.provider.defaultClient.cache;

      let queries = [];

      for (let watch of cache.watches) {
        let definitions = watch.query.definitions;

        for (let definition of definitions) {
          if (definition.name.value === name) {
            queries.push({
              query: watch.query,
              variables: watch.variables
            });
          }
        }
      }

      return queries;
    },

    /**
     * Delete a record from the Apollo cache
     *
     * @param matchers - the String or RegEx to match against the cache ID
     */
    deleteCache(matchers) {
      let cache = this.$apollo.provider.defaultClient.cache;

      let storeData = cache.data.data;
      matchers.forEach(matcher => {
        let regEx = new RegExp(matcher, 'i');

        for (let dataId of Object.keys(storeData)) {
          if (dataId.match(regEx)) {
            cache.data.delete(dataId);
          }
        }
      });
    },

    /**
     * Cast and filter the fields based on the given field type map in the global mutation settings
     */
    castFields(data, fields) {
      if (fields) {
        return this.getInputFields(data, fields);
      }

      return data;
    },

    /**
     * Post data to GraphQL to mutate a resource in the backend
     *
     * @param mutation
     * @param data
     * @param options
     * @returns {Promise<void>}
     */
    post(mutation, data, options) {
      let globals = this.getMutation();
      let variables = extend(globals.variables || {}, data);

      if (!mutation) {
        throw new Error(
          'Your mutation was not set! You must define the mutation when calling the MutationHelper post() method. Double check you are getting the correct mutation from your graphQL file!'
        );
      }

      // Clone our settings for this post so we can reset them in case there is another request immediately after
      // otherwise our data would get changed mid-request
      let appendTo = cloneDeep(this.appendTo);
      let deleteFrom = cloneDeep(this.deleteFrom);
      let refetchQueries = cloneDeep(this.refetchQueries);
      let awaitRefetchQueries = this.awaitRefetchQueries;
      let optimisticResponse = cloneDeep(this.optimisticResponse);

      // Reset all the fields above to prepare for next request
      this.reset();

      let params = {
        mutation,
        variables,
        error: null,
        optimisticResponse,
        ...options
      };

      // The original parent update callback (be sure to call this on update!)
      let parentUpdate = params.update;

      // Mutation update based requests (append / delete data, etc.)
      params.update = (store, response) => {
        let responseData = firstElement(response.data);

        if (appendTo) {
          this.updateQuery(store, appendTo.query, data => {
            // Resolve which data item should be appended to the list
            let appendData = appendTo.responsePath
              ? appendTo.responsePath(responseData)
              : responseData;

            if (appendData !== undefined) {
              // Retrieve the correct list to append to, then push the appendData item onto the list
              let array = appendTo.path(data, responseData);

              if (Array.isArray(array)) {
                array.push(appendData);
              }
            }
          });
        }

        if (deleteFrom) {
          this.updateQuery(store, deleteFrom.query, data => {
            let iteratee = deleteFrom.iteratee || { id: variables.id };
            let array = deleteFrom.path(data, iteratee, remove);

            if (array) {
              remove(array, iteratee);
            }
          });
        }

        // Call the original update callback if it was set
        if (parentUpdate) {
          parentUpdate(store, response, responseData);
        }
      };

      // Resolve the refetch Queries if they are requested
      if (refetchQueries.length > 0) {
        params.refetchQueries = refetchQueries;
        params.awaitRefetchQueries = awaitRefetchQueries;
      }

      // Commit
      return this.commitMutation(params);
    },

    /**
     * Executes the Apollo Mutations and handles the response / error
     */
    commitMutation(params) {
      // Commit the mutation
      this.loading = true;
      this.error = null;
      this.$emit('mutating', true);
      this.$emit('update:mutating', true);

      return new Promise((resolve, reject) => {
        this.$apollo
          .mutate(params)
          .then(result => {
            this.handleDone(result);
            resolve(firstElement(result.data));
          })
          .catch(e => {
            this.handleError(e);

            if (params.error) {
              params.error(e);
            }

            reject(e);
          });
      });
    },

    /**
     * Resets the state of the Mutation object to prepare for the next mutation
     */
    reset() {
      this.appendTo = null;
      this.awaitRefetchQueries = true;
      this.deleteFrom = null;
      this.refetchQueries = [];
      this.optimisticResponse = null;
    }
  },

  // Props, data, methods, etc.
  render(c) {
    return renderless.call(this, c, {
      ...this.$data,
      isSaving: this.loading,
      ...this.mutationMethods
    });
  }
};
</script>
