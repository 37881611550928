<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  computed: {
    hasFreshServiceWorker() {
      return window.swReg && window.swReg.isFresh;
    },
    appVersionMismatch() {
      return this.$store.getters['common/appVersionMismatch'];
    }
  },
  watch: {
    appVersionMismatch() {
      if (this.appVersionMismatch) {
        this.display();
      }
    }
  },
  mounted() {
    if (this.hasFreshServiceWorker) {
      // this.display();
    }

    if (this.appVersionMismatch) {
      this.display();
    }

    document.addEventListener('sw-updated', () => {
      // this.display();
    });

    document.addEventListener('sw-refresh', () => {
      if (window.swReg && window.swReg.registration) {
        window.swReg.registration.waiting.postMessage({
          action: 'skipWaiting'
        });
      } else {
        location.reload();
      }
    });
  },
  methods: {
    display() {
      this.$notify({
        title: 'Update Available!',
        message: `
              <div>You are running an older version of the application. Please refresh your page to avoid any issue.</div>
              <button
                class="button-blue focus:outline-none cursor-pointer px-4 py-3 mt-4"
                type="button"
                onClick="document.dispatchEvent(new Event('sw-refresh', { bubbles: true }))"
              >Refresh</button>`,
        dangerouslyUseHTMLString: true,
        duration: 0,
        offset: 50,
        ...this.options
      });
    }
  },
  render() {
    return null;
  }
};
</script>
