<script>
import {
  resetPasswordMutation,
  sendResetPasswordEmailMutation
} from '@/graphql/auth/passwordMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        resetPassword(input) {
          this.post(resetPasswordMutation, { input });
        },
        sendResetPasswordEmail(email) {
          this.post(sendResetPasswordEmailMutation, { email });
        }
      }
    };
  }
};
</script>
