export function headGuards(router) {
  // Title guard
  //
  // Example:
  //   meta: {
  //     title: 'Home Page'
  //   }
  router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle)
      document.title = `${nearestWithTitle.meta.title} | flytedesk`;
    else document.title = 'flytedesk';

    next();
  });

  // Meta guard
  //
  // Example:
  //   meta: {
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'The home page of our example app.'
  //       }
  //     ]
  //   }
  router.beforeEach((to, from, next) => {
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.metaTags);

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
      document.querySelectorAll('[data-vue-router-controlled]')
    ).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    const elements = nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    });

    // Add the meta tags to the document head.
    elements.forEach(tag => document.head.appendChild(tag));

    next();
  });
}
