const TheLayoutAdmin = () =>
  import(
    /* webpackChunkName: 'Admin', webpackPrefetch: true */
    '@/components/Admin/TheLayoutAdmin'
  );
const PageMediakitProducts = () =>
  import(
    /* webpackChunkName: 'SuperMediaKit', webpackPrefetch: true */
    '@/components/Admin/MediaKit/Products/PageMediakitProducts'
  );

const PageMediakitTags = () =>
  import(
    /* webpackChunkName: 'SuperMediaKit', webpackPrefetch: true */
    '@/components/Admin/MediaKit/Tags/PageMediakitTags'
  );

import { FeatureFlags } from '@/constants';

export default rootPath => {
  return {
    path: `${rootPath}/mediakit`,
    name: 'admin.mediakit',
    redirect: { name: 'admin.mediakit.tags' },
    component: TheLayoutAdmin,
    meta: { auth: true, feature: FeatureFlags.SUPER_MEDIA_KIT },
    children: [
      {
        path: 'tags',
        name: 'admin.mediakit.tags',
        meta: { title: 'Media Kit Tags' },
        component: PageMediakitTags
      },
      {
        path: 'national-products',
        name: 'admin.mediakit.nationalproducts',
        meta: { title: 'Media Kit National Products' },
        component: PageMediakitProducts
      }
    ]
  };
};
