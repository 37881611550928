<script>
import { QueryHelper } from '@/components/Core/Helpers';
import { getTakeoverBuyers } from '@/graphql/buyer/queries.graphql';

export default {
  mixins: [QueryHelper],

  query(props) {
    return {
      query: getTakeoverBuyers,
      variables: {
        filter: props.filter
      }
    };
  }
};
</script>
