import LoaderBox from './LoaderBox';
import LoaderContent from './LoaderContent';
import LoaderGrid from './LoaderGrid';
import LoaderHeading from './LoaderHeading';
import LoaderImageContentColumns from './LoaderImageContentColumns';
import LoaderMenu from './LoaderMenu';
import LoaderMenuContentColumns from './LoaderMenuContentColumns';
import LoaderTableRows from './LoaderTableRows';
import LoaderText from '@/components/Core/Loaders/LoaderText';
import LoadingButton from '@/components/Core/Loading/LoadingButton';

export {
  LoaderBox,
  LoaderContent,
  LoaderGrid,
  LoaderHeading,
  LoaderImageContentColumns,
  LoaderMenu,
  LoaderMenuContentColumns,
  LoaderTableRows,
  LoaderText,
  LoadingButton
};

export default {
  LoaderBox,
  LoaderContent,
  LoaderGrid,
  LoaderHeading,
  LoaderImageContentColumns,
  LoaderMenu,
  LoaderMenuContentColumns,
  LoaderTableRows,
  LoaderText,
  LoadingButton
};
