
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Paginator"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PaginatorInfo"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currentPage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasMorePages"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastPage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"perPage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"total"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":315}};
    doc.loc.source = {"body":"fragment Paginator on PaginatorInfo {\n  count\n  currentPage\n  hasMorePages\n  lastPage\n  perPage\n  total\n\n  # There is a bug with lighthouse where it declares firstItem and lastItem as non-nullable,\n  # but if there is an empty result set these will be null... so don't enable this shit\n  # firstItem\n  # lastItem\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
