<script>
import {
  createDiscount,
  deleteDiscount,
  updateDiscount
} from '@/graphql/discount/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

const discountFields = {
  name: String,
  code: String,
  type: String,
  value: Number,
  start_date: Date,
  end_date: Date,
  uses: Number,
  status: Boolean,
  notes: String
};

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        createDiscount(newData) {
          let input = this.castFields(newData, discountFields);

          this.post(createDiscount, { input });
        },
        deleteDiscount({ id }) {
          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateDiscount: {
                __typename: 'DiscountMutation',
                delete: true
              },
              supplier: {
                __typename: 'Supplier',
                id: this.supplierId,
                discounts: this.cachedRemoveItem(
                  'getSupplierDiscounts',
                  'supplier.discounts',
                  { id }
                )
              }
            }
          };

          this.post(deleteDiscount, { id });
        },
        updateDiscount(originalData, newData) {
          let input = this.castFields(newData, discountFields);

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateDiscount: {
                __typename: 'DiscountMutation',
                update: true,
                discount: {
                  ...originalData,
                  ...newData
                }
              }
            }
          };

          this.post(updateDiscount, { id: originalData.id, input });
        }
      },
      variables: {
        supplier_id: this.supplierId
      }
    };
  },

  computed: {
    supplierId() {
      return this.$store.getters['auth/supplierId'];
    }
  }
};
</script>
