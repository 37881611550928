import {
  disable,
  docText,
  dollar,
  money,
  paperPlane,
  trashEmpty
} from '@/vendor/icons';
import { objectMap } from '@/utils/helpers';

export const InvoiceStatus = {
  CHARGED: {
    label: 'Charged',
    icon: {
      icon: money,
      class: 'text-green hover:text-dark-green',
      value: 'Charged',
      name: 'Charged'
    }
  },
  FAILED_PAYMENT: {
    label: 'Failed Payment',
    icon: {
      icon: dollar,
      class: 'text-red hover:text-dark-red',
      value: 'Failed Payment',
      name: 'Failed Payment'
    }
  },
  PAID: {
    label: 'Paid',
    icon: {
      icon: dollar,
      class: 'text-green hover:text-dark-green',
      value: 'Paid',
      name: 'Paid'
    }
  },
  PARTIALLY_PAID: {
    label: 'Partially Paid',
    icon: {
      icon: dollar,
      class: 'text-yellow hover:text-dark-yellow',
      value: 'Partially Paid',
      name: 'Partially Paid'
    }
  },
  DRAFT: {
    label: 'Draft',
    icon: {
      icon: docText,
      class: 'text-gray hover:text-dark-silver',
      value: 'Draft',
      name: 'Draft'
    }
  },
  SENT: {
    label: 'Sent To Buyer',
    icon: {
      icon: paperPlane,
      class: 'text-blue hover:text-dark-blue',
      value: 'Sent',
      name: 'Sent'
    }
  },
  VOID: {
    label: 'Void',
    icon: {
      icon: disable,
      class: 'text-red hover:text-dark-red',
      value: 'Void',
      name: 'Void'
    }
  },
  DELETING: {
    label: 'Deleting',
    icon: {
      icon: trashEmpty,
      class: 'text-red hover:text-dark-red',
      value: 'Deleting',
      name: 'Deleting'
    }
  }
};

export const InvoiceStatusKeys = objectMap(InvoiceStatus, key => [key, key]);

export const InvoiceStatusLabels = objectMap(InvoiceStatus, (key, value) => [
  key,
  value.label
]);

export const InvoiceStatusIcons = objectMap(InvoiceStatus, (key, value) => [
  key,
  value.icon
]);
