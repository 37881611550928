import nprogress from 'nprogress/nprogress.js';

const actions = {
  start() {
    nprogress.start();
  },
  done(force = false) {
    nprogress.done(force);
  }
};

export default {
  namespaced: true,
  actions
};
