export const ScheduleType = {
  NON_CONTIGUOUS: 'NON_CONTIGUOUS',
  CONTIGUOUS: 'CONTIGUOUS',
  NONE: 'NONE'
};

export const ScheduleTypeValue = {
  CONTIGUOUS: 'Contiguous',
  NON_CONTIGUOUS: 'Non-Contiguous'
};

export const ScheduleTypeLabel = {
  Contiguous: 'By Time Block',
  'Non-contiguous': 'By Day',
  None: 'None'
};

export const ScheduleTypeTitle = {
  Contiguous: 'Book by Time Block',
  'Non-contiguous': 'Book by Day',
  None: 'None'
};

export const ScheduleUnit = {
  DAYS: 'Days',
  WEEKS: 'Weeks'
};

export const ScheduleUnits = [ScheduleUnit.DAYS, ScheduleUnit.WEEKS];
