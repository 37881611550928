const MediakitCollection = () =>
  import(
    /* webpackChunkName: 'Mediakit' */ '@/components/Supplier/Mediakit/Collection/MediakitCollection'
  );
const MediakitMedium = () =>
  import(
    /* webpackChunkName: 'Mediakit' */ '@/components/Supplier/Mediakit/Medium/MediakitMedium'
  );
const MediakitProperty = () =>
  import(
    /* webpackChunkName: 'Mediakit' */ '@/components/Supplier/Mediakit/Property/MediakitProperty'
  );
const PageMediakit = () =>
  import(
    /* webpackChunkName: 'Mediakit' */ '@/components/Supplier/Mediakit/PageMediakit'
  );
const TheMediakit = () =>
  import(
    /* webpackChunkName: 'Mediakit' */ '@/components/Supplier/Mediakit/TheMediakit'
  );
const TheMediakitSidebar = () =>
  import(
    /* webpackChunkName: 'Mediakit' */ '@/components/Supplier/Mediakit/TheMediakitSidebar'
  );

export default rootPath => {
  return {
    path: rootPath,
    component: PageMediakit,
    meta: { auth: true, title: 'Media Kits' },
    children: [
      {
        path: '/',
        name: 'supplier.mediakit',
        component: TheMediakit,
        meta: { title: 'Media Kit Property' }
      },
      {
        path: 'medium/:id',
        name: 'supplier.mediakit.medium',
        components: {
          sidebar: TheMediakitSidebar,
          default: MediakitMedium
        },
        meta: { title: 'Media Kit Medium' }
      },
      {
        path: 'property/:id',
        name: 'supplier.mediakit.property',
        components: {
          sidebar: TheMediakitSidebar,
          default: MediakitProperty
        },
        meta: { title: 'Media Kit Property' }
      },
      {
        path: 'collection/:id',
        name: 'supplier.mediakit.collection',
        components: {
          sidebar: TheMediakitSidebar,
          default: MediakitCollection
        },
        meta: { title: 'Media Kit Collection' }
      }
    ]
  };
};
