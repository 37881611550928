<script>
import { getNationalAds } from '@/graphql/admin/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    pager: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: Object,
      default: () => ({})
    },
    sort: {
      type: Object,
      default: () => ({})
    }
  },

  query(props) {
    return {
      query: getNationalAds,
      variables: {
        ...props.pager,
        filter: props.filter,
        sort: props.sort
      }
    };
  }
};
</script>
