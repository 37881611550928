<script>
import { getSupplierInvoices } from '@/graphql/supplier/getSupplierInvoices.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    pager: {
      type: Object,
      default: null
    }
  },
  query() {
    return {
      query: getSupplierInvoices,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        ...(this.pager || this.$store.getters['invoices/pager'])
      }
    };
  }
};
</script>
