<script>
import { MutationHelper } from '@/components/Core/Helpers';
import {
  createNationalTagPath,
  deleteNationalTagPath,
  updateNationalTagPath
} from '@/graphql/admin/superMediaKitMutations.graphql';

export default {
  mixins: [MutationHelper],
  props: {},
  mutation() {
    return {
      mutations: {
        createNationalTagPath(input) {
          this.appendToCache(
            'getNationalProducts',
            data => data.nationalTagPaths.data,
            response => response.nationalTagPath
          );
          return this.post(createNationalTagPath, { input });
        },
        updateNationalTagPath(id, input) {
          this.refetchCache('getFormsByCategory');
          return this.post(updateNationalTagPath, { id, input });
        },
        deleteNationalTagPath(id) {
          this.deleteFromCache(
            'getNationalProducts',
            data => data.nationalTagPaths.data,
            { id }
          );
          return this.post(deleteNationalTagPath, { id });
        }
      }
    };
  }
};
</script>
