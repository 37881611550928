import router from '@/router';
import Vue from 'vue';

function triggerEvent(type, code, e) {
  switch (code) {
    case 'a':
    case 65:
      if (type === 'keydown' && e.ctrlKey && e.altKey) {
        this.$emit('toggle-admin-nav');
      }
      break;

    case 'b':
    case 66:
      if (type === 'keydown' && e.ctrlKey && e.altKey) {
        this.$emit('takeover-buyer');
      }
      break;

    case 'c':
    case 67:
      if (type === 'keydown' && e.ctrlKey) {
        if (e.shiftKey) {
          this.$emit('admin-change-supplier');
        } else if (!e.altKey) {
          this.$emit('manual-copy');
        }
      }
      break;

    case 'p':
    case 80:
      if (type === 'keydown' && e.ctrlKey && e.altKey) {
        this.$emit('takeover-supplier');
      }
      break;

    case '4':
    case 52:
      if (type === 'keydown' && e.ctrlKey && e.altKey) {
        this.$emit('admin-v4');
      }
      break;

    case 'enter':
    case 13:
      if (type === 'keyup') {
        this.$emit('enter');
      }
      break;

    case 'shift':
    case 16:
      if (type === 'keydown') {
        this.$emit('shift-key');
      }

      if (type === 'keyup') {
        this.$emit('shift-key-release');
      }
      break;

    case 'escape':
    case 27:
      if (type === 'keyup') {
        this.$emit('escape');
      }
      break;
  }
}

function getCode(e) {
  return e.keyCode || ('' + e.key).toLowerCase();
}

export const KeyEventBus = new Vue({
  created() {
    window.addEventListener('keydown', e => {
      triggerEvent.call(this, 'keydown', getCode(e), e);
    });

    window.addEventListener('keyup', e => {
      triggerEvent.call(this, 'keyup', getCode(e), e);
    });
  }
});

export default {
  install() {
    window.addEventListener('message', event => {
      let message = event.data;

      if (typeof message === 'object') {
        if (message.from === 'flytedesk') {
          const payload = message.payload;

          let iframeEl = Array.from(
            document.getElementsByTagName('iframe')
          ).find(iframe => {
            return iframe.src === message.href;
          });

          // Trigger any key events on the current frame
          if (payload.keyEvent) {
            KeyEventBus.$emit(payload.keyEvent);
          }

          // Fire any custom events directly on the iframe so any listeners can respond
          if (payload.event) {
            iframeEl.dispatchEvent(
              new CustomEvent('v4-event', { detail: { payload } })
            );
          }
        }
      }

      // Check the origin of the data!
      if (event.origin === process.env.VUE_APP_API_URL) {
        if (event.data?.route) {
          router.push(event.data.route);
        } else if (event.data === 'print') {
          window.print();
        }
      }
    });

    Vue.prototype.$onKeyEvent = (name, callback) => {
      KeyEventBus.$on(name, callback);
    };

    Vue.prototype.$offKeyEvent = (name, callback) => {
      KeyEventBus.$off(name, callback);
    };
  }
};
