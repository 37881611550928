import { find } from 'lodash';
import { remove } from '@/utils/helpers';

/**
 * Maps a callback function to each element at the end of the given path
 *
 * @param list
 * @param path
 * @param callback
 * @returns {*}
 */
export function mapPath(list, path, callback) {
  if (path.length === 0) {
    return callback(list);
  }

  for (let item of list) {
    let pathItem = item[path[0]];

    if (pathItem && pathItem.length > 0) {
      mapPath(pathItem, path.slice(1), callback);
    }
  }
}

/**
 * Removes an element from the array at the given nested object path. (see countPath)
 *
 * @param list
 * @param path
 * @param e
 * @returns {*} - returns the removed elements or false if the element is not found
 */
export function removePath(list, path, e) {
  if (path.length === 0) {
    return remove(list, e);
  }

  for (let item of list) {
    let pathItem = item[path[0]];

    if (pathItem && pathItem.length > 0) {
      let removedElement = removePath(pathItem, path.slice(1), e);

      if (removedElement) {
        return removedElement;
      }
    }
  }

  return false;
}

/**
 * Counts the # of times a property exists in an array of nested objects
 *
 * list should be of the form:
 * [
 *   {
 *     props: [
 *       { ... },
 *       ...
 *     ]
 *   },
 *   ...
 * ]
 *
 * @param list - an array containing nested objects
 * @param path - the path to follow in a nested object where each property in the path
 *  is an array of objects. The last element can be any type.
 *
 * @returns {*}
 */
export function countPath(list, path) {
  if (path.length === 0) {
    return list.length;
  }

  let total = 0;

  for (let item of list) {
    if (item[path[0]]) {
      total += countPath(item[path[0]], path.slice(1));
    }
  }

  return total;
}

/**
 * Searches an array of nested object's path to find the object with the matching iteratee
 *
 * @param list
 * @param path
 * @param iteratee - a lodash iteratee to match against the list of objects (passed as arg 2 of lodash's find method)
 * @returns {*}
 */
export function findPath(list, path, iteratee) {
  if (path.length === 0) {
    return find(list, iteratee);
  }

  for (let item of list) {
    if (item[path[0]]) {
      let found = findPath(item[path[0]], path.slice(1), iteratee);

      if (found) {
        return found;
      }
    }
  }

  return null;
}

/**
 * Searches an array of nested object's path to find the object with the matching iteratee and return the top level
 * element in the array
 *
 * @param list
 * @param path
 * @param iteratee - a lodash iteratee to match against the list of objects (passed as arg 2 of lodash's find method)
 * @returns {*}
 */
export function findRootPath(list, path, iteratee) {
  if (path.length === 0) {
    return find(list, iteratee);
  }

  for (let item of list) {
    if (item[path[0]]) {
      let found = findRootPath(item[path[0]], path.slice(1), iteratee);

      if (found) {
        return item;
      }
    }
  }

  return null;
}

/**
 * Recursively flattens a nested object based on the path, which is a list of properties in an object.
 *
 * Similar to count(), except it creates a flat list of the elements instead of returning the length
 *
 * @param list
 * @param path
 * @returns {*}
 */
export function flattenPath(list, path) {
  if (path.length === 0) {
    return list;
  }

  let flatList = [];

  if (Array.isArray(list)) {
    for (let item of list) {
      if (item[path[0]]) {
        let flatItem = flattenPath(item[path[0]], path.slice(1));

        if (flatItem instanceof Array) {
          flatList = flatList.concat(flatItem);
        } else {
          flatList.push(flatItem);
        }
      }
    }
  } else {
    return flattenPath(list[path[0]], path.slice(1));
  }

  return flatList;
}
