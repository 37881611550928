export function NProgressGuard(store, router) {
  // NProgress
  router.beforeEach((to, from, next) => {
    store.dispatch('nprogress/start');
    next();
  });

  router.afterEach(() => {
    store.dispatch('nprogress/done');
  });
}
