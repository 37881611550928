
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DimensionAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Dimension"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"width"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"height"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"depth"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"width_ratio"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"height_ratio"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"max_width"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"max_height"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unit"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":137}};
    doc.loc.source = {"body":"fragment DimensionAttributes on Dimension {\n  id\n  width\n  height\n  depth\n  width_ratio\n  height_ratio\n  max_width\n  max_height\n  unit\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
