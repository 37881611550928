import { cloneDeep, debounce } from 'lodash';

import masks from './masks';

/**
 * Applies a mask to a given value. The mask should be an array of chars to check against each char in the input val.
 * Each mask element should be either a single char or a regex that matches a single char
 * eg: as phone number mask for (555) 555-5555 would look like:
 * ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
 *
 * @param val
 * @param mask
 * @returns {string}
 */
function applyMask(val, mask) {
  let chars = val.split('');

  let newVal = '';
  let charIndex = 0;
  let maskIndex = 0;

  while (mask[maskIndex] !== undefined && chars[charIndex] !== undefined) {
    let maskVal = mask[maskIndex];
    let charVal = chars[charIndex];

    if (typeof mask[maskIndex] === 'string') {
      newVal += maskVal;

      // If the char is correct go to the next
      if (maskVal === charVal) {
        charIndex++;
      }

      maskIndex++;
    } else {
      if (charVal.match(maskVal)) {
        newVal += charVal;

        maskIndex++;
      }

      charIndex++;
    }
  }

  return newVal;
}

/**
 * Callback function when the input element value has changed
 *
 * @param options
 * @param binding
 */
function inputElementChanged(options, binding) {
  if (this._lastValue !== this.value) {
    let newVal = this.value;

    if (options.mask) {
      // value should always be a string
      newVal = '' + applyMask(newVal, options.mask);
    }

    if (typeof options.apply === 'function') {
      // value should always be a string
      newVal = '' + options.apply(newVal, binding);
    }

    if (this.value !== newVal) {
      // assign the new value if different
      this.value = newVal;
      // Track the previous value so we only apply the mask when the value changes
      // (also handles blocking infinite looping input events)
      this._lastValue = newVal;
      this.dispatchEvent(new Event('input'));
    }
  }
}

/**
 * Finds the nested input element if the v-mask is applied to a parent
 *
 * @param el
 * @returns {*}
 */
function resolveInputElement(el) {
  if (el.nodeName.toUpperCase() === 'INPUT') return el;

  for (let child of el.children) {
    let input = resolveInputElement(child);

    if (input) {
      return input;
    }
  }

  return null;
}

/**
 *
 * @param el
 * @param binding
 */
function bindMasks(el, binding) {
  let maskList = Object.keys(binding.modifiers);

  if (maskList.length === 0) {
    if (binding.value) {
      if (typeof binding.value === 'object') {
        let mask, maskName, newBinding;

        if (binding.value.name) {
          maskName = binding.value.name;
          mask = masks[maskName];
          newBinding = { ...binding, value: binding.value.params };
        } else {
          mask = binding.value;
          maskName = 'anonymous';
          newBinding = { ...binding, value: null };
        }

        bindMaskToElement(el, newBinding, maskName, cloneDeep(mask));
      } else if (typeof binding.value === 'string') {
        let mask = masks[binding.value];

        if (mask) {
          bindMaskToElement(el, binding, binding.value, cloneDeep(mask));
        }
      }
    }
  } else {
    for (let name of maskList) {
      let mask = masks[name];

      if (mask) {
        bindMaskToElement(el, binding, name, cloneDeep(mask));
      }
    }
  }
}

// TODO: Should maybe refactor this so we only bind an event listener 1 time per element,
//       instead of 1 time per mask on each element
function bindMaskToElement(el, binding, name, mask) {
  mask.inputElement = resolveInputElement(el);

  let callback = inputElementChanged;

  if (mask.delay) {
    // If a delay is set, only call the callback 1 time for a grouping of input from the user after the delay period
    callback = debounce(inputElementChanged, mask.delay);

    // We need to make sure the element has the correct value when it is changed, but we do not want to immediately
    // change the value displayed in the input field, which may confuse / mess up the user's input
    mask.inputElement.parentElement.addEventListener(
      'change',
      () => {
        callback.call(mask.inputElement, mask, binding);
      },
      true
    );
  }

  const cbKey = 'mask-callback-' + name;

  // The apply function simply takes the value, applies the mask, then updates the input element.
  if (el[cbKey]) {
    mask.inputElement.removeEventListener('input', el[cbKey]);
  }

  // Assign and save the callback so we can remove it later
  el[cbKey] = () => {
    callback.call(mask.inputElement, mask, binding);
  };

  mask.inputElement.addEventListener('input', el[cbKey], false);

  // Mask the initial value of the input element
  el[cbKey]();
}

export default {
  // We may have to bind an event listener on the parent, so we need to wait until
  // the element is inserted into its parent before binding the mask
  inserted: bindMasks,

  update(el, binding) {
    // If the binding instruction is the same, then no rebinding necessary
    if (JSON.stringify(binding.value) === JSON.stringify(binding.oldValue)) {
      let inputElement = resolveInputElement(el);

      if (inputElement) {
        // Let's make sure we re-apply the mask in case something changed the value outside of a user input
        setTimeout(() => {
          // A way to track last resolved value so we don't
          // end up in an update loop
          if (inputElement.dataset.maskLastValue !== inputElement.value) {
            inputElement.dataset.maskLastValue = inputElement.value;
            inputElement.dispatchEvent(new Event('input'));
          }
        }, 1);
      }
    } else {
      // Otherwise we need to update the binding
      bindMasks(el, binding);
    }
  }
};
