export const Timescale = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
};

export const ScaleOptions = [
  {
    label: 'Day',
    value: Timescale.DAY
  },
  {
    label: 'Week',
    value: Timescale.WEEK
  },
  {
    label: 'Month',
    value: Timescale.MONTH
  }
];
