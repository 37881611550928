<script>
import { addOrderRep, removeOrderRep } from '@/graphql/order/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        addOrderRep(order, user_id) {
          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateOrder: {
                __typename: 'OrderMutation',
                addOrderRep: true,
                order: {
                  __typename: 'Order',
                  id: order.id,
                  reps: [
                    ...order.reps,
                    {
                      id: user_id,
                      name: null,
                      first_name: null,
                      last_name: null,
                      email: null,
                      phone: null,
                      __typename: 'User'
                    }
                  ]
                }
              }
            }
          };

          return this.post(addOrderRep, { id: order.id, user_id });
        },

        removeOrderRep(id, user_id) {
          return this.post(removeOrderRep, { id, user_id });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
