const PageSupplierBilling = () =>
  import(
    /* webpackChunkName: 'Billing' */ '@/components/Supplier/Billing/PageSupplierBilling'
  );
const SupplierBillingRunDates = () =>
  import(
    /* webpackChunkName: 'Billing' */
    '@/components/Supplier/Billing/SupplierBillingRunDates'
  );
const SupplierBillingInvoices = () =>
  import(
    /* webpackChunkName: 'Billing' */
    '@/components/Supplier/Invoices/SupplierBillingInvoices'
  );
const PageInvoiceDocument = () =>
  import(
    /* webpackChunkName: 'Billing' */
    '@/components/Supplier/Billing/Document/InvoiceDocument/PageInvoiceDocument'
  );
const PageVerifications = () =>
  import(
    /* webpackChunkName: 'Verifications' */ '@/components/Supplier/Verifications/PageSupplierVerifications'
  );
const PageSupplierCustomers = () =>
  import(
    /* webpackChunkName: 'Orders' */ '@/components/Supplier/Customers/PageSupplierCustomers'
  );

export default () => {
  return [
    {
      path: 'billing',
      redirect: {
        name: 'supplier.run-dates'
      },
      component: PageSupplierBilling,
      meta: {
        title: 'Billing'
      },
      name: 'supplier.billing',
      children: [
        {
          path: 'run-dates',
          name: 'supplier.run-dates',
          component: SupplierBillingRunDates
        },
        {
          path: 'invoices',
          name: 'supplier.invoices',
          component: SupplierBillingInvoices
        }
      ]
    },
    {
      path: 'billing/invoice/:id',
      name: 'supplier.invoice',
      component: PageInvoiceDocument
    },
    {
      path: 'verifications',
      component: PageVerifications,
      name: 'supplier.verifications'
    },
    {
      path: 'customers',
      component: PageSupplierCustomers,
      name: 'supplier.customers'
    }
  ];
};
