
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CampaignAttributes"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Campaign"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ref"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"start_date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"end_date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"analytics"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CampaignWithContacts"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Campaign"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CampaignAttributes"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"primaryContact"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContactAttributes"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"billingContact"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ContactAttributes"},"directives":[]}]}}]}}],"loc":{"start":0,"end":387}};
    doc.loc.source = {"body":"#import \"./ContactInfoFragment.graphql\"\n#import \"@/graphql/fragments/FileFragments.graphql\"\n\nfragment CampaignAttributes on Campaign {\n  id\n  ref\n  name\n  start_date\n  end_date\n  status\n  created_at\n  analytics\n  type\n}\n\nfragment CampaignWithContacts on Campaign {\n  ...CampaignAttributes\n  primaryContact {\n    ...ContactAttributes\n  }\n  billingContact {\n    ...ContactAttributes\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./ContactInfoFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/FileFragments.graphql").definitions));


      module.exports = doc;
    
