<script>
import {
  addAdDetails,
  buyerAcceptCreative,
  buyerRejectCreative,
  buyerSetCreative,
  exportSupplierAds,
  supplierAcceptCreative,
  supplierAcceptCreativeForBuyer,
  supplierCancelAd,
  supplierDeleteAd,
  supplierRejectCreative,
  supplierRescheduleAd,
  supplierResumeCanceledAd,
  supplierSaveVerification,
  supplierSetAdNote,
  supplierSetCreative,
  supplierUpdateAd,
  updateSupplierCreativeLastDownloadDate
} from '@/graphql/ads/adsMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';
import { AccountType, CampaignType, CreativeStatus } from '@/constants';
import { mapGetters } from 'vuex';
import { formatFormResponseInput } from '@/utils/helpers';

export default {
  mixins: [MutationHelper],
  props: {
    buyer: { type: Object, default: null },
    supplier: { type: Object, default: null }
  },

  data() {
    return {
      accountTypeOverride: null
    };
  },

  mutation() {
    return {
      mutations: {
        supplierUpdateAd(ad, input) {
          return this.post(supplierUpdateAd, { id: ad.id, input });
        },
        addDetails(ad, details) {
          let input = {
            id: ad.id,
            details
          };

          // Set supplier ID if it is set on the Ad
          // This fixes an issue on the CT where we are logged in as the wrong supplier
          if (ad.supplier) {
            input.supplier_id = ad.supplier.id;
          }

          return this.post(addAdDetails, input);
        },

        async setAdNote(id, note) {
          return this.post(supplierSetAdNote, { ad_id: id, note });
        },
        async updateDownloadDate(ad_id, creative_id, accountType) {
          return this.postAs(accountType, this.getUpdateDownloadDate(), {
            ad_id,
            creative_id
          });
        },
        async resumeCanceledAd(ad, status) {
          return this.post(supplierResumeCanceledAd, { ad_id: ad.id, status });
        },
        async cancelAd(ad, cancelLineItem) {
          return this.post(supplierCancelAd, { ad_id: ad.id, cancelLineItem });
        },
        async deleteAd(id) {
          this.refetchCache('getSupplierAds');
          return this.post(supplierDeleteAd, { id });
        },
        async rescheduleAd(ad, input) {
          return this.post(supplierRescheduleAd, {
            ad_id: ad.id,
            skuFilter: { sku: ad.sku },
            input
          });
        },
        async setCreative(ad, input, accountType) {
          if (this.$can('manage_non_account_creative')) {
            if (ad.campaign.type === CampaignType.NATIONAL) {
              accountType = AccountType.BUYER;
            }
          }

          input = {
            ...input,
            formResponse: formatFormResponseInput(input.formResponse)
          };

          return this.postAs(
            accountType,
            this.getSetCreativeMutation,
            {
              ad_id: ad.id,
              input
            },
            () => {
              this.getCreativeOptimisticResponse(ad, input);
            }
          );
        },
        async acceptCreative(ad, accountType) {
          if (this.isSupplier && accountType === AccountType.BUYER) {
            return this.post(supplierAcceptCreativeForBuyer, {
              id: ad.id
            });
          }

          return this.postAs(accountType, this.getAcceptCreativeMutation, {
            id: ad.id
          });
        },
        async rejectCreative(ad, noteText, accountType) {
          return this.postAs(accountType, this.getRejectCreativeMutation, {
            id: ad.id,
            noteText
          });
        },
        async supplierSaveVerification(ad, input) {
          input = {
            ...input,
            formResponse: formatFormResponseInput(input.formResponse)
          };

          return this.post(supplierSaveVerification, {
            ad_id: ad.id,
            input
          });
        },
        exportSupplierAds(filter) {
          return this.post(exportSupplierAds, { filter });
        }
      },
      variables: {
        supplier_id: this.isSupplier
          ? (this.supplier && this.supplier.id) || this.supplierId
          : undefined,
        buyer_id: !this.isSupplier
          ? (this.buyer && this.buyer.id) || this.buyerId
          : undefined
      }
    };
  },

  computed: {
    ...mapGetters({
      supplierId: 'auth/supplierId',
      buyerId: 'auth/buyerId',
      accountType: 'auth/accountType'
    }),

    isSupplier() {
      return (
        (this.accountTypeOverride || this.accountType) === AccountType.SUPPLIER
      );
    }
  },

  methods: {
    postAs(accountType, mutation, input, optimistic) {
      // Suppliers and Super Admins are allowed to manage and approve creatives
      if (
        accountType &&
        (this.$can('manage_non_account_creative') ||
          this.accountType === AccountType.SUPPLIER)
      ) {
        this.accountTypeOverride = accountType;
      }
      if (optimistic) {
        this.optimisticResponse =
          typeof optimistic === 'function' ? optimistic() : optimistic;
      }

      let result = this.post(mutation(), input);

      // Always reset this if we've temporarily overridden the account type
      this.accountTypeOverride = null;

      return result;
    },
    getAcceptCreativeMutation() {
      return this.isSupplier ? supplierAcceptCreative : buyerAcceptCreative;
    },
    getSetCreativeMutation() {
      return this.isSupplier ? supplierSetCreative : buyerSetCreative;
    },
    getRejectCreativeMutation() {
      return this.isSupplier ? supplierRejectCreative : buyerRejectCreative;
    },
    getUpdateDownloadDate() {
      return this.isSupplier ? updateSupplierCreativeLastDownloadDate : null;
    },
    getCreativeOptimisticResponse(ad, input) {
      const creative = {
        __typename: 'Creative',
        id: 'new-creative',
        display_name: '',
        updated_at: '',
        created_at: '',
        status: CreativeStatus.PENDING,
        last_downloaded_at: '',
        date_supplier_approved: '',
        date_buyer_approved: '',
        date_supplier_rejected: '',
        date_buyer_rejected: '',
        formResponse: null,
        ...(ad.creative || {}),
        ...input
      };

      if (this.isSupplier) {
        return {
          mutateSupplier: {
            __typename: 'SupplierMutation',
            mutateAd: {
              __typename: 'AdsMutation',
              mutateCreative: {
                __typename: 'CreativeMutation',
                supplierSetCreative: true,
                ad: {
                  __typename: 'Ad',
                  ...ad,
                  creative
                }
              }
            }
          }
        };
      } else {
        return {
          mutateBuyer: {
            __typename: 'BuyerMutation',
            mutateAd: {
              __typename: 'AdsMutation',
              mutateCreative: {
                __typename: 'CreativeMutation',
                buyerSetCreative: true,
                ad: {
                  __typename: 'Ad',
                  ...ad,
                  creative
                }
              }
            }
          }
        };
      }
    }
  }
};
</script>
