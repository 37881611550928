<template>
  <el-tooltip :content="title" effect="light" class="notifications-icon">
    <div class="relative cursor-pointer mr-4" @click="$emit('click')">
      <icon :icon="bellAltIcon" class="text-2xl" :class="iconClass" />
      <div
        v-if="hasUnreadNotifications"
        class="absolute -m-1 px-1 right-0 top-0 h-4 w-4 rounded-xxl bg-red border border-white"
      />
    </div>
  </el-tooltip>
</template>

<script>
import Vue from 'vue';
import { bellAlt as bellAltIcon } from '@/vendor/icons';

export default Vue.component('NotificationsIcon', {
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    iconClass: {
      type: String,
      default: () => 'text-white'
    }
  },
  data() {
    return {
      bellAltIcon
    };
  },
  computed: {
    title() {
      if (this.unreadCount > 1) {
        return `You have ${this.unreadCount} new notifications`;
      } else if (this.unreadCount === 1) {
        return `You have ${this.unreadCount} new notification`;
      } else {
        return `No new notifications`;
      }
    },
    unreadCount() {
      if (this.notifications && this.notifications.length) {
        return this.notifications.reduce((acc, notification) => {
          return acc + (notification && notification.is_read ? 0 : 1);
        }, 0);
      }

      return 0;
    },
    hasUnreadNotifications() {
      return this.unreadCount > 0;
    }
  }
});
</script>
