<template>
  <page-overlay
    ref="pageOverlay"
    class="z-2000"
    location="right"
    sidebar
    @close="$emit('close')"
  >
    <notification-mutation class="flex flex-col w-full h-full px-12 pt-12">
      <template v-slot="{ archive, markAllRead, toggleRead, isSaving }">
        <div class="flex w-full justify-between mb-10 items-center">
          <h1 class="text-dark-gray">Notifications</h1>
          <loader-box v-if="isSaving" class="flex" :width="10" :height="1.5" />
          <div v-else class="flex items-center">
            <a @click="markAllRead()">Mark all as read</a>
            <icon
              class="ml-11 text-dark-silver text-md cursor-pointer hover:text-gunmetal"
              :icon="cancelIcon"
              @click="close"
            />
          </div>
        </div>
        <transition-group
          tag="div"
          name="fadeRight"
          class="relative flex h-full w-full flex-col overflow-y-auto animate-position"
        >
          <template v-if="!isLoading">
            <notification-item
              v-for="(notification, index) in sortedNotifications"
              :key="`notification-${notification.id}`"
              :notification="notification"
              :has-bottom-border="index + 1 !== notifications.length"
              @archive="archive(notification)"
              @toggle-read="toggleRead(notification, $event)"
              @close="close"
            />
          </template>
          <template v-if="isLoading">
            <loader-box
              v-for="i in 8"
              :key="`notification-loader-${i}`"
              height="100%"
              class="h-20 my-6"
            />
          </template>
        </transition-group>
      </template>
    </notification-mutation>
  </page-overlay>
</template>

<script>
import Vue from 'vue';
import { cancel as cancelIcon } from '@/vendor/icons';
import NotificationItem from '@/components/Core/NotificationItem';
import { NotificationMutation } from '@/components/Mutations';
import PageOverlay from '@/components/Core/PageOverlay';

export default Vue.component('NotificationsPanel', {
  components: { NotificationItem, NotificationMutation, PageOverlay },
  props: {
    isLoading: Boolean,
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cancelIcon
    };
  },
  computed: {
    sortedNotifications() {
      return [...this.notifications].sort((a, b) => {
        return a.created_at > b.created_at ? -1 : 1;
      });
    }
  },
  methods: {
    close() {
      this.$refs.pageOverlay.close();
    }
  }
});
</script>

<style lang="scss" scoped>
.page-overlay {
  margin-top: 0 !important;
}
</style>
