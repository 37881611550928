import moment from 'moment';
import { clone, cloneDeep, uniqueId } from 'lodash';
import { datetimeDb, float } from '@/utils/filters';

/**
 * Fetch the query result from the apollo store
 *
 * @param query
 * @param optimistic - by default data will be read w/ optimistic data included
 * @returns {*}
 */
export function readQuery(query, optimistic) {
  let data = this.$apollo.provider.defaultClient.readQuery(
    query,
    optimistic !== false
  );

  if (query.update) {
    return query.update(data);
  } else {
    return data;
  }
}

/**
 * Update a GraphQL query helper function to simplify the code to read => modify => write cached queries
 *
 * @param store
 * @param query
 * @param callback
 */
export function updateQuery(store, query, callback) {
  let data = store.readQuery(query);

  if (data) {
    // Callback should modify data directly which will be written as the new source of truth
    callback(data);

    store.writeQuery({ ...query, data });
  }
}

// The Field Types the input fields can be cast to (or use a self-defined function)
let fieldCastTypes = [String, Number, Date, Boolean, Array, JSON, File, Object];

/**
 * Filters & formats all the input fields for GraphQL
 *
 * @param input
 * @param fields
 * @returns {string[]|*}
 */
export function getInputFields(input, fields) {
  // If the input value is empty, then we cannot compare it against its fields
  // So it is a null object value, however if the fields are telling us to skip this object
  // we want to return undefined so the field is not set at all
  if (!input) return fields._skip ? undefined : null;

  // The filtered and formatted input fields
  let inputFields = {};

  for (let fieldKey of Object.keys(fields)) {
    // The __typename declarations are not used for input fields
    if (fieldKey === '__typename') continue;

    // In case we want to ignore this field
    if (fieldKey === '_skip') return undefined;

    let fieldType = fields[fieldKey];

    // If a custom format function is given, call that and ignore any other fields
    if (fieldKey === '_map') {
      return fieldType(input);
    }

    // If value is not set, skip it
    if (input[fieldKey] === undefined) continue;

    // We don't want to overwrite the original input fields
    //  We cannot clone a File, so just use the original
    let value = fieldType === File ? input[fieldKey] : clone(input[fieldKey]);

    // Handle Special Cases of fieldTypes
    switch (fieldType) {
      // JSON Objects are treated as Scalars, so we do not want to traverse into the objects keys
      // just return the object as is
      case JSON:
        break;

      case File:
        if (!(value instanceof File)) {
          if (value && value.raw instanceof File) {
            value = value.raw;
          } else if (value !== null) {
            value = undefined;
          }
        }
        break;

      default:
        if (typeof fieldType === 'object' && !Array.isArray(fieldType)) {
          if (Array.isArray(value)) {
            for (let v in value) {
              value[v] = getInputFields(value[v], fieldType);
            }
          } else {
            value = getInputFields(value, fieldType);
          }
        } else {
          value = castType(value, fieldType);

          // If there was no cast type found, check if it is a function to callback
          if (value === null && fieldCastTypes.indexOf(fieldType) === -1) {
            // If this fieldType is a function, call directly with input to format
            if (typeof fieldType === 'function') {
              fieldType(input, inputFields);

              // The callback function should already set the inputField value, so continue to next iteration
              continue;
            } else {
              throw new Error(`Unknown type ${fieldType}`);
            }
          }
        }
        break;
    }

    // Assign formatted value
    if (value !== undefined) {
      inputFields[fieldKey] = value;
    }
  }

  return inputFields;
}

/**
 * Cast a value to the given type and return the casted value
 *
 * @param value
 * @param type
 * @returns {*}
 */
function castType(value, type) {
  if (value === null) {
    return null;
  }

  switch (type) {
    case String:
      return '' + value;

    case Number:
      return float(value);

    case Date:
      if (
        value instanceof moment ||
        value instanceof Date ||
        typeof value === 'string'
      ) {
        return datetimeDb(value);
      } else {
        return null;
      }

    case Boolean:
      return !!value;

    case Array:
      return Array.isArray(value) ? value : [value];

    case JSON:
      return value;

    case Object:
      return value;

    default:
      // If the type is an array of a type, loop through all the values in the array and cast them
      // to the correct type
      if (Array.isArray(type)) {
        let arrayType = type[0];

        if (!Array.isArray(value)) {
          throw new Error(`The value ${value} was not a of type ${arrayType}`);
        }

        let castedArray = [];

        for (let v in value) {
          castedArray.push(castType(value[v], arrayType));
        }

        return castedArray;
      }

      // Unknown cast
      return undefined;
  }
}

/**
 * Maps all the __typename fields for the optimistic UI updates
 *
 * @param input
 * @param fields
 * @returns {*}
 */
export function mapTypenames(input, fields) {
  input = cloneDeep(input);

  // Map the base case
  if (fields.__typename) {
    input.__typename = fields.__typename;

    // All Objects require an ID
    if (!input.id) {
      input.id = uniqueId(input.__typename);
    }
  }

  // Loop through all the field keys to find any nested objects that have a __typename
  for (let fieldKey of Object.keys(fields)) {
    let fieldValue = fields[fieldKey];

    if (typeof fieldValue === 'object') {
      let inputValue = input[fieldKey];

      if (inputValue) {
        let mappedInput;

        if (Array.isArray(inputValue)) {
          mappedInput = [];

          for (let inputItem of inputValue) {
            mappedInput.push(mapTypenames(inputItem, fieldValue));
          }
        } else {
          mappedInput = mapTypenames(inputValue, fieldValue);
        }

        input[fieldKey] = mappedInput;
      }
    }
  }

  return input;
}
