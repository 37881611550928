<script>
import {
  archiveNotification,
  markAllNotificationsRead,
  toggleNotificationRead
} from '@/graphql/common/notificationMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        async toggleRead(notification, input) {
          this.optimisticResponse = {
            mutateNotification: {
              __typename: 'NotificationMutation',
              toggleRead: input,
              notification: {
                ...notification,
                is_read: input
              }
            }
          };

          return this.post(toggleNotificationRead, {
            id: notification.id,
            input
          });
        },
        markAllRead() {
          const pendingNotifications = this.getNotifications().map(
            notification => ({
              ...notification,
              is_read: true,
              __typename: 'Notification'
            })
          );

          this.optimisticResponse = {
            mutateUser: {
              __typename: 'UserMutation',
              markAllNotificationsRead: true,
              user: {
                id: this.user.id,
                pendingNotifications,
                __typename: 'User'
              }
            }
          };
          this.post(markAllNotificationsRead, { user_id: this.user.id });
        },
        archive(notification) {
          const pendingNotifications = this.getNotifications().filter(
            pn => pn.id !== notification.id
          );

          this.optimisticResponse = {
            mutateNotification: {
              __typename: 'NotificationMutation',
              archive: true,
              user: {
                id: this.user.id,
                pendingNotifications,
                __typename: 'User'
              }
            }
          };

          this.post(archiveNotification, {
            id: notification.id
          });
        }
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },

  methods: {
    getNotifications() {
      return this.cached('getUserNotifications', 'me.pendingNotifications');
    }
  }
};
</script>
