<template>
  <div
    class="loading-image"
    :style="{ height: height + 'em' }"
    @click="$emit('click')"
  >
    <img v-if="showImage" ref="image" :src="src" />

    <div v-if="!isLoaded" class="is-loading is-overlay has-content-centered">
      <slot>
        <loading-icon class="icon-lg"></loading-icon>
      </slot>
    </div>

    <div v-if="isEmpty" class="is-empty no-image has-content-centered">
      <slot name="empty">
        <div>No Image</div>
        <div class="pad-top-sm pad-horiz">
          <icon :icon="fileImage" class="icon-xl" />
        </div>
      </slot>
    </div>

    <div v-if="isError" class="has-error no-image has-content-centered">
      <slot name="error"> Image Not<br />Found</slot>
    </div>
  </div>
</template>

<script>
import LoadingIcon from './LoadingIcon';

import { fileImage } from '@/vendor/icons';

export default {
  name: 'LoadingImage',

  components: {
    LoadingIcon
  },
  props: {
    src: {
      type: String,
      default: null
    },
    height: { type: Number, default: null }
  },

  data() {
    return {
      isLoaded: false,
      isError: false,
      isEmpty: false,
      fileImage
    };
  },

  computed: {
    showImage() {
      return !this.isEmpty && !this.isError;
    }
  },

  mounted() {
    this.$refs.image.addEventListener('load', () => {
      this.isLoaded = true;
      this.isError = false;
      this.isEmpty = false;
    });

    this.$refs.image.addEventListener('error', () => {
      this.isLoaded = true;

      if (this.src) this.isError = true;
    });

    if (!this.src) {
      this.isLoaded = true;
      this.isEmpty = true;
      this.isError = false;
    }
  }
};
</script>

<style lang="scss" type="text/scss" scoped>
@import '~@/scss/_variables';

.loading-image {
  position: relative;
  min-width: 4em;
  min-height: 4em;
}

.is-loading {
  padding: 1em;
}

.no-image {
  text-align: center;
  min-width: 5em;
  min-height: 5em;
  width: 100%;
  height: 100%;

  &.is-empty {
    background: $color-gray;
    color: $color-dark-silver;
  }

  &.has-error {
    background: $color-red;
    color: white;
  }
}
</style>
