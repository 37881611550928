<script>
import { getAudience } from '@/graphql/audience/audienceQueries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    id: {
      type: String,
      required: true
    },
    preference: {
      type: Object,
      default: null
    }
  },
  query(props) {
    return {
      query: getAudience,
      variables: {
        buyer_id: this.$store.getters['auth/buyerId'],
        id: props.id,
        ...this.$store.getters['audience/pager']
      }
    };
  }
};
</script>
