<template>
  <div class="loader-table-rows px-8 mb-3">
    <loader-text
      :lines="rows"
      line-width="100%"
      :thickness="4"
      line-class="my-2"
    />
  </div>
</template>

<script>
import LoaderText from '@/components/Core/Loaders/LoaderText';

export default {
  components: { LoaderText },
  props: {
    rows: {
      type: Number,
      default: 5
    }
  }
};
</script>
