<template>
  <div class="loader-heading" :class="classObject">
    <div class="loader-heading__content">
      <div class="loader-heading__title" :style="titleStyle"></div>
      <div class="loader-heading__subtitle" :style="subtitleStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    animated: {
      type: Boolean,
      default: true
    },
    thickness: {
      type: Number,
      default: 1.5
    }
  },

  computed: {
    classObject() {
      return {
        'loader-is-animated': this.animated
      };
    },

    titleStyle() {
      return {
        height: this.thickness * 2 + 'em'
      };
    },

    subtitleStyle() {
      return {
        height: this.thickness * 1.5 + 'em'
      };
    }
  }
};
</script>
