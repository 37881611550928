<script>
import { getAudienceSearchTools } from '@/graphql/audience/audienceQueries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  query(props) {
    return {
      query: getAudienceSearchTools,
      variables: {
        id: props.id
      }
    };
  }
};
</script>
