<script>
import {
  addDiscountToCart,
  removeDiscountFromCart
} from '@/graphql/cart/mutations.graphql';
import { MutationHelper } from '@/components/Core/Helpers';

export default {
  name: 'CartDiscountMutation',
  mixins: [MutationHelper],
  mutation() {
    return {
      name: 'cart',
      mutations: {
        addDiscountToCart(cartId, discountCode) {
          return this.post(addDiscountToCart, { cartId, discountCode });
        },
        removeDiscountFromCart(cartId) {
          return this.post(removeDiscountFromCart, { cartId });
        }
      },
      variables: {
        cartId: String,
        discountCode: String
      }
    };
  }
};
</script>
