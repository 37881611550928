
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OrderCheckoutRelations"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Order"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrderInfo"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ads"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AdAttributes"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"orderProduct"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrderProductAttributes"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mediumType"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediumTypeAttributes"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"orderLineItem"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OrderLineItemAttributes"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"campaign"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":466}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/AdFragments.graphql\"\n#import \"@/graphql/fragments/OrderProductInfoFragment.graphql\"\n#import \"@/graphql/fragments/OrderLineItemFragments.graphql\"\n\nfragment OrderCheckoutRelations on Order {\n  ...OrderInfo\n  ads {\n    ...AdAttributes\n    orderProduct {\n      ...OrderProductAttributes\n      mediumType {\n        ...MediumTypeAttributes\n      }\n    }\n    orderLineItem {\n      ...OrderLineItemAttributes\n    }\n  }\n  campaign {\n    id\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/AdFragments.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/OrderProductInfoFragment.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/OrderLineItemFragments.graphql").definitions));


      module.exports = doc;
    
