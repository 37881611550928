const PagePrintInvoiceDocument = () =>
  import(
    /* webpackChunkName: 'Accounting' */
    '@/components/Accounting/PagePrintInvoiceDocument'
  );

const PagePayableInvoiceDocument = () =>
  import(
    /* webpackChunkName: 'Accounting' */
    '@/components/Accounting/PagePayableInvoiceDocument'
  );

const PagePrintInsertionOrderDocument = () =>
  import(
    /* webpackChunkName: 'Accounting' */
    '@/components/Accounting/PagePrintInsertionOrderDocument'
  );

export default () => {
  return [
    {
      path: '/invoice/:id/print',
      component: PagePrintInvoiceDocument
    },
    {
      path: '/invoice/:id',
      component: PagePayableInvoiceDocument
    },
    {
      path: '/insertion-order/:supplier_id/:id/print',
      name: 'accounting.io',
      component: PagePrintInsertionOrderDocument
    }
  ];
};
