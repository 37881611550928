import { colors } from '@/tailwind';
import { adjustColor } from '@/vendor/adjust-color';

export const defaultLineChartData = {
  labels: [],
  datasets: [
    {
      spanGaps: false,
      label: 'Loading...',
      data: []
    }
  ]
};

export const DefaultLineChartOptions = {
  animation: {
    tension: {
      duration: 1,
      easing: 'linear',
      to: 0.3,
      loop: false
    }
  },
  interaction: {
    mode: 'index',
    intersect: false
  },
  scales: {
    y: {
      min: 0,
      max: 50
    }
  }
};

export const ChartColors = [
  colors.blue,
  colors.green,
  colors.red,
  colors.purple,
  colors.yellow,
  colors['dark-blue'],
  colors['dark-green'],
  colors['dark-red'],
  colors['dark-yellow'],
  '#008296',
  '#9575CD',
  '#F06292',
  '#FF8346',
  '#4DD0E1',
  '#609462',
  '#4DB68C',
  '#475466',
  '#005b69',
  '#685290',
  '#A84566',
  '#C26B40',
  '#36929E',
  '#4A6F4C',
  '#367f78',
  '#323b47'
];

export const ChartLineColors = ChartColors.map(c => adjustColor(0.6, c));
