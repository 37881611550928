<script>
import { getSupplierFilterData } from '@/graphql/billing/supplierBillingQueries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  query() {
    return {
      query: getSupplierFilterData,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        buyerSort: [{ column: 'buyer_company', order: 'asc' }]
      }
    };
  }
};
</script>
