// https://github.com/FortAwesome/vue-fontawesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome';

export const IconPlugin = {
  install(Vue) {
    Vue.component('icon', FontAwesomeIcon);
    Vue.component('icon-layers', FontAwesomeLayers);
  }
};
