<template>
  <transition appear name="fade">
    <div id="app">
      <transition name="fadeLeft" :duration="500" mode="out-in">
        <router-view />
      </transition>

      <app-refresh-notification />

      <intercom />
    </div>
  </transition>
</template>

<script>
import AppRefreshNotification from '@/components/Core/AppRefreshNotification';
import Intercom from '@/components/Core/Intercom';

export default {
  name: 'App',
  components: {
    Intercom,
    AppRefreshNotification
  }
};
</script>

<style lang="scss">
@import '~@/scss/app';
@import '~@/scss/tailwind';
</style>

<style lang="scss" scoped>
#app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: white;
  overflow: auto;
}

@media print {
  #app {
    position: absolute !important;
  }
}
</style>
