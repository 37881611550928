<script>
import { getScheduleTypes } from '@/graphql/mediakit/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  name: 'ScheduleTypesQuery',

  mixins: [QueryHelper],

  query() {
    return {
      query: getScheduleTypes
    };
  }
};
</script>
