import {
  alphanumeric,
  currency,
  integer,
  number,
  phone,
  round,
  uppercase
} from '@/utils/filters';

export default {
  phone: {
    apply(val) {
      return phone(val);
    }
  },

  alphanumeric: {
    apply(val) {
      return alphanumeric(val);
    }
  },

  uppercase: {
    apply(val) {
      return uppercase(val);
    }
  },

  hexColor: {
    mask: [
      '#',
      /[0-9A-F]/i,
      /[0-9A-F]/i,
      /[0-9A-F]/i,
      /[0-9A-F]/i,
      /[0-9A-F]/i,
      /[0-9A-F]/i
    ]
  },

  number: {
    apply(val, binding) {
      let options = {
        allowEmpty: true,
        minPrecision: 0,
        nullDecimal: true
      };

      if (typeof binding.value === 'object') {
        options = { ...options, ...binding.value };
      }

      return '' + number(val, options);
    }
  },

  integer: {
    apply(val, binding) {
      let options = {
        allowEmpty: true
      };

      if (typeof binding.value === 'object') {
        options = { ...options, ...binding.value };
      }

      return '' + integer(val, options);
    }
  },

  /**
   * Define an integer input range. delay the visual update so the user is not disrupted when trying to enter the
   * desired value
   */
  range: {
    delay: 1000,
    apply(val, binding) {
      // Allow an empty input
      if (val === 'undefined' || (!val && val !== '0')) {
        return val;
      }

      let params = binding.value || {};

      let options = {
        precision: false,
        nullDecimal: true,
        allowEmpty: true,
        min: +params.min || 0,
        max: +params.max || false,
        allowMaxOverride: true
      };

      return number(val, options) || 0;
    }
  },

  currency: {
    apply(val, binding) {
      let hasFocus = document.activeElement === this.inputElement;

      let options = {
        minPrecision: hasFocus ? 0 : 2,
        nullDecimal: true,
        allowEmpty: true
      };

      if (typeof binding.value === 'object') {
        options = { ...options, ...binding.value };
      }

      return currency(val, options);
    }
  },

  decimal: {
    apply(val, binding) {
      if (val === '') return '';

      let newVal = round(val, binding.value || 2);

      if (val.match(/\.$/)) {
        newVal += '.';
      }

      return newVal;
    }
  },

  zipcode: {
    apply(val) {
      // only allow digits
      let newVal = val.replace(/[^\d]/g, '').split('');

      // max length of 9
      newVal = newVal.slice(0, 9);

      // insert a - between the 5th and 6th digit
      if (newVal.length > 5) {
        newVal.splice(5, 0, '-');
      }

      return newVal.join('');
    }
  }
};
