<script>
import { getSupplierTeam } from '@/graphql/supplier/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    invitesFilter: {
      type: Object,
      default: null
    }
  },

  query(props) {
    return {
      query: getSupplierTeam,
      variables: {
        invitesFilter: props.invitesFilter,
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
