import moment from 'moment';
import { map, pull } from 'lodash';
import { iso8601 } from '@/utils/filters';

/**
 * Removes any dates that are between the start and end of the given date range. Returns a new array containing the
 * list of dates that qualify
 *
 * @param dates
 * @param start
 * @param end
 * @returns {Array}
 */
export function removeDatesBetween(dates, start, end) {
  let newDates = [];

  for (let date of dates) {
    let mDate = moment(date);

    if (mDate.isBefore(start) || mDate.isAfter(end)) {
      newDates.push(date);
    }
  }

  return newDates;
}

/**
 * Construct an array of selectedDates based on the frequency, months and days of the week.
 * The minDate / maxDate of the picker config is used as the start and end date for the schedule
 *
 * @param frequency
 * @param months
 * @param daysOfWeek
 * @param minDate
 * @param maxDate
 * @returns {Array}
 */
export function buildSchedule({
  frequency,
  months,
  daysOfWeek,
  minDate,
  maxDate
}) {
  // format the months / daysOfWeek array lists
  months = map(months, a => +a);
  daysOfWeek = map(daysOfWeek, a => +a);

  pull(months, 0);
  pull(daysOfWeek, 0);

  let currDate = moment(minDate);
  let lastDate = moment(maxDate);
  let nthDayOfWeek = [];
  let currMonth = 0;
  let selectedDates = [];

  while (currDate.isSameOrBefore(lastDate)) {
    let dayOfWeek = currDate.day() + 1;
    let month = currDate.month() + 1;
    let week = currDate.isoWeek();

    // If new month, reset the nth days of week to 0
    if (currMonth !== month) {
      currMonth = month;
      nthDayOfWeek = [0, 0, 0, 0, 0, 0, 0];
    }

    nthDayOfWeek[dayOfWeek - 1]++;

    if (
      (daysOfWeek.length === 0 || daysOfWeek.indexOf(dayOfWeek) >= 0) &&
      (months.length === 0 || months.indexOf(month) >= 0)
    ) {
      let isValid = true;

      switch (frequency) {
        case 1:
        case '1':
        case 'weekly':
          break;

        case 2:
        case '2':
        case 'bi-weekly':
          // Every other week, but sunday is technically the last day of the week, so we want the sunday of the
          // previous week
          if (dayOfWeek === 1 ? week % 2 === 0 : week % 2 === 1) {
            isValid = false;
          }
          break;

        case 3:
        case '3':
        case 'monthly':
          // Only on the first Sunday, Monday, etc. of the month
          if (nthDayOfWeek[dayOfWeek - 1] !== 1) {
            isValid = false;
          }
          break;
      }

      if (isValid) {
        selectedDates.push(iso8601(currDate));
      }
    }

    currDate = currDate.add(1, 'day');
  }

  return selectedDates;
}
