export const AccountType = {
  SUPPLIER: 'SUPPLIER',
  BUYER: 'BUYER',
  ADMIN: 'ADMIN'
};

export const OrganizationType = {
  CAMPUS_TRANSIT_OFFICE: 'Campus Transit Office',
  CONTRACTOR: 'Contractor',
  GOTCHA: 'Gotcha',
  INFLUENCER: 'Influencer',
  STUDENT_MEDIA: 'Student Media Organization',
  STUDENT_RADIO_STATION: 'Student Radio Station',
  VOTER_GUIDE: 'Voter Guide'
};

export const SupplierTeamMemberType = {
  STUDENT: 'Student',
  STAFF: 'Professional Staff'
};
