<template>
  <div class="loader-text" :class="{ 'loader-is-animated': animated }">
    <div v-for="(line, index) in lines" :key="`line-${line}`">
      <div
        :style="style(line)"
        class="loader-text__line"
        :class="lineClasses"
      ></div>
      <div
        v-if="index < lines - 1"
        class="loader-line-spacer"
        :style="{ width: 1, height: lineSpace }"
      ></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('loaderText', {
  props: {
    lineClass: {
      type: String,
      default: ''
    },
    lineSpacing: {
      type: Number,
      default: 0.4
    },
    lineWidth: { type: [Number, String], default: null },
    lines: {
      type: Number,
      default: 4
    },
    thickness: {
      type: Number,
      default: 1.5
    },
    dark: Boolean,
    animated: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    lineClasses() {
      return {
        'is-dark': this.dark,
        [this.lineClass]: true
      };
    },
    lineSpace() {
      return typeof this.lineSpacing === 'string'
        ? this.lineSpacing
        : this.lineSpacing + 'em';
    }
  },

  methods: {
    width() {
      if (this.lineWidth) {
        return typeof this.lineWidth === 'string'
          ? this.lineWidth
          : this.lineWidth + '%';
      } else {
        return 30 * Math.random() + 70 + '%';
      }
    },

    style(n) {
      return {
        height: this.thickness + 'em',
        width: this.width(n)
      };
    }
  }
});
</script>
