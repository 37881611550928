<template functional>
  <div class="organization-list-item" :class="{ 'is-active': props.active }">
    <el-button @click="listeners.click">
      <div class="content">
        <span class="name">{{ props.name }}</span>
      </div>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'OrganizationListItem',
  props: {
    name: {
      type: String,
      required: true
    },
    active: Boolean
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.organization-list-item {
  .el-button + .el-button {
    margin-left: 0;
  }

  .el-button {
    display: block;
    width: 100%;
    padding: 0.5em 1.5em;
    border: none;
    border-radius: 0;

    .content {
      height: 100%;
      display: flex;
      color: $color-blue;

      .name {
        height: 2em;
        display: flex;
        align-items: center;
      }

      .profile-picture {
        height: 2em;
        border-radius: 2em;
        margin-left: auto;
      }
    }
  }

  &:hover,
  &.is-active {
    .el-button {
      background: lighten($color-baby-blue, 5%);
      opacity: 0.75;

      .content {
        color: $color-dark-gray;
      }
    }
  }
}
</style>
