<template>
  <the-default-layout>
    <the-account-bar
      slot="header"
      class="bg-blue"
      :link-to="{
        name: 'home'
      }"
    />

    <div class="h-full w-full flex items-center flex-col px-32 pt-20">
      <h1 class="text-blue text-4xl">404: Page Not Found</h1>
      <p class="border-top pt-4 mt-4 text-center px-4">
        Oops. Looks like this page doesn't exist or has moved. Please double
        check the spelling of your URL. If you're still having problems, please
        <a href="https://www.flytedesk.com/contact" target="_blank">
          contact us.
        </a>
      </p>
    </div>
  </the-default-layout>
</template>

<script>
import NavToggle from '@/components/Core/NavToggle';
import TheAccountBar from '@/components/Core/Layout/TheAccountBar';
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';

export default {
  components: {
    TheAccountBar,
    TheDefaultLayout
  },
  mixins: [NavToggle]
};
</script>
