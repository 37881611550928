<template>
  <!-- Super Admin Button -->
  <organization-picker
    v-if="$can('do_anything')"
    class="account-info-button"
    :user="user"
    :current="current"
    :buyers="buyers"
    :suppliers="suppliers"
    :button-class="buttonClass"
    @view-account="viewAccount"
    @select="switchAccount"
  />
  <!-- User Button -->
  <el-popover
    v-else
    v-model="showPopover"
    placement="bottom"
    trigger="click"
    popper-class="overflow-hidden"
  >
    <div class="min-w-15 flex flex-col">
      <div class="font-semibold">
        {{ accountName }}
      </div>
      {{ organizationName }}
      <el-button class="button-blue mt-5" @click="viewAccount">
        Settings
      </el-button>
      <div class="border-top border-silver -mx-5 my-5" />
      <loading-button v-if="isLoggingOut" class="w-full" />
      <el-button v-else class="button-white" @click="logout">
        Log Out
      </el-button>
    </div>
    <el-button slot="reference" class="button-white-blue hover:opacity-75 p-3">
      <div class="flex justify-between items-center">
        <span class="name">{{ accountName }}</span>
        <icon :icon="userCircleO" class="icon-xl ml-3" />
      </div>
    </el-button>
  </el-popover>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { LoadingButton } from '@/components/Core/Loading';
import OrganizationPicker from '@/components/Core/OrganizationPicker/OrganizationPicker';

import { userCircleO } from '@/vendor/icons';

export default {
  components: {
    LoadingButton,
    OrganizationPicker
  },
  props: {
    buttonClass: { type: String, default: null }
  },
  data() {
    return {
      isLoggingOut: false,
      showPopover: false,
      userCircleO
    };
  },
  computed: {
    accountName() {
      return (
        this.$store.getters['auth/name'] || this.$store.getters['auth/email']
      );
    },
    organizationName() {
      if (this.isSupplier) {
        return this.supplier.name;
      } else if (this.isBuyer) {
        return this.buyer.company;
      }

      return '';
    },
    ...mapGetters({
      isSupplier: 'auth/isSupplier',
      isBuyer: 'auth/isBuyer'
    }),
    ...mapState('auth', {
      current: 'currentAccount',
      user: 'user',
      buyer: 'buyer',
      supplier: 'supplier',
      buyers: 'buyers',
      suppliers: 'suppliers'
    })
  },
  methods: {
    ...mapActions('auth', {
      switchAccount: 'switchAccount'
    }),
    logout() {
      this.isLoggingOut = true;
      this.$store.dispatch('auth/logout').finally(() => {
        this.isLoggingOut = false;
        this.$router.replace('/auth/login');
      });
    },
    viewAccount() {
      this.showPopover = false;
      if (this.isBuyer) {
        this.$router.push({ name: 'buyer.settings.account' });
      } else if (this.isSupplier) {
        this.$router.push({ name: 'supplier.settings.account' });
      } else if (this.$can('do_anything')) {
        this.$router.push('/admin');
      } else {
        this.$router.push({ name: 'home' });
      }
    }
  }
};
</script>
