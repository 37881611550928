<script>
import {
  cancelCampaign,
  deleteCampaign,
  resumeCampaign,
  updateCampaign
} from '@/graphql/campaign/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        cancelCampaign({ id }) {
          this.post(cancelCampaign, { id });
        },
        resumeCampaign({ id }) {
          this.post(resumeCampaign, { id });
        },
        deleteCampaign({ id }) {
          this.refetchCache('getBuyerCampaigns');
          this.post(deleteCampaign, { id });
        },
        updateCampaign(originalData, newData) {
          this.post(updateCampaign, { id: originalData.id, input: newData });
        }
      },
      variables: {
        buyer_id: this.$store.getters['auth/buyerId']
      }
    };
  }
};
</script>
