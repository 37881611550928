<script>
import {
  addProductVariantGroup,
  checkoutCartWithCreditCard,
  checkoutCartWithInvoice,
  deleteProductVariantGroup,
  updateCartRateClass,
  updateProductVariantGroup
} from '@/graphql/cart/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';
import { PaymentMethodName } from '@/constants';

export default {
  mixins: [MutationHelper],

  props: {
    cartId: {
      type: String,
      required: true
    }
  },

  mutation() {
    return {
      variables: {
        cartId: this.cartId
      },
      mutations: {
        updateCartRateClass(rateClass) {
          return this.post(updateCartRateClass, { rateClass });
        },
        addProductVariantGroupToCart(input) {
          // Commit the product variant group to the cart
          return this.post(addProductVariantGroup, { input });
        },
        deleteProductVariantGroup(id) {
          return this.post(deleteProductVariantGroup, { id });
        },
        updateProductVariantGroup(id, input) {
          return this.post(updateProductVariantGroup, { id, input });
        },
        submitPaymentRequest(paymentMethod, order) {
          const mutation =
            paymentMethod === PaymentMethodName.INVOICE
              ? checkoutCartWithInvoice
              : checkoutCartWithCreditCard;

          return this.post(mutation, order);
        }
      }
    };
  }
};
</script>
