import { arrowsV, bank, calendar, cog, filter, location } from '@/vendor/icons';

export const SearchGroupTypes = {
  MANAGED: 'MANAGED',
  UNMANAGED: 'UNMANAGED'
};

export const SearchGroupNames = {
  WORKFLOW: 'workflow',
  DETAILS: 'details',
  SUPER_INFO: 'Super Info',
  STATUS: 'status',
  SETTING: 'Setting',
  LOCATION: 'Location',
  FILTER: 'Filter',
  PREFERENCE: 'Preference',
  DATE: 'Date',
  CAMPUS: 'Campus',
  SORT: 'Sort',
  CLASSIFICATION: 'Classification',
  DATE_RANGE_GROUP: 'Date Range Group',
  CAMPUSES_GROUP: 'Campuses Group',
  SORT_GROUP: 'Sort Group'
};

export const SearchGroupDictionary = {
  WORKFLOW: {
    name: SearchGroupNames.WORKFLOW,
    label: 'Workflow',
    tagType: 'warning',
    icon: filter
  },
  DETAILS: {
    name: SearchGroupNames.DETAILS,
    label: 'Details',
    tagType: 'warning',
    icon: filter
  },
  SUPER_INFO: {
    name: SearchGroupNames.SUPER_INFO,
    tagType: 'warning',
    icon: filter
  },
  STATUS: {
    name: SearchGroupNames.STATUS,
    label: 'Status',
    tagType: 'warning',
    icon: filter
  },
  SETTING: {
    name: SearchGroupNames.SETTING,
    label: 'Settings',
    tagType: 'info',
    color: 'dark-gray',
    icon: cog
  },
  LOCATION: {
    name: SearchGroupNames.LOCATION,
    label: 'Location',
    tagType: 'default',
    color: 'blue',
    icon: location
  },
  FILTER: {
    name: SearchGroupNames.FILTER,
    label: 'Filters',
    tagType: 'warning',
    color: 'yellow',
    icon: filter,
    info:
      'Filters will limit your search results to only campuses that match your filter criteria.'
  },
  CLASSIFICATION: {
    name: SearchGroupNames.CLASSIFICATION,
    tagType: 'warning',
    icon: filter
  },
  PREFERENCE: {
    name: SearchGroupNames.PREFERENCE,
    label: 'Preferences',
    tagType: 'success',
    color: 'dark-green',
    icon: arrowsV,
    info:
      'Preferences will factor into a campus’s relevance score, but will not limit your search results. Campuses most relevant to your preference criteria will automatically be sorted to the top of your list.'
  },
  DATE: {
    name: SearchGroupNames.DATE,
    label: 'Date',
    tagType: 'default',
    color: 'blue',
    icon: calendar
  },
  DATE_RANGE_GROUP: {
    name: SearchGroupNames.DATE_RANGE_GROUP,
    label: 'Date',
    tagType: 'default',
    color: 'blue',
    icon: calendar
  },
  CAMPUS: {
    name: SearchGroupNames.CAMPUS,
    label: 'Campus',
    tagType: 'info',
    color: 'dark-gray',
    icon: bank
  },
  CAMPUSES_GROUP: {
    name: SearchGroupNames.CAMPUSES_GROUP,
    label: 'Campus',
    tagType: 'info',
    color: 'dark-gray',
    icon: bank
  },
  SORT: {
    name: SearchGroupNames.SORT,
    label: 'Sort',
    tagType: 'success',
    color: 'dark-green',
    icon: arrowsV
  },
  SORT_GROUP: {
    name: SearchGroupNames.SORT_GROUP,
    label: 'Sort',
    tagType: 'success',
    color: 'dark-green',
    icon: arrowsV
  }
};

export const SearchToolTypes = {
  SEARCH_GROUP: 'SearchGroup',
  SEARCH_TOOL: 'SearchTool'
};

export const SearchDataTypes = {
  BOOLEAN: 'BOOLEAN',
  CURRENCY: 'CURRENCY',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  FLOAT: 'FLOAT',
  INTEGER: 'INTEGER',
  MAP: 'MAP',
  PERCENT: 'PERCENT',
  PERCENT100: 'PERCENT100',
  RANK: 'RANK',
  STRING: 'STRING'
};

export const SearchToolDataTypes = {
  BOOLEAN_DATA: 'BooleanData',
  TEXT_DATA: 'TextData',
  SELECT_DATA: 'SelectData',
  RANGE_DATA: 'RangeData',
  DATE_RANGE_DATA: 'DateRangeData',
  DATE_CALENDAR_DATA: 'DateCalendarData',
  COUPLE_SELECT_DATA: 'CoupleSelectData',
  TREE_SELECT_DATA: 'TreeSelectData'
};

export const AudienceSearchGroups = [
  SearchGroupDictionary.LOCATION,
  SearchGroupDictionary.FILTER,
  SearchGroupDictionary.PREFERENCE
];

export const AdminAudienceSearchGroups = [
  SearchGroupDictionary.SETTING,
  SearchGroupDictionary.LOCATION,
  SearchGroupDictionary.FILTER,
  SearchGroupDictionary.PREFERENCE
];

export const MediumSearchGroups = [
  SearchGroupDictionary.CLASSIFICATION,
  SearchGroupDictionary.CAMPUS,
  SearchGroupDictionary.DATE,
  SearchGroupDictionary.SORT
];

export const CampaignTrackerSearchGroups = [
  SearchGroupDictionary.WORKFLOW,
  SearchGroupDictionary.DETAILS,
  SearchGroupDictionary.DATE,
  // SearchGroupDictionary.SUPER_INFO,
  SearchGroupDictionary.STATUS,
  SearchGroupDictionary.SORT_GROUP
];

export const FinanceTrackerSearchGroups = [
  { ...SearchGroupDictionary.STATUS, showLabel: false },
  SearchGroupDictionary.WORKFLOW,
  SearchGroupDictionary.DETAILS
];

export const InvoiceSearchGroups = [
  // SearchGroupDictionary.DETAILS,
  SearchGroupDictionary.DATE
  // SearchGroupDictionary.SUPER_INFO,
  // SearchGroupDictionary.STATUS,
  // SearchGroupDictionary.SORT_GROUP
];

export const LineItemsSearchGroups = [
  SearchGroupDictionary.FILTER,
  SearchGroupDictionary.DATE,
  SearchGroupDictionary.SORT_GROUP
];
