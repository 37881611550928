import { urlParams } from '@/utils/helpers';

let jwtBearerToken = urlParams('jwt_token');

// If this JWT Token was originally set via a URL parameter, we want to handle it slightly differently
let isUrlParam = !!jwtBearerToken;

const TOKEN_NAME = 'jwt-authorization-token';

export function isUrlParamAuthorized() {
  return isUrlParam;
}

export function getAuthorizationToken() {
  if (!jwtBearerToken) {
    jwtBearerToken = localStorage.getItem(TOKEN_NAME);
  }

  return jwtBearerToken;
}

export function clearAuthorizationToken() {
  jwtBearerToken = null;

  localStorage.removeItem(TOKEN_NAME);
}

export function isAuthorized() {
  return !!getAuthorizationToken();
}

export function setAuthorizationToken(token) {
  jwtBearerToken = token;

  if (!isUrlParam) {
    localStorage.setItem(TOKEN_NAME, token);
  }
}

export function getAuthorizationHeader() {
  const jwtBearerToken = getAuthorizationToken();

  if (jwtBearerToken) {
    return {
      Authorization: `Bearer ${jwtBearerToken}`
    };
  }

  return {};
}
