<script>
import { MutationHelper } from '@/components/Core/Helpers';
import {
  createBillForAds,
  createInvoiceForAds,
  deleteBill,
  deleteInvoice,
  syncBillToProvider,
  syncInvoiceToProvider
} from '@/graphql/admin/accounting/accountingMutations.graphql';

export default {
  mixins: [MutationHelper],
  props: {},
  mutation() {
    return {
      mutations: {
        createBillForAds(input) {
          input = this.mapInput(input);
          this.refetchCache('getNationalAds');
          return this.post(createBillForAds, { input });
        },
        createInvoiceForAds(input) {
          input = this.mapInput(input);
          this.refetchCache('getNationalAds');
          return this.post(createInvoiceForAds, { input });
        },
        syncBillToProvider(id) {
          return this.post(syncBillToProvider, { id });
        },
        syncInvoiceToProvider(id) {
          return this.post(syncInvoiceToProvider, { id });
        },
        deleteBill(id) {
          this.refetchCache('getNationalAds');
          return this.post(deleteBill, { id });
        },
        deleteInvoice(id) {
          this.refetchCache('getNationalAds');
          return this.post(deleteInvoice, { id });
        }
      }
    };
  },

  methods: {
    mapInput(input) {
      return this.castFields(input, {
        payment_preference: String,
        transaction_date: Date
      });
    }
  }
};
</script>
