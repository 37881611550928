<template>
  <div class="notifications-panel-icon">
    <user-notifications-subscription>
      <template v-slot="{ isLoading, notifications }">
        <notifications-icon
          :notifications="notifications"
          :icon-class="iconClass"
          @click="toggleNotificationPanel"
        />
        <notifications-panel
          v-if="showNotificationsPanel"
          ref="notesPanel"
          :is-loading="isLoading"
          :notifications="notifications"
          @close="showNotificationsPanel = false"
        />
      </template>
    </user-notifications-subscription>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('NotificationsPanelIcon', {
  props: {
    iconClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showNotificationsPanel: false
    };
  },
  methods: {
    toggleNotificationPanel() {
      // We want to trigger the close method when closing the Campaign Notes
      // in order to animate the overlay and slide out of the panel
      if (this.showNotificationsPanel) {
        this.$refs.notesPanel.close();
      } else {
        this.showNotificationsPanel = true;
      }
    }
  }
});
</script>
