import moment from 'moment-timezone';
import { dateDb } from '@/utils/filters';

export const DATE_FORMAT_API = 'yyyy-MM-dd';
export const DATE_FORMAT_SHORT_US = 'MM/DD/YYYY';
export const DATE_FORMAT_LONG = 'MMMM D, YYYY';

export const GROUP_BY = {
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  DAY: 'day'
};

export function momentTz(date) {
  return moment.tz(date, 'America/Chicago').local();
}

function allDatesInRange(range) {
  let curr = moment(range[0]);
  let endDate = moment(range[1]);

  let dates = [];

  while (curr.isSameOrBefore(endDate)) {
    dates.push(dateDb(curr));
    curr.add(1, 'day');
  }

  return dates;
}

/**
 * Element UI Date Picker's Shortcuts
 */
export const PICKER_SHORTCUTS = {
  lastYear: {
    text: 'Last Year',
    range() {
      return [
        dateDb(
          moment()
            .subtract(1, 'year')
            .startOf('year')
        ),
        dateDb(
          moment()
            .subtract(1, 'year')
            .endOf('year')
        )
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.lastYear.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.lastYear.range());
    }
  },

  thisYear: {
    text: 'This Year',
    range() {
      return [dateDb(moment().startOf('year')), dateDb(moment().endOf('year'))];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.thisYear.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.thisYear.range());
    }
  },

  nextYear: {
    text: 'Next Year',
    range() {
      return [
        dateDb(
          moment()
            .add(1, 'year')
            .startOf('year')
        ),
        dateDb(
          moment()
            .add(1, 'year')
            .endOf('year')
        )
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.thisYear.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.thisYear.range());
    }
  },

  lastMonth: {
    text: 'Last Month',
    range() {
      return [
        dateDb(
          moment()
            .subtract(1, 'month')
            .startOf('month')
        ),
        dateDb(
          moment()
            .subtract(1, 'month')
            .endOf('month')
        )
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.lastMonth.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.lastMonth.range());
    }
  },

  lastWeek: {
    text: 'Last Week',
    range() {
      return [
        dateDb(
          moment()
            .subtract(1, 'week')
            .startOf('week')
        ),
        dateDb(
          moment()
            .subtract(1, 'week')
            .endOf('week')
        )
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.lastWeek.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.lastWeek.range());
    }
  },

  thisWeek: {
    text: 'This Week',
    range() {
      return [dateDb(moment().startOf('week')), dateDb(moment().endOf('week'))];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.thisWeek.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.thisWeek.range());
    }
  },

  thisMonth: {
    text: 'This Month',
    range() {
      return [
        dateDb(moment().startOf('month')),
        dateDb(moment().endOf('month'))
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.thisMonth.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.thisMonth.range());
    }
  },

  nextWeek: {
    text: 'Next Week',
    range() {
      return [
        dateDb(
          moment()
            .add(1, 'week')
            .startOf('week')
        ),
        dateDb(
          moment()
            .add(1, 'week')
            .endOf('week')
        )
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.nextWeek.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.nextWeek.range());
    }
  },

  nextMonth: {
    text: 'Next Month',
    range() {
      return [
        dateDb(
          moment()
            .add(1, 'month')
            .startOf('month')
        ),
        dateDb(
          moment()
            .add(1, 'month')
            .endOf('month')
        )
      ];
    },
    multiple() {
      return allDatesInRange(PICKER_SHORTCUTS.nextMonth.range());
    },
    onClick(picker) {
      picker.$emit('pick', PICKER_SHORTCUTS.nextMonth.range());
    }
  }
};

PICKER_SHORTCUTS.ALL = [
  PICKER_SHORTCUTS.lastYear,
  PICKER_SHORTCUTS.thisYear,
  PICKER_SHORTCUTS.nextYear,
  PICKER_SHORTCUTS.lastMonth,
  PICKER_SHORTCUTS.thisMonth,
  PICKER_SHORTCUTS.nextMonth,
  PICKER_SHORTCUTS.lastWeek,
  PICKER_SHORTCUTS.thisWeek,
  PICKER_SHORTCUTS.nextWeek
];

export default {
  DATE_FORMAT_API,
  GROUP_BY
};
