<template>
  <div
    id="the-full-page-layout"
    class="flex flex-col h-screen min-h-screen overflow-hidden w-screen"
  >
    <header class="layout-header z-1000 flex-shrink-0">
      <router-view name="header" />
    </header>

    <main class="full-page-content flex-grow">
      <div class="h-full flex overflow-hidden relative">
        <slot name="sidebar">
          <transition name="fadeLeft" mode="out-in" :duration="500">
            <router-view name="sidebar" />
          </transition>
        </slot>
        <slot>
          <transition :duration="500" mode="out-in" name="fadeLeft">
            <router-view class="flex-grow" />
          </transition>
        </slot>
      </div>
    </main>

    <takeover-dialog
      v-if="$can('takeover_suppliers') || $can('takeover_buyers')"
    />
  </div>
</template>

<script>
import TakeoverDialog from '@/components/Core/TakeoverDialog';

export default {
  components: {
    TakeoverDialog
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';
</style>
