<script>
import { getSupplierAds } from '@/graphql/supplier/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

import { AdPostBookingStatuses } from '@/constants';

export default {
  mixins: [QueryMixin],

  query() {
    let pager = this.$store.getters['verifications/pager'];

    if (!pager.filter.status) {
      pager.filter.status = AdPostBookingStatuses;
    }

    return {
      query: getSupplierAds,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        ...pager
      }
    };
  }
};
</script>
