<template>
  <apollo-subscribe-to-more
    v-if="theDocument"
    v-bind="$props"
    :document="theDocument"
    :variables="theVariables"
    :update-query="onEvent"
  >
    <template slot-scope="props">
      <slot v-bind="props" />
    </template>
  </apollo-subscribe-to-more>
</template>

<script>
import { ApolloSubscribeToMore } from 'vue-apollo';

export default {
  props: {
    ...ApolloSubscribeToMore.props,
    document: { type: [String, Object], default: null },
    variables: { type: Object, default: null }
  },
  data() {
    return {
      result: null
    };
  },
  /**
   * Override this query() method with your own query and variables when extending via a component,
   */
  subscription() {
    return {
      document: this.document,
      variables: this.variables,
      after: null
    };
  },
  computed: {
    theDocument() {
      return this.getSubscription().document;
    },
    theVariables() {
      const variables = this.getSubscription().variables;

      if (variables) {
        return {
          ...variables,
          ...this.variables
        };
      } else {
        return {
          ...this.variables
        };
      }
    }
  },
  methods: {
    getSubscription() {
      return this.$options.subscription.call(this, this.$props);
    },
    onEvent(previousResult, result) {
      let data;

      const callback = this.getSubscription().after;

      if (callback) {
        data = callback(previousResult, result);
      } else {
        data = previousResult;
      }

      // TODO: how do we handle this when there is no defined callback?
      this.$emit('data', data);

      return data;
    }
  }
};
</script>
