<script>
import { getMediakitTypes } from '@/graphql/mediakit/queries.graphql';

import { map } from 'lodash';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  name: 'MediakitTypesQuery',

  mixins: [QueryHelper],
  props: {
    // The list of parent medium Types (for use with one of properties / collections / products)
    mediumTypes: { type: Array, default: () => [] },

    // Choose the types you want returned
    properties: Boolean,
    collections: Boolean,
    products: Boolean,
    isSuper: Boolean,
    supplierId: { type: String, default: null }
  },

  query(props) {
    return {
      query: getMediakitTypes,
      variables: {
        supplier_id: props.isSuper
          ? null
          : props.supplierId || this.$store.getters['auth/supplierId']
      }
    };
  },

  computed: {
    /**
     * a flat list of the values for the given list of Medium Types
     */
    mediumTypeValues() {
      return map(this.mediumTypes, 'type.value');
    },

    /**
     * Override the resultData to give the requested type / sub-type
     */
    resultData() {
      if (!this.result || !this.result.data) {
        return null;
      }

      let mediakitTypes = this.xxxFixTypes(this.result.data.mediakitTypes);

      if (this.properties) {
        return this.getFilteredTypes('propertyTypes', mediakitTypes);
      } else if (this.collections) {
        return this.getFilteredTypes('collectionTypes', mediakitTypes);
      } else if (this.products) {
        return this.getFilteredTypes('productTypes', mediakitTypes);
      } else {
        return mediakitTypes;
      }
    }
  },

  methods: {
    /**
     *  XXX: This is a hack for a crazy bug where the mediakitTypes apollo cache gets overwritten
     *  where types with "is_curated = false" removed from the list
     * TODO: Need to figure out why this bug is happening and fix it!!
     */
    xxxFixTypes(mediakitTypes) {
      if (!this.cachedTypes) {
        this.cachedTypes = mediakitTypes;
      }

      return this.cachedTypes;
    },

    /**
     * Get the full nested list of mediakit types.
     *
     * @returns {{query: *, variables: {supplier_id: number}}}
     */

    /**
     * Retrieves the types list for Properties / Collections / Products / etc.
     * filtered by the list of medium types that they are associated to
     *
     * @param type
     * @param mediakitTypes - the list of mediakit types to filter
     * @returns {{query, variables}}
     */
    getFilteredTypes(type, mediakitTypes) {
      let types = [];

      for (let { value, [type]: filterTypes } of mediakitTypes) {
        if (this.mediumTypeValues.indexOf(value) >= 0) {
          types = types.concat(filterTypes);
        }
      }

      return types;
    }
  }
};
</script>
