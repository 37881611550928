<script>
import { getCollectionSchedule } from '@/graphql/mediakit/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    collectionId: {
      type: String,
      required: true
    }
  },

  query(props) {
    return {
      query: getCollectionSchedule,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        collection_id: props.collectionId
      }
    };
  }
};
</script>
