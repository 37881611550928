export const state = {
  appVersionMismatch: false,
  contentScrollY: 0,
  contentOffsetTop: 0,
  mediakitScrollY: 0
};

const mutations = {
  appVersionMismatch(state, value) {
    state.appVersionMismatch = value;
  },
  contentScrollChanged(state, position) {
    state.contentScrollY = position;
  },
  setContentOffset(state, value) {
    state.contentOffsetTop = value;
  },
  setMediakitScrollY(state, value) {
    state.mediakitScrollY = value;
  }
};

const actions = {};

const getters = {
  appVersionMismatch: state => {
    return state.appVersionMismatch;
  },
  getContentScrollY: state => {
    return state.contentScrollY;
  },
  getContentOffsetTop: state => {
    return state.contentOffsetTop;
  },
  getMediakitScrollY: state => {
    return state.mediakitScrollY;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
