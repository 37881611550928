<script>
import {
  applyTemplate,
  createMedium,
  deleteMedium,
  saveTemplate,
  updateMedium
} from '@/graphql/mediakit/mediumMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  data() {
    return {
      isDeleting: false
    };
  },

  mutation() {
    return {
      mutations: {
        applyTemplate(medium) {
          return this.post(applyTemplate, { id: medium.id });
        },
        saveTemplate(medium) {
          return this.post(saveTemplate, { id: medium.id });
        },
        createMedium(medium) {
          const input = this.mapInput(medium);
          return this.post(createMedium, { input });
        },
        updateMedium(original, medium) {
          const input = this.mapInput(medium);

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateMediakit: {
                __typename: 'MediakitMutation',
                mutateMedium: {
                  __typename: 'MediumMutation',
                  update: true,
                  medium: {
                    __typename: 'Medium',
                    ...original,
                    ...input
                  }
                }
              }
            }
          };

          return this.post(updateMedium, { id: original.id, input });
        },
        async deleteMedium(id) {
          this.isDeleting = true;

          try {
            await this.post(deleteMedium, { id });
          } catch (e) {
            this.isDeleting = false;
            throw e;
          }
        }
      },
      variables: {
        supplier_id: this.supplierId
      }
    };
  },

  computed: {
    supplierId() {
      return this.$store.getters['auth/supplierId'];
    }
  },

  methods: {
    mapInput(medium) {
      const fields = {
        name: String,
        is_enabled: Boolean,
        typeEdges: {
          _map: type => type.type.value
        }
      };

      if (this.$can('manage_super_objects')) {
        fields.is_super = Boolean;
      }

      return this.castFields(medium, fields);
    }
  }
};
</script>
