import { AccountType } from '@/constants';
import AccountingRoutes from './accounting';
import MediakitRoutes from './mediakit';
import OrdersRoutes from './orders';

const TheSupplierLayout = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Supplier/TheSupplierLayout'
  );
const PageSupplierDashboard = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Supplier/Dashboard/PageSupplierDashboard'
  );
const PageSupplierOrders = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */
    '@/components/Supplier/PageSupplierOrders'
  );
const SettingsLayout = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Core/Settings/SettingsLayout'
  );
const SettingsAccountTab = () =>
  import(
    /* webpackChunkName: 'SupplierAccount', webpackPrefetch: true */ '@/components/Core/Settings/SettingsAccountTab'
  );
const SettingsNotificationsTab = () =>
  import(
    /* webpackChunkName: 'SupplierAccount', webpackPrefetch: true */ '@/components/Core/Settings/SettingsNotificationsTab'
  );
const SupplierSettingsOrganizationTab = () =>
  import(
    /* webpackChunkName: 'SupplierAccount', webpackPrefetch: true */ '@/components/Supplier/SupplierSettingsOrganizationTab'
  );
const SupplierSettingsTeamTab = () =>
  import(
    /* webpackChunkName: 'SupplierAccount', webpackPrefetch: true */
    '@/components/Supplier/SupplierSettingsTeamTab'
  );

const SupplierSettingsSubscriptionTab = () =>
  import(
    /* webpackChunkName: 'SupplierAccount', webpackPrefetch: true */
    '@/components/Supplier/SupplierSettingsSubscriptionTab'
  );
const V4SupplierAdApprovalsTable = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Supplier/Ads/V4SupplierAdApprovalsTable'
  );
const V4SupplierAdsTable = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Supplier/Ads/V4SupplierAdsTable'
  );
const V4SupplierOrdersTable = () =>
  import(
    /* webpackChunkName: 'Supplier', webpackPrefetch: true */ '@/components/Supplier/Ads/V4SupplierOrdersTable'
  );
const PageAnalytics = () =>
  import(
    /* webpackChunkName: 'SupplierDigital' */ '@/components/Supplier/PageAnalytics'
  );
const PageDigitalAnalytics = () =>
  import(
    /* webpackChunkName: 'SupplierDigital' */ '@/components/Supplier/Analytics/Digital/PageDigitalAnalytics'
  );
const DigitalAnalyticsOverview = () =>
  import(
    /* webpackChunkName: 'SupplierDigital' */ '@/components/Supplier/Analytics/Digital/DigitalAnalyticsOverview'
  );
const PageDiscounts = () =>
  import(
    /* webpackChunkName: 'SupplierDiscount' */ '@/components/Supplier/Discounts/PageDiscounts'
  );

export default rootPath => {
  return {
    path: rootPath,
    component: TheSupplierLayout,
    meta: {
      auth: true,
      type: AccountType.SUPPLIER
    },
    children: [
      {
        path: '/publisher:afterPublisher(.*)',
        redirect: to => {
          return {
            path: '/supplier' + to.params.afterPublisher
          };
        }
      },
      {
        path: 'dashboard',
        name: 'supplier.dashboard',
        component: PageSupplierDashboard,
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '',
        redirect: { name: 'supplier.ads' },
        component: PageSupplierOrders,
        meta: {
          title: 'Orders'
        },
        children: [
          {
            path: 'orders',
            name: 'supplier.orders',
            component: V4SupplierOrdersTable
          },
          {
            path: 'approvals',
            name: 'supplier.approvals',
            component: V4SupplierAdApprovalsTable
          },
          {
            path: 'ads',
            name: 'supplier.ads',
            component: V4SupplierAdsTable
          }
        ]
      },
      {
        path: 'analytics',
        component: PageAnalytics,
        meta: {
          title: 'Analytics'
        },
        children: [
          {
            path: 'digital',
            name: 'supplier.analytics.digital',
            component: PageDigitalAnalytics,
            redirect: {
              name: 'supplier.analytics.digital.website'
            },
            children: [
              {
                path: 'overview',
                name: 'supplier.analytics.digital.website',
                component: DigitalAnalyticsOverview,
                props: () => ({
                  analyticsType: 'Website'
                })
              },
              {
                path: 'email',
                name: 'supplier.analytics.digital.email',
                component: DigitalAnalyticsOverview,
                props: () => ({
                  analyticsType: 'Email'
                })
              }
            ]
          }
        ]
      },
      {
        path: 'settings',
        name: 'supplier.settings',
        redirect: { name: 'supplier.settings.account' },
        component: SettingsLayout,
        meta: {
          title: 'Settings'
        },
        children: [
          {
            path: 'account',
            name: 'supplier.settings.account',
            component: SettingsAccountTab
          },
          {
            path: 'notifications',
            name: 'supplier.settings.notifications',
            component: SettingsNotificationsTab
          },
          {
            path: 'organization',
            name: 'supplier.settings.organization',
            component: SupplierSettingsOrganizationTab
          },
          {
            path: 'team',
            name: 'supplier.settings.team',
            component: SupplierSettingsTeamTab
          },
          {
            path: 'subscription',
            name: 'supplier.settings.subscription',
            component: SupplierSettingsSubscriptionTab
          }
        ]
      },
      {
        path: 'dashboard/ads',
        redirect: { name: 'supplier.ads' }
      },
      {
        path: 'dashboard/orders',
        redirect: { name: 'supplier.orders' }
      },
      {
        path: 'dashboard/national/orders',
        redirect: { name: 'supplier.orders' }
      },
      ...OrdersRoutes(rootPath),
      ...AccountingRoutes(rootPath),
      MediakitRoutes(rootPath + '/mediakit'),
      {
        path: 'discounts',
        name: 'supplier.discounts',
        component: PageDiscounts,
        meta: { title: 'Discounts' }
      }
    ]
  };
};
