<template>
  <div class="loader-content" :class="classObject">
    <div class="loader-box" :class="loaderClass" :style="style"></div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('loaderBox', {
  props: {
    animated: {
      type: Boolean,
      default: true
    },
    dark: Boolean,
    height: {
      type: [Number, String],
      default: 10
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    loaderClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    classObject() {
      return {
        'is-dark': this.dark,
        'loader-is-animated': this.animated
      };
    },
    style() {
      return {
        height: this.height + (typeof this.height === 'string' ? '' : 'em'),
        width: this.width + (typeof this.width === 'string' ? '' : 'em')
      };
    }
  }
});
</script>
