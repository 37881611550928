import pager from '../includes/pager';
import moment from 'moment';
import { dateDb } from '@/utils/filters';

const startDate = moment()
  .subtract(1, 'month')
  .startOf('month');

export const state = {
  pager: {
    ...pager.state.pager,
    filter: {
      adDeliveryMonth: dateDb(startDate)
    }
  },
  jobBatch: null
};

const mutations = {
  ...pager.mutations,
  setJobBatch(state, batch) {
    state.jobBatch = batch;
  }
};

const actions = {
  ...pager.actions
};

const getters = {
  ...pager.getters,
  getJobBatch: state => state.jobBatch
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
