var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('takeover-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var takeoverBuyer = ref.takeoverBuyer;
var takeoverSupplier = ref.takeoverSupplier;
return [(!_vm.isTakingOver)?_c('div',[(_vm.isSupplier)?_c('div',{staticClass:"filter-bar flex mb-5"},[_c('div',{staticClass:"flex-grow"},[_c('form',{attrs:{"autocomplete":"off"}},[_c('custom-el-select',{staticClass:"w-full",attrs:{"select-class":"w-full","value":_vm.supplierFilter.organization_type,"options":_vm.organizationTypes,"clearable":"","placeholder":"All Organizations"},on:{"input":function($event){return _vm.saveFilter({ organization_type: $event })}}})],1)]),_c('div',{staticClass:"flex-grow"})]):_vm._e(),_c('el-autocomplete',{ref:"autocomplete",staticClass:"w-full",attrs:{"debounce":100,"fetch-suggestions":_vm.getSuggestions,"placeholder":("Search " + (_vm.isBuyer ? 'advertisers' : 'suppliers') + "..."),"value-key":"name"},on:{"select":function($event){return _vm.takeoverAccount(
              _vm.isBuyer ? takeoverBuyer : takeoverSupplier,
              $event
            )}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var item = ref.item;
return _c('div',{class:{ 'bg-light-red': item.is_test }},[_vm._v(" "+_vm._s(item.display_name)+" ")])}}],null,true),model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}},[(_vm.listItems.length === 0)?_c('icon',{attrs:{"slot":"suffix","icon":_vm.loadingIcon,"spin":""},slot:"suffix"}):_vm._e()],1)],1):_c('div',[_vm._v(" Hold on to your butts... "),_c('icon',{attrs:{"icon":_vm.loadingIcon,"spin":""}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }