export const FeatureFlags = {
  ACCOUNTING_INVOICES: 'accounting-invoices',
  ACCOUNTING_REPORTS: 'accounting-reports',
  AD_KEYWORD_TARGETING: 'ad-keyword-targeting',
  AD_SHOP_ACCESS: 'ad-shop-access',
  BUYER_CAMPAIGN_DASHBOARD: 'buyer-campaign-dashboard',
  CREATIVES_UPLOADER: 'creatives-uploader',
  CUSTOMER_MANAGEMENT: 'customer-management',
  FULFILLMENT_FEE_QUANTITY: 'fulfillment-fee-quantity',
  MEDIA_KIT_BUILDER: 'media-kit-builder',
  PLACEMENT_SETTINGS: 'placement-settings',
  REPORTING_REPS_DIMENSION: 'reporting-reps-dimension',
  SUBSCRIPTIONS_PAGE: 'subscriptions-page',
  SUPER_MEDIA_KIT: 'super-media-kit',
  SUPPLIER_DIGITAL_DASHBOARD: 'supplier-digital-dashboard',
  V4_ADS: 'v4-ads'
};

export const FeaturableLabels = {
  'App\\Models\\Supplier\\Supplier': 'Supplier',
  'App\\Models\\Buyer\\Buyer': 'Buyer',
  'App\\Models\\Account\\User': 'User'
};

export const FeaturablesInfo = [
  {
    name: 'Supplier',
    value: 'App\\Models\\Supplier\\Supplier'
  },
  {
    name: 'Buyer',
    value: 'App\\Models\\Buyer\\Buyer'
  },
  {
    name: 'User',
    value: 'App\\Models\\Account\\User'
  }
];
