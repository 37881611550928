
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ReachInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Reach"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"is_primary"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"date_start"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"date_end"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":82}};
    doc.loc.source = {"body":"fragment ReachInfo on Reach {\n  id\n  is_primary\n  value\n  date_start\n  date_end\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
