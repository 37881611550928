import { colors } from '@/tailwind';

export const Algorithms = [
  {
    value: 'prepend',
    name: 'Prepend',
    description: 'Before all other content located at Xpath'
  },
  {
    value: 'in-content',
    name: 'In-Content',
    description:
      'Inline with paragraphs using an intelligent placement strategy (ie: Articles or other text-based content)'
  },
  {
    value: 'middle',
    name: 'Middle of Page',
    description:
      'From the injection Xpath, find the widest element as close to 50% of total page height, but at least 25% and at most 75% of total page height'
  },
  {
    value: 'append',
    name: 'Append',
    description: 'After all other content located at Xpath'
  }
];

export const AdLabelPositions = [
  {
    value: '',
    name: 'No Ad Label'
  },
  {
    value: 'above',
    name: 'Above'
  },
  {
    value: 'below',
    name: 'Below'
  },
  {
    value: 'top-right',
    name: 'Inside - Top Right'
  },
  {
    value: 'top-left',
    name: 'Inside - Top Left'
  },
  {
    value: 'bottom-right',
    name: 'Inside - Bottom Right'
  },
  {
    value: 'bottom-left',
    name: 'Inside - Bottom Left'
  }
];

export const AdLabelColors = [
  colors.white,
  colors.blue,
  colors['dark-gray'],
  colors.gray,
  colors['dark-silver'],
  '#000000'
];
