export default {
  install(Vue) {
    Vue.config.optionMergeStrategies.render = (childFn, parentFn) => {
      if (!childFn) return parentFn;
      if (!parentFn) return childFn;

      return function(createElement, children) {
        let result = childFn.call(this, createElement, children);

        // If our child is returning null, that means it requires the result of the parent to render itself correctly
        // So call the parent first then the child again with the result of the parent
        if (result === null) {
          result = parentFn.call(this, createElement, result);

          return childFn.call(this, createElement, result);
        } else {
          return parentFn.call(this, createElement, result);
        }
      };
    };
  }
};
