import SupplierRoutes from './supplier';

const beforeEnter = to => {
  location.href = (process.env.VUE_APP_V4_URL || location.origin) + to.path;
};

SupplierRoutes.map(r => (r.beforeEnter = beforeEnter));

export default {
  path: '/v4',
  children: [...SupplierRoutes]
};
