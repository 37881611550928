<script>
import { getUsersByBuyerId } from '@/graphql/customer/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  props: {
    buyerId: {
      type: [String, Number],
      required: true
    }
  },

  query() {
    return {
      query: getUsersByBuyerId,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        buyer_id: this.buyerId
      }
    };
  }
};
</script>
