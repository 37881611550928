export const CustomerType = {
  NATIONAL: {
    label: 'National',
    value: 'NATIONAL'
  },
  Local: {
    label: 'Local',
    value: 'LOCAL'
  },
  ON_CAMPUS: {
    label: 'On Campus',
    value: 'ON_CAMPUS'
  },
  NON_PROFIT: {
    label: 'Non-Profit',
    value: 'NON_PROFIT'
  }
};

export const CustomerTypes = [
  CustomerType.NATIONAL,
  CustomerType.Local,
  CustomerType.ON_CAMPUS,
  CustomerType.NON_PROFIT
];
