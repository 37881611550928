<script>
import { getSupplierCustomers } from '@/graphql/customer/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    pager: {
      type: Object,
      default: () => ({})
    }
  },

  query(props) {
    return {
      query: getSupplierCustomers,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        ...props.pager
      }
    };
  }
};
</script>
