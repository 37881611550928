<script>
import { getBuyerFilterData } from '@/graphql/buyer/getBuyerFilterData.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  query() {
    return {
      query: getBuyerFilterData,
      variables: {
        buyer_id: this.$store.getters['auth/buyerId']
      }
    };
  }
};
</script>
