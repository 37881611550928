const PagePlacementSettings = () =>
  import(
    /* webpackChunkName: 'PlacementSettings' */ '@/components/Supplier/Mediakit/Placements/PagePlacementSettings'
  );

const ThePlacementHeader = () =>
  import(
    /* webpackChunkName: 'PlacementSettings' */ '@/components/Supplier/Mediakit/Placements/ThePlacementHeader'
  );

export default rootPath => [
  {
    path: rootPath + '/placements/:property_id?/:variant_id?',
    name: 'supplier.mediakit.placements',
    components: {
      default: PagePlacementSettings,
      header: ThePlacementHeader
    },
    meta: { title: 'Digital Ad Manager' }
  }
];
