import { dashboard as dashboardIcon } from '@/vendor/icons';

export default store => {
  const dashboard = {
    name: 'Dashboard (v4)',
    icon: dashboardIcon,
    component: 'v4.supplier.dashboard'
  };

  return [dashboard];
};
