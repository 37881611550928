import { FeatureFlags } from '@/constants';
import { getAuthorizationToken } from '@/utils/authorization';
import {
  database,
  fileImage as creativesIcon,
  heart,
  lab as fastIcon,
  location,
  money as financeIcon,
  tag
} from '@/vendor/icons';

export default store => {
  const isMediaKitFeatureEnabled = store.getters['auth/feature'](
    FeatureFlags.SUPER_MEDIA_KIT
  );

  const isCreativesUploaderEnabled = store.getters['auth/feature'](
    FeatureFlags.CREATIVES_UPLOADER
  );

  const creatives = {
    name: 'Creatives',
    icon: creativesIcon,
    href: `${window._env.API_URL}/admin-v4/creatives`
  };
  const newCampaigns = {
    name: 'Campaigns',
    icon: fastIcon,
    component: 'admin.campaigns-fast'
  };
  const financeTracker = {
    name: 'Finances',
    icon: financeIcon,
    component: 'admin.finance-tracker'
  };
  const mediaKit = {
    name: 'Media Kit',
    icon: tag,
    component: 'admin.mediakit',
    children: [
      {
        name: 'Tags',
        component: {
          name: 'admin.mediakit.tags'
        }
      },
      {
        name: 'National Products',
        component: {
          name: 'admin.mediakit.nationalproducts'
        }
      }
    ]
  };

  return [
    {
      name: 'Nova',
      icon: database,
      href: `${window._env.API_URL}/nova`
    },
    newCampaigns,
    isCreativesUploaderEnabled ? creatives : null,
    financeTracker,
    isMediaKitFeatureEnabled ? mediaKit : null,
    {
      name: 'Location Search Tool',
      icon: location,
      component: 'admin.locations'
    },
    {
      name: 'Dev',
      icon: heart,
      children: [
        {
          name: 'Feature Flags',
          component: 'dev.feature-flags'
        },
        {
          name: 'Billing Auth',
          href:
            `${window._env.API_URL}/oauth/QuickBooks/authorize/?jwt_token=` +
            getAuthorizationToken(),
          title: store.getters['auth/can']('manage_billing')
            ? null
            : 'You are not allowed to Manage Billing',
          disabled: !store.getters['auth/can']('manage_billing')
        },
        {
          name: "Developer's Guide",
          component: {
            name: 'dev.guide'
          }
        }
      ]
    }
  ];
};
