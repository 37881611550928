<script>
import {
  addCampuses,
  addCampusesByName,
  createAudience,
  deleteAudience,
  removeCampuses,
  selectAllAvailableCampuses,
  selectAllCampuses,
  updateAudience
} from '@/graphql/audience/audienceMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

const fields = {
  name: String,
  campuses: Array,
  selections: Array,
  available_selections: Array,
  selectAllSelections: Boolean,
  selectAllAvailableSelections: Boolean
};

export default {
  mixins: [MutationHelper],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  mutation() {
    return {
      mutations: {
        createAudience(input) {
          input = this.castFields(input, fields);
          return this.post(createAudience, { input });
        },
        deleteAudience() {
          return this.post(deleteAudience);
        },
        updateAudience(audience, input) {
          input = this.castFields(input, fields);

          this.optimisticResponse = {
            mutateBuyer: {
              __typename: 'BuyerMutation',
              mutateAudience: {
                __typename: 'AudienceMutation',
                update: true,
                audience: {
                  __typename: 'Audience',
                  ...audience,
                  ...input
                }
              }
            }
          };

          return this.post(updateAudience, { input });
        },
        selectAllAvailableCampuses(audience, selectAll) {
          return this.post(selectAllAvailableCampuses, {
            selectAll
          });
        },
        selectAllCampuses(audience, selectAll) {
          return this.post(selectAllCampuses, {
            selectAll
          });
        },
        addCampuses(audience, ids) {
          return this.post(addCampuses, { ids });
        },
        addCampusesByName(audience, names) {
          return this.post(addCampusesByName, { names });
        },
        removeCampuses(audience, ids) {
          return this.post(removeCampuses, { ids });
        }
      },
      variables: {
        id: this.id || undefined,
        buyer_id: this.$store.getters['auth/buyerId'],
        ...this.$store.getters['audience/pager']
      }
    };
  }
};
</script>
