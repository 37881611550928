<script>
import {
  applyDimensions,
  copyCollection,
  createCollection,
  deleteCollection,
  updateCollection
} from '@/graphql/mediakit/collectionMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],
  props: {
    propertyId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isCopying: false,
      isDeleting: false
    };
  },

  mutation() {
    return {
      mutations: {
        createCollection(input) {
          input = this.mapInput(input);
          input.property_id = this.propertyId;

          return this.post(createCollection, { input });
        },
        updateCollection(collection, input) {
          input = this.mapInput(input);

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateMediakit: {
                __typename: 'MediakitMutation',
                mutateCollection: {
                  __typename: 'CollectionMutation',
                  update: true,
                  collection: {
                    __typename: 'Collection',
                    ...collection,
                    ...{ ...input, typeEdges: undefined },
                    reachType: {
                      __typename: 'ReachType',
                      ...collection.reachType,
                      ...input.reachType
                    },
                    reaches: {
                      __typename: 'Reach',
                      ...collection.reaches,
                      ...input.reaches
                    },
                    dimension: {
                      __typename: 'Dimension',
                      ...collection.dimension,
                      ...input.dimension
                    },
                    schedule: {
                      __typename: 'Schedule',
                      ...collection.schedule,
                      ...input.schedule,
                      quantityLimit: {
                        __typename: 'QuantityLimit',
                        ...((collection.schedule &&
                          collection.schedule.quantityLimit) ||
                          {}),
                        ...((input.schedule && input.schedule.quantityLimit) ||
                          {})
                      }
                    }
                  }
                }
              }
            }
          };

          return this.post(updateCollection, { id: collection.id, input });
        },
        async copyCollection(collection, input) {
          input = this.mapInput(input);

          this.isCopying = true;
          await this.post(copyCollection, { id: collection.id, input });
          this.isCopying = false;
        },
        async deleteCollection(id) {
          this.isDeleting = true;

          try {
            await this.post(deleteCollection, { id });
          } catch (e) {
            this.isDeleting = false;
            throw e;
          }
        },
        applyDimensions(collection, input) {
          input = this.castFields(input, {
            dimension: {
              id: String,
              width: Number,
              height: Number,
              unit: String
            }
          });

          this.post(applyDimensions, {
            id: collection.id,
            input
          });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        propertyId: this.propertyId
      }
    };
  },

  methods: {
    mapInput(collection) {
      const fields = {
        property_id: String,
        name: String,
        is_enabled: Boolean,
        typeEdges: {
          _map: type => type.type.value
        },
        description: String,
        reachType: {
          id: String,
          value: String
        },
        reaches: {
          id: String,
          is_primary: Boolean,
          value: Number,
          date_start: String,
          date_end: String
        },
        is_reach_applicable: Boolean,
        dimension: {
          id: String,
          width: Number,
          height: Number,
          depth: Number,
          unit: String
        },
        schedule: {
          schedule_type_id: String,
          dates: Array,
          quantityLimit: {
            min: Number,
            max: Number,
            increment: Number,
            unit: String
          }
        }
      };

      if (this.$can('manage_super_objects')) {
        fields.is_super = Boolean;
      }

      return this.castFields(collection, fields);
    }
  }
};
</script>
