import { cancel, clock, disable, dollar, ok } from '@/vendor/icons';

export const AdStatus = {
  CANCELED: 'CANCELED',
  CREATIVE_PENDING: 'CREATIVE_PENDING',
  CREDITED: 'CREDITED',
  MAKE_GOOD: 'MAKE_GOOD',
  PENDING_CREATIVE_APPROVAL: 'PENDING_CREATIVE_APPROVAL',
  PENDING_VERIFICATION_APPROVAL: 'PENDING_VERIFICATION_APPROVAL',
  READY: 'READY',
  VERIFICATION_REJECTED: 'VERIFICATION_REJECTED',
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED'
};

export const AdStatusValues = {
  CANCELED: 'Canceled',
  CREATIVE_PENDING: 'Creative Pending',
  CREDITED: 'Credited',
  MAKE_GOOD: 'Make Good',
  PENDING_CREATIVE_APPROVAL: 'Pending Creative Approval',
  PENDING_VERIFICATION_APPROVAL: 'Pending Verification Approval',
  READY: 'Ready',
  VERIFICATION_REJECTED: 'Verification Rejected',
  UNVERIFIED: 'Unverified',
  VERIFIED: 'Verified'
};

export const AdInactiveStatuses = {
  CANCELED: AdStatus.CANCELED,
  CREDITED: AdStatus.CREDITED,
  MAKE_GOOD: AdStatus.MAKE_GOOD
};

export const AdStatusInfo = {
  CANCELED: {
    icon: cancel,
    class: 'text-red hover:text-dark-red',
    name: 'Canceled',
    key: AdStatus.CANCELED,
    value: AdStatusValues.CANCELED
  },

  PENDING_CREATIVE_APPROVAL: {
    icon: clock,
    class: 'text-dark-silver hover:text-gunmetal',
    name: 'Creative Pending Approval',
    key: AdStatus.PENDING_CREATIVE_APPROVAL,
    value: AdStatusValues.PENDING_CREATIVE_APPROVAL
  },

  CREATIVE_PENDING: {
    icon: clock,
    class: 'text-dark-silver hover:text-gunmetal',
    name: 'Creative Pending Upload',
    key: AdStatus.CREATIVE_PENDING,
    value: AdStatusValues.CREATIVE_PENDING
  },

  READY: {
    icon: ok,
    class: 'text-green hover:text-dark-green',
    name: 'Creative Ready',
    key: AdStatus.READY,
    value: AdStatusValues.READY
  },

  CREDITED: {
    icon: dollar,
    class: 'text-red hover:text-dark-red',
    name: 'Credited',
    key: AdStatus.CREDITED,
    value: AdStatusValues.CREDITED
  },

  PENDING_VERIFICATION_APPROVAL: {
    icon: clock,
    class: 'text-dark-silver hover:text-gunmetal',
    name: 'Pending Verification Approval',
    key: AdStatus.PENDING_VERIFICATION_APPROVAL,
    value: AdStatusValues.PENDING_VERIFICATION_APPROVAL
  },

  VERIFICATION_REJECTED: {
    icon: disable,
    class: 'text-red   hover:text-dark-red  ',
    name: 'Verification Rejected',
    key: AdStatus.VERIFICATION_REJECTED,
    value: AdStatusValues.VERIFICATION_REJECTED
  },

  UNVERIFIED: {
    icon: clock,
    class: 'text-dark-silver hover:text-gunmetal',
    name: 'Unverified',
    key: AdStatus.UNVERIFIED,
    value: AdStatusValues.UNVERIFIED
  },

  VERIFIED: {
    icon: ok,
    class: 'text-green hover:text-dark-green',
    name: 'Verified',
    key: AdStatus.VERIFIED,
    value: AdStatusValues.VERIFIED
  }
};

export const AdPostBookingStatuses = [
  AdStatusValues.VERIFIED,
  AdStatusValues.PENDING_VERIFICATION_APPROVAL,
  AdStatusValues.VERIFICATION_REJECTED,
  AdStatusValues.UNVERIFIED
];

export const BOOKING_TYPES = ['Original', 'Non-Original', 'Exclude'];
