<script>
import { getSupplierCustomer } from '@/graphql/customer/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    buyerId: {
      type: String,
      default: null
    },
    customerId: {
      type: String,
      default: null
    }
  },

  query() {
    return {
      query: getSupplierCustomer,
      variables: {
        buyerId: this.buyerId,
        customerId: this.customerId,
        supplierId: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
