import { cancel, clock, disable, dollar, ok } from '@/vendor/icons';

export const TransactionStatus = {
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
  VOID: 'VOID',
  PARTIAL_REFUND: 'PARTIAL_REFUND'
};

export const TransactionType = {
  BILL_PAYMENT: 'Bill Payment',
  CREDIT: 'Credit',
  PAYMENT: 'Payment',
  TRANSFER: 'Transfer',
  DISCOUNT: 'Discount',
  SALE: 'Sale'
};

export const TransactionStatusInfo = {
  COMPLETE: {
    id: 1,
    icon: ok,
    class: 'text-green hover:text-dark-green',
    name: 'Success'
  },

  PARTIAL_REFUND: {
    id: 4,
    icon: dollar,
    class: 'text-yellow hover:text-dark-yellow',
    name: 'Partial Refund'
  },

  FAILED: {
    id: 2,
    icon: cancel,
    class: 'text-red hover:text-dark-red',
    name: 'Failed'
  },

  PENDING: {
    id: 0,
    icon: clock,
    class: 'text-dark-silver hover:text-gunmetal',
    name: 'Pending'
  },

  VOID: {
    id: 3,
    icon: disable,
    class: 'text-red hover:text-dark-red',
    name: 'Voided'
  }
};
