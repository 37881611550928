<script>
import {
  addCampaignRep,
  removeCampaignRep
} from '@/graphql/cart/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        // eslint-disable-next-line
        addCampaignRep(campaign, user_id) {
          this.optimisticResponse = {
            mutateBuyer: {
              __typename: 'BuyerMutation',
              mutateCampaign: {
                __typename: 'CampaignMutation',
                addCampaignRep: true,
                campaign: {
                  __typename: 'Campaign',
                  id: campaign.id,
                  reps: [
                    ...campaign.reps,
                    {
                      id: user_id,
                      name: null,
                      email: null,
                      profilePicture: null,
                      phone: null,
                      created_at: null,
                      __typename: 'User'
                    }
                  ]
                }
              }
            }
          };

          return this.post(addCampaignRep, {
            campaign_id: campaign.id,
            user_id
          });
        },
        // eslint-disable-next-line
        removeCampaignRep(campaign_id, user_id) {
          return this.post(removeCampaignRep, { campaign_id, user_id });
        }
      },
      variables: {
        buyer_id: this.buyerId
      }
    };
  },

  computed: {
    buyerId() {
      return (
        this.$store.getters['auth/buyerId'] ||
        this.$store.getters['adShop/takeoverBuyerId']
      );
    }
  }
};
</script>
