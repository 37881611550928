<template>
  <div
    id="the-default-layout"
    class="flex flex-col h-screen min-h-screen overflow-hidden w-screen"
  >
    <header class="default-layout-header flex z-1000">
      <slot name="header" />
    </header>

    <div class="main">
      <aside>
        <slot name="sidebar" />
      </aside>
      <div ref="content" class="content">
        <slot name="content-top" />

        <transition :duration="500" mode="out-in" name="fadeLeft">
          <slot>
            <router-view />
          </slot>
        </transition>
      </div>
    </div>

    <takeover-dialog
      v-if="$can('takeover_suppliers') || $can('takeover_buyers')"
    />
  </div>
</template>

<script>
import TakeoverDialog from '@/components/Core/TakeoverDialog';

import Waves from 'node-waves';

export default {
  name: 'TheDefaultLayout',

  components: {
    TakeoverDialog
  },

  mounted() {
    // Add the waves element click effect for anything using the .fd-waves class
    Waves.attach('.fd-waves');
    Waves.init();

    this.$refs.content.onscroll = e => {
      window.requestAnimationFrame(() => {
        this.$store.commit('common/contentScrollChanged', e.target.scrollTop);
      });
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

header {
  flex: 0 $header-height;
}

.main {
  display: flex;
  flex: 1;

  min-height: 0;

  aside {
    flex: 0 0 auto;
  }

  .content {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 500;
  }
}
</style>

<style lang="scss">
@media print {
  #the-default-layout {
    overflow: visible !important;

    .default-layout-header {
      display: none;
    }
  }
}
</style>
