<template>
  <div class="organization-picker">
    <el-popover
      v-model="showPopover"
      popper-class="organization-picker-popover"
      placement="bottom"
      trigger="click"
    >
      <organization-button
        slot="reference"
        :is-test="isTest"
        :name="currentOrganizationName"
        :icon="currentOrganizationIcon"
        :button-class="buttonClass"
      />
      <account-info-item
        :email="user.email"
        :name="user.name"
        @click-account-button="handleClickAccountButton"
      />
      <organization-list
        :current="current"
        :suppliers="suppliers"
        :buyers="buyers"
        @select="handleSelect"
      />
    </el-popover>
  </div>
</template>

<script>
import OrganizationButton from './OrganizationButton';
import OrganizationList from './OrganizationList';
import AccountInfoItem from '@/components/Core/OrganizationPicker/AccountInfoItem';
import { AccountType } from '@/constants';

import { dollar, print, user } from '@/vendor/icons';

export default {
  components: {
    AccountInfoItem,
    OrganizationList,
    OrganizationButton
  },
  props: {
    /* Object with keys:
     *   `type`
     *   `id` (`SUPPLIER` or `BUYER`)
     *   `name` (optional)
     */
    current: {
      type: Object,
      default: null,
      validator: ({ type, id }) => {
        return (
          type !== undefined &&
          id !== undefined &&
          [AccountType.SUPPLIER, AccountType.BUYER].includes(type)
        );
      }
    },
    // Object with keys: `email` (required) and `name` (optional)
    user: {
      type: Object,
      required: true,
      validator: ({ email }) => {
        return !!email;
      }
    },
    // Array of objects with fields: id, name
    suppliers: {
      type: Array,
      default: () => []
    },
    // Array of objects with fields: id, buyer_company
    buyers: {
      type: Array,
      default: () => []
    },
    buttonClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dropdownSize: '400px',
      showPopover: false
    };
  },
  computed: {
    isTest() {
      return this.currentOrganization?.is_test;
    },
    accountType() {
      return this.current ? this.current.type : null;
    },
    currentOrganizationIcon() {
      if (this.current) {
        if (this.accountType === AccountType.SUPPLIER) {
          return print;
        } else if (this.accountType === AccountType.BUYER) {
          return dollar;
        }
      }

      return user;
    },
    currentOrganization() {
      let accounts =
        this.accountType === AccountType.SUPPLIER
          ? this.suppliers
          : this.buyers;
      return accounts.find(a => a.id === this.current.id);
    },
    currentOrganizationName() {
      if (!this.current) {
        return this.user.name || this.user.email;
      } else if (this.current.name) {
        return this.current.name + ` (${this.current.id})`;
      } else {
        if (!this.currentOrganization) {
          throw Error(
            `Account with id ${this.current.id} not found in ${this.accountType} list.`
          );
        }

        return this.currentOrganization.display_name;
      }
    }
  },
  methods: {
    handleSelect({ type, id }) {
      this.showPopover = false;
      this.$emit('select', { id, type });
    },
    handleClickAccountButton() {
      this.showPopover = false;
      this.$emit('view-account');
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

.el-popover.organization-picker-popover {
  padding: 0;
  min-width: 300px;
  max-width: 80vw;
}
</style>
