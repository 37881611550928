<script>
import { getSupplierOrders } from '@/graphql/supplier/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],

  query() {
    return {
      query: getSupplierOrders,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        ...this.$store.getters['orders/pager']
      }
    };
  }
};
</script>
