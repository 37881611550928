<script>
import {
  approvePaymentMethod,
  approveRateClass,
  createCustomer,
  importCustomers,
  setCustomerFiles,
  setCustomerNotes,
  setCustomerTags,
  setInvoiceTerms,
  updateCustomer,
  updateCustomerBuyer
} from '@/graphql/customer/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        createCustomer(input) {
          this.refetchCache('getSupplierCustomers');
          return this.post(createCustomer, { input });
        },
        updateCustomer(customer, input) {
          return this.post(updateCustomer, {
            id: customer.id,
            input
          });
        },
        archiveCustomer(customer) {
          this.refetchCache('getSupplierCustomers');
          return this.post(updateCustomer, {
            id: customer.id,
            input: { is_active: false }
          });
        },
        updateCustomerBuyer(id, input) {
          this.refetchCache('getSupplierCustomers');
          return this.post(updateCustomerBuyer, { id, input });
        },
        setCustomerNotes(id, input) {
          this.refetchCache('getSupplierCustomers');
          return this.post(setCustomerNotes, { id, input });
        },
        setCustomerFiles(id, input) {
          this.refetchCache('getSupplierCustomers');
          return this.post(setCustomerFiles, { id, input });
        },
        setCustomerTags(id, input) {
          this.refetchCache('getSupplierCustomers');
          return this.post(setCustomerTags, { id, input });
        },
        // eslint-disable-next-line
        approveRateClass(buyer_id, rate_class) {
          return this.post(approveRateClass, { buyer_id, rate_class });
        },
        // eslint-disable-next-line
        approvePaymentMethod(buyer_id, payment_method_id) {
          return this.post(approvePaymentMethod, {
            buyer_id,
            payment_method_id
          });
        },
        importCustomers(file) {
          return this.post(importCustomers, { file });
        },
        setInvoiceTerms(id, input) {
          return this.post(setInvoiceTerms, { id, input });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
