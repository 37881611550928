export const ContactType = {
  PRIMARY: 'PRIMARY',
  BILLING: 'BILLING',
  ADVISOR: 'ADVISOR',
  CREATIVE: 'CREATIVE'
};

export const ContactNames = {
  PRIMARY: 'Primary Contact',
  BILLING: 'Billing Contact',
  ADVISOR: 'Advisor',
  CREATIVE: 'Creative Contact'
};
