<script>
import {
  toggleFeaturableEntity,
  toggleFeatureFlag
} from '@/graphql/admin/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        toggleFeaturableEntity(id, input) {
          this.post(toggleFeaturableEntity, { id, input });
        },
        toggleFeatureFlag(id, enabled) {
          this.post(toggleFeatureFlag, { id, enabled });
        }
      }
    };
  }
};
</script>
