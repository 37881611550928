<script>
import OrganizationListItem from './OrganizationListItem';
import { AccountType } from '@/constants';

export default {
  name: 'OrganizationList',
  functional: true,
  components: {
    OrganizationListItem
  },
  props: {
    /* Object with keys: `type` and `id`
     *
     * Type is either `SUPPLIER` or `BUYER`
     */
    current: {
      type: Object,
      default: null,
      validator: ({ type, id }) => {
        return (
          type !== undefined &&
          id !== undefined &&
          [AccountType.SUPPLIER, AccountType.BUYER].includes(type)
        );
      }
    },
    suppliers: {
      type: Array,
      default: () => []
    },
    buyers: {
      type: Array,
      default: () => []
    }
  },
  render(h, { props, listeners }) {
    const onSelectSupplier = supplier => {
      listeners.select({ type: AccountType.SUPPLIER, id: supplier.id });
    };
    const onSelectBuyer = buyer => {
      listeners.select({ type: AccountType.BUYER, id: buyer.id });
    };
    const isSelected = (type, id) =>
      props.current && props.current.type === type && props.current.id === id;

    let suppliersList = null;
    if (props.suppliers.length > 0) {
      const listItems = props.suppliers.map(supplier => (
        <OrganizationListItem
          key={supplier.id}
          name={supplier.display_name}
          active={isSelected(AccountType.SUPPLIER, supplier.id)}
          onClick={() => onSelectSupplier(supplier)}
        />
      ));

      suppliersList = (
        <div class="organization-group">
          <div class="divider" />
          <div class="group-label">Publisher Accounts</div>
          {listItems}
        </div>
      );
    }

    let buyersList = null;
    if (props.buyers.length > 0) {
      const listItems = props.buyers.map(buyer => (
        <OrganizationListItem
          key={buyer.id}
          name={buyer.buyer_company + ` (${buyer.id})`}
          active={isSelected(AccountType.BUYER, buyer.id)}
          onClick={() => onSelectBuyer(buyer)}
        />
      ));

      buyersList = (
        <div class="organization-group">
          <div class="divider" />
          <div class="group-label">Buyer Accounts</div>
          {listItems}
        </div>
      );
    }

    return (
      <div class="organization-list">
        {suppliersList}
        {buyersList}
      </div>
    );
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.organization-list {
  padding-bottom: 1.5em;
}

.organization-group + .organization-group {
  padding-top: 1em;
}

.divider {
  margin: 0 1.5em;
  border-bottom: 1px $color-gray solid;
}

.group-label {
  margin: 1em 1.15em 0.5em;
  font-weight: bold;
  font-size: 1.15em;
}
</style>
