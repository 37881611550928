export const RateType = {
  IMPRESSIONS: {
    name: 'Impressions',
    value: 'IMPRESSIONS',
    tooltip: ''
  },
  PER_DAY: {
    name: 'Per Day (CPD)',
    value: 'PER_DAY',
    tooltip:
      'Price equal to rate multiplied by the number of days booked. Adjusting quantity will book separate ads equal to the quantity.'
  },
  PER_QUANTITY: {
    name: 'Per Quantity',
    value: 'PER_QUANTITY',
    tooltip:
      'Price equal to rate multiplied by the quantity of units booked. Adjusting quantity in cart will book only a single ad.'
  },
  PER_INTERVAL: {
    name: 'Per Interval',
    value: 'PER_INTERVAL',
    tooltip:
      'Price equal to rate multiplied by the number of custom intervals booked. Adjusting quantity will book separate ads equal to the quantity.'
  },
  FLAT_RATE: {
    name: 'Flat Rate',
    value: 'FLAT_RATE',
    tooltip:
      'Price equal to flat rate and does not take into account the number of days or intervals booked. Adjusting quantity will book separate ads equal to the quantity.'
  }
};

export const NonContiguousRateTypes = [
  RateType.PER_DAY,
  RateType.PER_QUANTITY,
  RateType.FLAT_RATE
];

export const ContiguousRateTypes = [
  RateType.PER_DAY,
  RateType.PER_INTERVAL,
  RateType.PER_QUANTITY,
  RateType.FLAT_RATE
];

export const RateClass = {
  Standard: 'Standard',
  Local: 'Local',
  Super: 'Super'
};

export const RateClassLabel = {
  Standard: 'National Buyer',
  Local: 'Local Buyer',
  Super: 'Super',
  'Non Profit': 'Non Profit Buyer',
  'On Campus': 'On Campus Buyer'
};

export const RateClassesAllowed = {
  Local: ['Local', 'Super', 'Standard'],
  Super: ['Super', 'Standard'],
  Standard: ['Standard']
};

export const RateClasses = [
  {
    id: RateClass.Local,
    label: RateClassLabel[RateClass.Local]
  },
  {
    id: RateClass.Standard,
    label: RateClassLabel[RateClass.Standard]
  }
];
