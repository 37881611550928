<script>
import adminMenu from '@/navigation/admin';
import supplierMenu from '@/navigation/supplier';
import v4SupplierMenu from '@/navigation/v4-supplier';
import buyerMenu from '@/navigation/buyer';

export default {
  name: 'NavToggle',

  data() {
    return {
      showNav: 'admin',
      useV4Urls: false
    };
  },

  computed: {
    navMenu() {
      switch (this.showNav) {
        case 'admin':
          return adminMenu(this.$store, this.$router);

        case 'supplier':
          return supplierMenu(this.$store, this.$router);

        case 'buyer':
          return buyerMenu(this.$store, this.$router);

        case 'v4-supplier':
          return v4SupplierMenu(this.$store, this.$router);

        default:
          return [];
      }
    },

    toggleNav() {
      return this.$store.getters['auth/isSupplier'] ? 'supplier' : 'buyer';
    }
  },

  created() {
    // Toggle between Super Admin menu and Supplier Menu if user has permission
    if (this.$can('do_anything')) {
      this.$onKeyEvent('toggle-admin-nav', () => {
        this.showNav = this.showNav === 'admin' ? this.toggleNav : 'admin';
      });
    }

    if (this.$feature('v4-navigation')) {
      this.$onKeyEvent('admin-v4', () => {
        switch (this.showNav) {
          case 'supplier':
            this.showNav = 'v4-supplier';
            break;

          case 'v4-supplier':
            this.showNav = 'supplier';
            break;
        }
      });
    }
  }
};
</script>
