import {
  AccountType,
  AdShopTerms,
  CampaignType,
  OrganizationType
} from '@/constants';
import { Me } from '@/graphql/auth/me.graphql';
import {
  clearAuthorizationToken,
  isAuthorized,
  isUrlParamAuthorized
} from '@/utils/authorization';
import { apolloClient } from '@/vue-apollo';
import axios from 'axios';
import { cloneDeep, map } from 'lodash';
import moment from 'moment';

const state = {
  applicationLoadingPercent: 0,
  user: null,
  roles: [],
  permissions: [],
  features: [],

  buyers: [],
  suppliers: [],
  authTokens: [],

  currentAccount: null,
  supplier: null,
  buyer: null,

  signupInfo: null
};

// eslint-disable-next-line
function persistCurrentAccount(user_id, account) {
  // The current account data to persist to local storage
  const currentAccount = {
    user_id,
    account
  };

  // Persist the current account to local storage
  localStorage.setItem('current_account', JSON.stringify(currentAccount));
}

/*
 * Helper function to set current account. Used by login and switchAccounts
 */
function setCurrentAccount(state, account) {
  let newSupplier;
  let newBuyer;
  switch (account.type) {
    case AccountType.SUPPLIER:
      newSupplier = state.suppliers.find(
        supplier => supplier.id === account.id
      );

      if (newSupplier) {
        state.supplier = newSupplier;
        state.buyer = null;
        state.features = state.features.concat(
          map(state.supplier.features, 'name')
        );
      } else {
        account = null;
      }
      break;

    case AccountType.BUYER:
      newBuyer = state.buyers.find(buyer => buyer.id === account.id);

      if (newBuyer) {
        state.buyer = newBuyer;
        state.supplier = null;
        state.features = (state.features || []).concat(
          map(state.buyer.features, 'name')
        );
      } else {
        account = null;
      }
      break;

    case AccountType.ADMIN:
    default:
      // How to handle logging in as super admin (or no pub / buyer)?
      account = null;
      break;
  }

  state.currentAccount = account;

  // Make sure the next time the SPA loads, the current account is loaded too.
  persistCurrentAccount(state.user.id, account);

  return state.currentAccount;
}

function resolveAccount(state) {
  // Try to resolve the account type by looking at the page URLs
  let requiredAccountType = false;

  if (location.href.match(/\/v3\/(auth\/)?supplier/i)) {
    // This user does not have the correct account type
    if (state.suppliers.length === 0) {
      return false;
    }

    requiredAccountType = AccountType.SUPPLIER;
  } else if (location.href.match(/\/v3\/(auth\/)?buyer/i)) {
    // This user does not have the correct account type
    if (state.buyers.length === 0) {
      return false;
    }
    requiredAccountType = AccountType.BUYER;
  }

  // If same user, try to switch to the user's last account selected
  let previousAccount = JSON.parse(
    window.localStorage.getItem('current_account')
  );

  // If user has no active account when visiting the ad shop then switch to a BUYER account
  if (location.href.match(/\/v3\/shop/i) && !previousAccount) {
    if (!state.buyers.length > 0) {
      return false;
    }
    requiredAccountType = AccountType.BUYER;
  }

  // If the user had a previous account saved, set that as the current account
  if (previousAccount) {
    // If previous account was a different user account, this is invalid
    if (previousAccount.user_id !== state.user.id) {
      previousAccount = null;
    }

    // If the previous account is not the correct type for the current page,
    // then it is invalid
    if (
      requiredAccountType &&
      previousAccount.account.type !== requiredAccountType
    ) {
      previousAccount = null;
    }

    // If the account is valid, set it as the current account
    if (previousAccount) {
      setCurrentAccount(state, previousAccount.account);
    }
  }

  // If the account was not resolved by a previous account, lets log them in as their first
  // available account
  if (!state.currentAccount) {
    if (!requiredAccountType) {
      if (state.suppliers.length > 0) {
        requiredAccountType = AccountType.SUPPLIER;
      } else if (state.buyers.length > 0) {
        requiredAccountType = AccountType.BUYER;
      }
    }

    let resolvedAccount;

    if (requiredAccountType === AccountType.SUPPLIER) {
      resolvedAccount = {
        type: AccountType.SUPPLIER,
        id: state.suppliers[0].id
      };
    } else if (requiredAccountType === AccountType.BUYER) {
      resolvedAccount = {
        type: AccountType.BUYER,
        id: state.buyers[0].id
      };
    }

    // If the account was resolved, log them in otherwise return false
    if (resolvedAccount) {
      return setCurrentAccount(state, resolvedAccount) !== null;
    } else {
      return false;
    }
  }
}

const mutations = {
  logout(state) {
    window.localStorage.clear();
    apolloClient.resetStore();

    state.user = null;
    state.roles = [];
    state.permissions = [];

    state.suppliers = [];
    state.supplier = null;

    state.buyers = [];
    state.buyer = null;

    state.currentAccount = null;
  },
  login(state, payload) {
    state.user = payload;

    if (payload === null) {
      return;
    }

    state.roles = payload.roles;
    state.permissions = payload.permissions;
    state.authTokens = payload.authTokens;
    state.suppliers = payload.suppliers;
    state.buyers = payload.buyers;
    state.features = payload.features ? map(payload.features, 'name') : [];

    state.features = state.features.concat(map(payload.globalFeatures, 'name'));

    resolveAccount(state);

    // Add subscription feature flags
    if (state.supplier && state.supplier.adShopSubscription) {
      let subscriptionFeatures =
        state.supplier.adShopSubscription.subscriptionType?.features || [];

      for (let feature of subscriptionFeatures) {
        state.features.push(feature.id);
      }
    }
  },

  /**
   * Clears the cached authenticated user in the v4 platform
   *
   * @param state
   */
  clearAuthenticatedUser(state) {
    localStorage.setItem('authenticatedUser', null);
  },

  /**
   * Switch the Actively logged in account to either  Supplier, Buyer, or a Super Admin
   * account. This will unset all the data related to the currently logged in account from
   * the Vuex store and the Apollo GraphQL store. Then switch the SPA route to the home page
   * for that account.
   *
   *
   * TODO: For now, we are just reloading the SPA to avoid the complications of wiping out the old
   * data without reloading the active queries during a transition animation w/ vue
   *
   * @param state
   * @param account
   * @returns {*}
   */
  switchAccount(state, account) {
    let originalAccount = cloneDeep(state.currentAccount);

    // TODO: Since we dont want to cause GraphQL Query errors by switching the account info,
    // just persist the account then reload
    persistCurrentAccount(state.user.id, account);
    // account = setCurrentAccount(state, account);

    if (originalAccount && account.type === originalAccount.type) {
      if (account.id !== originalAccount.id) {
        window.location.reload();
      }
    } else {
      switch (account.type) {
        case AccountType.SUPPLIER:
          window.location = '/v3/supplier/dashboard';
          break;

        case AccountType.BUYER:
          window.location = '/v3/buyer/dashboard';
          break;

        case AccountType.ADMIN:
          window.location = '/v3/admin';
          break;
      }
    }

    return account;
  },
  updateLoadingPercent(state, percent) {
    state.applicationLoadingPercent = percent;
  },

  updateSignupInfo(state, input) {
    state.signupInfo = input;
  }
};

const actions = {
  async reset() {
    await apolloClient.resetStore();
  },

  async logRequest(store, request) {
    axios.get('/logRequest', { params: { path: request } });
  },

  async getUser({ commit }) {
    if (isAuthorized()) {
      try {
        const {
          data: { me }
        } = await apolloClient.query({
          query: Me,
          fetchPolicy: 'no-cache'
        });
        commit('login', me);
        commit('updateLoadingPercent', 100);
      } catch (error) {
        console.error(error);

        if (!isUrlParamAuthorized()) {
          commit('logout');
        }
      }
    }
  },
  async logout({ commit }) {
    try {
      await axios.get('/logout');
      commit('logout');
      clearAuthorizationToken();
    } catch (error) {
      throw Error(error);
    }
  },
  async switchAccount({ commit }, { type, id, name }) {
    commit('switchAccount', { type, id, name });
  }
};

const getters = {
  signupInfo: state => {
    return state.signupInfo;
  },
  isLoading: state => {
    return state.applicationLoadingPercent < 100;
  },
  loadingPercent: state => {
    return state.applicationLoadingPercent;
  },
  isAuthenticated: state => {
    return state.user !== null && +state.user.id !== 0;
  },
  hasARoleAssigned: state => {
    return state.roles.length > 0;
  },
  hasRole: state => slugs => {
    if (!Array.isArray(slugs)) {
      slugs = [slugs];
    }

    // Check if the user roles contains any of the specified roles
    return state.roles.some(role => {
      return slugs.some(slug => role.slug === slug);
    });
  },
  can: state => slugs => {
    if (!Array.isArray(slugs)) {
      slugs = [slugs];
    }

    // Check if the user permissions contains any of the specified permission
    return state.permissions.some(permission => {
      return slugs.some(
        slug => permission.slug === slug || permission.name === slug
      );
    });
  },
  isOrganizationType: state => type => {
    return state.supplier && state.supplier.organization_type === type;
  },
  isInfluencer: state => {
    return getters.isOrganizationType(state)(OrganizationType.INFLUENCER);
  },
  feature: state => name => {
    // Check if the account has the name feature enabled
    return state.features.indexOf(name) >= 0;
  },
  accountType: state => {
    return state.currentAccount && state.currentAccount.type;
  },
  areAdShopTermsAccepted: state => {
    if (state.supplier) {
      const acceptedDate = state.supplier.adShop.terms_accepted_date;
      return (
        acceptedDate && moment(AdShopTerms.date).isSameOrBefore(acceptedDate)
      );
    } else {
      return false;
    }
  },
  isSupplier: state => {
    return (
      state.currentAccount && state.currentAccount.type === AccountType.SUPPLIER
    );
  },
  isBuyer: state => {
    return (
      state.currentAccount && state.currentAccount.type === AccountType.BUYER
    );
  },
  isNationalBuyer: state => {
    return (
      state.buyer && state.buyer.campaign_type_id === CampaignType.NATIONAL
    );
  },
  isLocalBuyer: state => {
    return state.buyer && state.buyer.campaign_type_id === CampaignType.LOCAL;
  },
  isAdmin: (state, getters) => {
    return getters.hasRole('super_admin');
  },
  /**
   * An Authenticated guest is a user who is authenticated without a real user account. They have a valid
   * JWT Token which gives them Roles / Permissions, but no User / Buyer / Supplier account. This is used
   * for things like accessing a specific Draft Campaign information via a special URL including a JWT token
   * w/o having to authenticate themselves
   *
   * @param state
   * @returns {*|boolean}
   */
  isAuthenticatedGuest: state => {
    return state.user && +state.user.id === 0;
  },
  buyer: state => {
    return state.buyer;
  },
  adShop: state => {
    return state.supplier && state.supplier.adShop;
  },
  adShopSlug: state => {
    if (state.supplier && state.supplier.adShop) {
      return state.supplier.adShop.slug;
    } else {
      return null;
    }
  },
  campuses: state => {
    return state.supplier ? state.supplier.campuses : [];
  },
  supplier: state => {
    return state.supplier;
  },
  supplierId: state => {
    return (state.supplier && state.supplier.id) || null;
  },
  userAuthToken: state => {
    // Any token will do for User-based authentication
    return state.authTokens[0];
  },
  buyerId: state => {
    return (state.buyer && state.buyer.id) || null;
  },
  userId: state => {
    return (state.user && state.user.id) || null;
  },
  user: state => {
    return state.user;
  },
  email: state => {
    return (state.user && state.user.email) || null;
  },
  name: state => {
    return (state.user && state.user.name) || null;
  },
  systemSettings: state => {
    return (state.user && state.user.systemSettings) || null;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
