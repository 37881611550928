import Vue from 'vue';

// Authentication guards
export function authGuards(store, router) {
  // Auth guard
  //
  // Example:
  //   meta: {
  //     auth: true
  //   }
  router.beforeEach((to, from, next) => {
    // If any route requires authentication
    if (to.matched.some(record => record.meta.auth)) {
      // Redirect to login if not authenticated
      if (
        !store.getters['auth/isAuthenticated'] &&
        !store.getters['auth/isAuthenticatedGuest']
      ) {
        console.error('User not authenticated');
        next({
          path: '/auth/login',
          query: {
            redirect: process.env.BASE_URL.replace(/\/$/, '') + to.fullPath
          }
        });
      }

      // Redirect to account settings if user has not filled out their name
      const user = store.getters['auth/user'];
      if (
        store.getters['auth/isSupplier'] &&
        (!user.first_name || !user.last_name)
      ) {
        if (to.name !== 'supplier.settings.account') {
          next({ name: 'supplier.settings.account' });
        }
      }
    }

    next();
  });

  // Roles and Permissions guard
  //
  // Example:
  //   meta: {
  //    roles: ['super_admin']
  //   }
  router.beforeEach((to, from, next) => {
    // Workaround for first page load
    const throwError = message => {
      if (from.path === '/') {
        console.error(message);
        next({ path: '/' });
      } else {
        throw new Error(message);
      }
    };

    for (let record of to.matched) {
      if (!record.meta) {
        continue;
      }

      if (record.meta.type) {
        if (record.meta.type !== store.getters['auth/accountType']) {
          Vue.prototype.$message.error(
            `You are logged into a ${
              store.getters['auth/accountType']
            } account and cannot access ${
              record.meta.type
            } pages: ${record.name || record.path}`
          );
          console.error('Record', record, 'Store', store.getters['auth']);
          throwError(
            'User does not have an account type of: ' + record.meta.type
          );
        }
      }

      if (record.meta.roles) {
        if (!store.getters['auth/hasRole'](record.meta.roles)) {
          Vue.prototype.$message.error(
            'You do not have a role that can access that page'
          );
          throwError(
            'User does not have necessary role: ' + record.meta.roles.join(', ')
          );
        }
      }

      if (record.meta.permissions) {
        if (!store.getters['auth/can'](record.meta.permissions)) {
          Vue.prototype.$message.error(
            'You do not have permission to access that page'
          );
          throwError(
            'User does not have permissions: ' +
              record.meta.permissions.join(', ')
          );
        }
      }

      if (record.meta.feature) {
        if (!store.getters['auth/feature'](record.meta.feature)) {
          Vue.prototype.$message.error('This feature is not yet available.');
          throwError('User does not have feature: ' + record.meta.feature);
        }
      }
      if (record.meta.restrict && record.meta.restrict.value()) {
        let errorMessage =
          record.meta.restrict.message ||
          'The page requested has been restricted for access';
        Vue.prototype.$message.error(errorMessage);
        throwError(errorMessage);
      }
    }

    next();
  });

  /**
   * Redirects all Influencer supplier accounts to the MVA, except in the case they are trying to print a PDF
   */
  router.beforeEach((to, from, next) => {
    if (
      store.getters['auth/isInfluencer'] &&
      !store.getters['auth/isAdmin'] &&
      !location.href.match(/\/(print|reports)/)
    ) {
      console.log('User is Influencer. Redirecting to MVA');
      location.href = window._env.API_URL + '/mva';
    } else {
      next();
    }
  });
}
