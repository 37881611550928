/**
 * Checks the object for a type of the particualr constant value
 *
 * @param typedObject
 * @param typeConstant

 * @returns {Boolean}
 */
export function hasType(typedObject, typeConstant) {
  let hasType = false;
  if (typedObject.hasOwnProperty('typeEdges')) {
    for (let type of typedObject.typeEdges) {
      if (type.type.value === typeConstant) {
        hasType = true;
        break;
      }
    }
  }
  return hasType;
}
