<script>
import { getMediakit } from '@/graphql/mediakit/queries.graphql';
import { findPath, findRootPath } from '@/utils/helpers';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    mediumId: { type: String, default: null },
    propertyId: { type: String, default: null },
    collectionId: { type: String, default: null },
    productId: { type: String, default: null }
  },

  query() {
    return {
      query: getMediakit,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      },
      update: this.onResults
    };
  },

  computed: {
    mediums() {
      return this.resultData && this.resultData.mediums;
    },

    medium() {
      if (!this.mediums) return null;

      if (this.mediumId) {
        return findPath(this.mediums, [], { id: this.mediumId });
      }

      if (this.propertyId) {
        return findRootPath(this.mediums, ['properties'], {
          id: this.propertyId
        });
      }

      if (this.collectionId) {
        return findRootPath(this.mediums, ['properties', 'collections'], {
          id: this.collectionId
        });
      }

      if (this.productId) {
        return findRootPath(
          this.mediums,
          ['properties', 'collections', 'products'],
          { id: this.productId }
        );
      }

      return null;
    }
  },

  created() {
    this.$on('data', () => {
      this.$emit('medium', this.medium);
    });
  },

  methods: {
    onResults({ data: { supplier } }) {
      let results = {
        mediums: null,
        medium: null,
        property: null,
        collection: null,
        product: null
      };

      if (supplier) {
        results.mediums = supplier.mediums;

        if (this.mediumId) {
          results.medium = findPath(supplier.mediums, [], {
            id: this.mediumId
          });
        }

        if (this.propertyId) {
          results.property = findPath(supplier.mediums, ['properties'], {
            id: this.propertyId
          });
        }

        if (this.collectionId) {
          results.collection = findPath(
            supplier.mediums,
            ['properties', 'collections'],
            {
              id: this.collectionId
            }
          );
        }

        if (this.productId) {
          results.product = findPath(
            supplier.mediums,
            ['properties', 'collections', 'products'],
            { id: this.productId }
          );
        }
      }

      return results;
    }
  }
};
</script>
