// Setup fingerprinting / session
import { getFingerprint } from '@/utils/session';

import Vue from 'vue';

// Vuex / Vue Router
import { sync } from 'vuex-router-sync';
import router from '@/router';
import { addGuards } from '@/router/guards';
import store from '@/store';

// Icons
import { IconPlugin } from '@/vendor/icons/plugin';

// Element UI
import { ElementUIComponents } from '@/vendor/element-ui';

// Calendar
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

// Custom Utilities
import { VueFilters } from '@/utils/filters';
import { cookie, VueCookie } from '@/utils/cookie';
import KeyEventListener from '@/utils/keyEvents';
import EmitBubble from '@/utils/emitBubble';
import ClickOutside from '@/vendor/click-outside';
import RenderMergeStrategy from '@/utils/mergeStrategies/renderMergeStrategy';

// Directives
import Mask from '@/directives/mask';
import '@/directives/scrollto';
import '@/directives/infiniteScroll';

// Axios HTTP
import { configureAxios } from '@/axios';

// Moment JS Business Days functions
import './vendor/momentjs-business';

// Analytics
import VueGtag from 'vue-gtag';

// Global Components
import '@/components/Core';

// Service Worker
import './registerServiceWorker';

// The App
import LoadingApp from '@/components/Core/Loading/LoadingApp';
import App from '@/App';
import { apolloProvider } from './vue-apollo';

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === 'production';

// Cookies
Vue.use(VueCookie);

// IconPlugin
Vue.use(IconPlugin);

Vue.use(RenderMergeStrategy);

new Vue({
  el: '#loading-app',
  components: { LoadingApp },
  template: '<LoadingApp/>'
});

// Element UI
Vue.use(ElementUIComponents);

// Calendar
Vue.use(VCalendar, {
  popoverDirection: 'top',
  popoverVisibility: 'focus',
  popoverKeepVisibleOnInput: true
});

// Filters
Vue.use(VueFilters);

// Click Outside Event
Vue.use(ClickOutside);

// Emit Event w/ Bubble
Vue.use(EmitBubble);

// Key Event Listener
Vue.use(KeyEventListener);

// Google Analytics
if (process.env.VUE_APP_GA_TOKEN) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GA_TOKEN }
    },
    router
  );
}

// Sync Vuex with router
sync(store, router);

// Add router guards
addGuards(store, router);

// Configure Axios
configureAxios();

// Directives
Vue.directive('mask', Mask);

// Startup
async function boot() {
  // Always set the fingerprint in the cookie if not set
  if (!cookie('fingerprint')) {
    // This will set the cookie itself. (any time this is run it should update the cookie so we always have the most
    // accurate fingerprint)
    await getFingerprint();
  }

  router.afterEach(to => {
    store.dispatch('auth/logRequest', location.origin + '/v3' + to.fullPath);
  });

  // Get User info
  await store.dispatch('auth/getUser');

  new Vue({
    el: '#app',
    apolloProvider,
    router,
    store,
    directives: {},
    components: { App },
    template: '<App/>'
  });
}

boot();
