<script>
import { getSupplierLineItems } from '@/graphql/order/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  query() {
    return {
      query: getSupplierLineItems,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        ...this.$store.getters['orderLineItems/pager']
      }
    };
  }
};
</script>
