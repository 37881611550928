<script>
import { getSupplierDashboard } from '@/graphql/supplier/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';
import { AdStatusValues } from '@/constants';

export default {
  mixins: [QueryHelper],
  props: {
    nationalAdsPager: {
      type: Object,
      default: () => ({ perPage: 0, page: 1 })
    }
  },
  query(props) {
    return {
      query: getSupplierDashboard,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        pageNationalAds: props.nationalAdsPager.page,
        perPageNationalAds: props.nationalAdsPager.perPage,
        adsFilter: {
          national: true,
          status: {
            '!=': [
              AdStatusValues.CANCELED,
              AdStatusValues.VERIFIED,
              AdStatusValues.PENDING_VERIFICATION_APPROVAL
            ]
          }
        }
      }
    };
  }
};
</script>
