import { isString, merge } from 'lodash';
import moment from 'moment';
import { momentTz } from '@/utils/date';

export const filters = {
  alpha,
  alphanumeric,
  boolean,
  currency,
  date,
  dateAtTime,
  dateDb,
  datetime,
  datetimeDb,
  deunderscore,
  filterType,
  float,
  integer,
  iso8601,
  lowercase,
  month,
  number,
  numberInteger,
  percent,
  phone,
  pluralize,
  rank,
  round,
  roundFloat,
  shortCurrency,
  shortNumber,
  shortSize,
  slug,
  stripTags,
  substr,
  time,
  uppercase,
  validEmail,
  validPassword,
  week,
  year,
  zipcode
};

function filterType(value, type) {
  return filters[type](value);
}

function validMomentOrNull(date, format) {
  if (date.isValid()) {
    return format ? date.format(format) : date;
  }

  return null;
}

export function validEmail(rule, value, callback) {
  if (value) {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test(value)) {
      callback(new Error('Please enter a valid Email Address'));
    }
  }
  callback();
}

export function validPassword(rule, value, callback) {
  if (!value) {
    callback(new Error('Please input a password'));
  } else if (value.length < 8) {
    callback(new Error('Passwords must be at least 8 characters in length'));
  } else {
    callback();
  }
}

export const VueFilters = {
  install(Vue) {
    for (const filter in filters) {
      Vue.filter(filter, filters[filter]);
    }
  }
};

function assertString(value, filterName) {
  if (!isString(value)) {
    throw new Error(`cannot format non-string to ${filterName}`);
  }
}

/*
 * Filter Implementations
 */
export function boolean(value) {
  return value && value !== '0' && value !== 'false' && value !== 'null'
    ? 'Yes'
    : 'No';
}

export function zipcode(value) {
  assertString(value, 'zipcode');
  let zip = value.replace(/[^\d]/g, '');

  return zip.substr(0, 5) + (zip.length > 5 ? '-' + zip.substr(5, 9) : '');
}

export function phone(value) {
  assertString(value, 'phone');

  let input = value.replace(/[^\d]/g, '').split('');
  let phone = '';

  const startsWithOne = input.length > 0 && input[0] === '1';
  const shift = startsWithOne ? 1 : 0;

  input.map((number, index) => {
    switch (index) {
      case shift:
        phone += '(';
        break;
      case shift + 3:
        phone += ') ';
        break;
      case shift + 6:
        phone += '-';
        break;
      case shift + 10:
        phone += ' x';
        break;
    }
    if (index === 0 && number === '1') {
      phone += '+1 ';
    } else {
      phone += number;
    }
  });

  if (value === '+1 (') {
    return '';
  }

  return phone;
}

export function percent(value, options) {
  return `${number(+value * 100, options)}%`;
}

export function rank(value, options = {}) {
  switch (value) {
    case 1:
      return `${options.preText || ''}${options.lowText ||
        'Low'}${options.postText || ''}`;
    case 2:
      return `${options.preText || ''}${options.mediumText ||
        'Medium'}${options.postText || ''}`;
    case 3:
      return `${options.preText || ''}${options.highText ||
        'High'}${options.postText || ''}`;
    default:
      return '--';
  }
}

export function stripTags(value) {
  assertString(value, 'stripTags');

  let tmp = document.createElement('DIV');
  tmp.innerHTML = value;
  return tmp.textContent || tmp.innerText || '';
}

export function iso8601(value) {
  if (!value || value === '0000-00-00 00:00:00') {
    return '';
  }

  // Correct for timezone initialized strings
  if (typeof value === 'string' && value.match(/T/)) {
    value = momentTz(value);
  }

  // For now, we are not casting to UTC, so pass true for keepOffset
  value = validMomentOrNull(moment(value));

  return value ? value.toISOString(true) : null;
}

export function dateDb(value) {
  return datetime(value, 'Y-MM-DD');
}

export function datetimeDb(value) {
  return datetime(value, 'Y-MM-DD HH:mm:ss');
}

export function deunderscore(value) {
  return value.split('_').join(' ');
}

export function date(value, format) {
  if (!value || value === '0000-00-00 00:00:00' || value === '0000-00-00') {
    return '';
  }

  // Correct for timezone initialized strings
  if (typeof value === 'string' && value.match(/T/)) {
    value = momentTz(value);
  }

  return validMomentOrNull(moment(value), format || 'MM/DD/Y');
}

export function datetime(value, format) {
  if (!value || value === '0000-00-00 00:00:00') {
    return '';
  }

  if (value instanceof Date) {
    let year = value.getFullYear();
    let month = value.getMonth() + 1;
    let date = value.getDate();
    let hour = value.getHours();
    let hour12 = ((hour + 11) % 12) + 1;
    let minute = value.getMinutes();
    let second = value.getSeconds();

    // Our moment JS / Standard date format conversion to a date format
    // This is here for optimization! Date pickers are expensive when rendering
    return format
      .replace(/YY/g, (year + '').substr(2))
      .replace(/Y/g, year)
      .replace(/MM/g, month < 10 ? '0' + month : month)
      .replace(/M/g, month)
      .replace(/DD/g, date < 10 ? '0' + date : date)
      .replace(/D/g, date)
      .replace(/HH/g, hour < 10 ? '0' + hour : hour)
      .replace(/H/g, hour)
      .replace(/hh/g, hour12 < 10 ? '0' + hour12 : hour12)
      .replace(/h/g, hour12)
      .replace(/(mm|ii)/g, minute < 10 ? '0' + minute : minute)
      .replace(/[mi]/g, minute)
      .replace(/ss/g, second < 10 ? '0' + second : second)
      .replace(/s/g, second)
      .replace(/\[at]/g, '@')
      .replace(/a/g, hour > 11 ? 'pm' : 'am');
  }

  // Correct for timezone initialized strings
  if (typeof value === 'string' && value.match(/T/)) {
    value = momentTz(value);
  }

  return validMomentOrNull(moment(value), format || 'MM/DD/Y h:mm:ss a');
}

export function dateAtTime(value, format) {
  if (!value || value === '0000-00-00 00:00:00') {
    return '';
  }

  // Correct for timezone initialized strings
  if (typeof value === 'string' && value.match(/T/)) {
    value = momentTz(value);
  }

  return validMomentOrNull(moment(value), format || 'MM/DD/YYYY [at] h:mm a');
}

export function week(value) {
  return 'Week ' + date(value, 'W YYYY');
}

export function month(value) {
  return date(value, 'MMMM YYYY');
}

export function year(value) {
  return date(value, 'YYYY');
}

export function time(value, format) {
  // Correct for timezone initialized strings
  if (typeof value === 'string' && value.match(/T/)) {
    value = momentTz(value);
  }

  return validMomentOrNull(moment(value), format || 'h:mm:ss a');
}

export function slug(value, separator) {
  assertString(value, 'slug');

  return value
    .replace(/[\s_-]/g, separator || '-')
    .replace(/[^a-z0-9-_]/gi, '')
    .toLowerCase();
}

export function pluralize(word, amount) {
  return amount > 1 || amount === 0 ? `${word}s` : word;
}

export function currency(value, options) {
  options = merge(
    {
      neg: '-',
      pos: '',
      left: '$',
      right: '',
      allowE: false,
      allowEmpty: false,
      abs: true,
      fromCents: false
    },
    options
  );

  if (options.short) {
    return shortCurrency(value, options);
  }

  let n = value;

  if (options.fromCents) {
    n = (+n / 100).toFixed(2);
  }

  n = n + '';

  let num = number(n, options);

  // cast to string to accomadate VueJS string objects
  if (typeof num === 'object' ? num.length === 0 : num === '') {
    return '';
  }

  let plusMinus = n.match(new RegExp('^' + options.neg))
    ? options.neg
    : options.pos;

  return plusMinus + options.left + num + options.right;
}

export function integer(value, options) {
  options = merge(
    {
      thousands: '',
      precision: 0,
      allowE: false
    },
    options
  );

  return number(value, options);
}

export function float(value, options) {
  if (value === '∞') return value;

  options = merge(
    {
      thousands: '',
      precision: false,
      allowE: false
    },
    options
  );

  return +number(value, options);
}

export function number(value, options) {
  if (value === '∞') return value;

  if (typeof options === 'number') {
    options = { precision: options };
  }

  options = {
    precision: 2,
    minPrecision: null,
    thousands: ',',
    decimal: '.',
    allowEmpty: false,
    nullDecimal: false,
    allowE: true,
    abs: false,
    min: false,
    max: false,
    allowMaxOverride: false,
    ...options
  };

  if (options.short) {
    return shortNumber(value, options);
  }

  if (!options.allowE) {
    if ((value + '').match(/[eE]-\d+/)) {
      value = 0;
    }
  }

  let n = (value + '').replace(/[^0-9+\-eE.]/g, '');

  if (!n) {
    return options.allowEmpty ? (n === '0' ? 0 : '') : options.min || 0;
  }

  if (!options.abs && n === '-') {
    return n;
  }

  let prec =
    options.precision === false
      ? false
      : !isFinite(+options.precision)
      ? 0
      : Math.abs(options.precision);
  let minPrec = options.minPrecision === null ? prec : options.minPrecision;

  // Capture any 0's after the decimal to make sure we don't erase them
  let trailingZeroes = n.match(/\.([1-9]*)(0+)$/);

  if (trailingZeroes && trailingZeroes[2].length > minPrec) {
    minPrec = Math.min(
      trailingZeroes[1].length + trailingZeroes[2].length,
      options.precision
    );
  }

  n = +parseFloat(n);

  if (!isFinite(n)) n = 0;

  // If number is the next order of magnitude greater than the max, allow changing last digit in input
  // (eg: if the previous value is 1 and the user types 2, value is 12, but if the max is less than 2 digits (ie: 10)
  // then let the value be overridden to 2
  if (options.allowMaxOverride) {
    if (options.max && ('' + options.max).length < ('' + n).length) {
      n = +('' + n).substr(1);
    }
  }

  if (options.min !== false && n < options.min) {
    return number(options.min, options);
  }

  if (options.max !== false && n > options.max) {
    return number(options.max);
  }

  if (options.abs) n = Math.abs(n);

  // Round to the maximum precision
  n = prec !== false ? roundFloat(n, prec) : n;

  let [integerPart, fractionalPart = ''] = ('' + n).split('.');

  // Add thousands separator
  if (integerPart.length > 3) {
    integerPart = integerPart.replace(
      /\B(?=(?:\d{3})+(?!\d))/g,
      options.thousands
    );
  }

  // Make sure we add trailing 0's to match the minimum precision
  while (fractionalPart.length < minPrec) {
    fractionalPart += '0';
  }

  // Only set the decimal if it already exists
  if (!('' + value).match(/\./)) options.nullDecimal = false;

  // Create the final decimal number
  return (
    integerPart +
    (fractionalPart || options.nullDecimal ? options.decimal : '') +
    fractionalPart
  );
}

export function numberInteger(value) {
  return number(value, 0);
}

export function round(value, p) {
  let k = Math.pow(10, p);
  return '' + Math.round((+value || 0) * k) / k;
}

export function roundFloat(value, p) {
  return round(float(value), p);
}

export function lowercase(value) {
  assertString(value, 'lowercase');
  return value.toLowerCase();
}

export function uppercase(value) {
  assertString(value, 'uppercase');
  return value.toUpperCase();
}

export function substr(value, start, length) {
  assertString(value, 'substr');
  return value.substr(start, length);
}

export function shortCurrency(value, options) {
  return '$' + shortNumber(value, options);
}

export function shortNumber(value, options) {
  let shorts = [
    { pow: 3, unit: 'K' },
    { pow: 6, unit: 'M' },
    { pow: 9, unit: 'B' },
    { pow: 12, unit: 'T' }
  ];

  let n = Math.round(+value);
  let short = null;

  for (let s in shorts) {
    let max = Math.pow(10, shorts[s].pow);

    // This number is smaller than the next jump, so we already have a short modifier
    if (max > n) {
      break;
    }

    short = shorts[s];
  }

  if (short) {
    n = n / Math.pow(10, short.pow);
    if (options && options.round) {
      return n + short.unit;
    }
    return n.toFixed(n > 100 ? 0 : 1) + short.unit;
  }

  return n;
}

export function shortSize(value, options) {
  let powers = [
    { pow: 0, unit: 'B' },
    { pow: 10, unit: 'KB' },
    { pow: 20, unit: 'MB' },
    { pow: 30, unit: 'GB' },
    { pow: 40, unit: 'TB' },
    { pow: 50, unit: 'PB' }
  ];

  let n = Math.round(+value);
  let power;

  for (power of powers) {
    let max = Math.pow(2, power.pow + 10);

    // If the number is smaller than 1 of the next unit, use the current unit
    if (max > n) {
      break;
    }
  }

  let div = Math.pow(2, power.pow);

  return Math.round(n / div) + ' ' + power.unit;
}

export function alphanumeric(value) {
  assertString(value, 'alphanumeric');
  return value.replace(/[^a-z0-9]/gi, '');
}

export function alpha(value) {
  assertString(value, 'alpha');
  return value.replace(/[^a-z]/gi, '');
}
