<template>
  <transition name="fade" appear>
    <div
      v-show="!isClosing"
      class="page-overlay z-50"
      @click.self="onClickOutside"
    >
      <transition
        :name="transitionName"
        appear
        leave-class="fadeUp"
        @after-leave="$emit('close')"
      >
        <div v-show="!isClosing" class="page-overlay-content" :style="style">
          <div v-if="$slots.header" class="page-overlay-header">
            <slot name="header" />
          </div>

          <div class="page-overlay-body">
            <slot />
          </div>

          <div v-if="$slots.footer" class="page-overlay-footer">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeOnEscape: {
      type: Boolean,
      default: true
    },
    location: { type: String, default: null },
    cover: {
      type: String,
      default: '90%'
    },
    maxCover: {
      type: String,
      default() {
        // Set the standard maxCover width for sidebar page overlays
        return ['', true].includes(this.$options.propsData.sidebar)
          ? '40em'
          : '60em';
      }
    },
    manualClose: Boolean,
    sidebar: Boolean
  },

  data() {
    return {
      isClosing: false
    };
  },

  computed: {
    transitionName() {
      switch (this.location) {
        case 'top':
          return this.isClosing ? 'fadeUp' : 'fadeDown';

        case 'bottom':
          return this.isClosing ? 'fadeDown' : 'fadeUp';

        case 'left':
          return 'fadeLeft';

        case 'right':
        default:
          return 'fadeRight';
      }
    },

    style() {
      switch (this.location) {
        case 'top':
          return {
            top: 0,
            width: '100%',
            height: this.cover,
            'max-height': this.maxCover
          };

        case 'bottom':
          return {
            bottom: 0,
            width: '100%',
            height: this.cover,
            'max-height': this.maxCover
          };

        case 'left':
          return {
            left: 0,
            height: '100%',
            width: this.cover,
            'max-width': this.maxCover
          };

        case 'right':
        default:
          return {
            right: 0,
            height: '100%',
            width: this.cover,
            'max-width': this.maxCover
          };
      }
    }
  },

  mounted() {
    this.$onKeyEvent('escape', () => {
      if (this.closeOnEscape) {
        this.isClosing = true;
      }

      this.$emit('escape');
    });
  },

  methods: {
    onClickOutside() {
      this.$emit('click-outside');
      if (!this.manualClose) {
        this.isClosing = true;
      }
    },
    close() {
      this.isClosing = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  margin-top: $header-height;
  margin-bottom: -$header-height;

  .page-overlay-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    padding-bottom: $header-height;

    .page-overlay-header {
      flex-grow: 0;
    }

    .page-overlay-body {
      flex-grow: 1;
      overflow: auto;
      padding-bottom: 1.5em;
    }

    .page-overlay-footer {
      flex-grow: 0;
      z-index: 20;
    }
  }
}
</style>
