import {
  arrowsCw as resumeIcon,
  attention,
  cancel,
  ok,
  playCircleO,
  trashEmpty
} from '@/vendor/icons';

export const CampaignType = {
  NATIONAL: 'NATIONAL',
  LOCAL: 'LOCAL'
};

export const CampaignTypeSelectInfo = [
  {
    name: 'Flytedesk National Orders',
    value: 'National'
  },
  {
    name: 'Ad Shop Orders',
    value: 'Local'
  }
];

export const CampaignStatus = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  COMPLETE: 'COMPLETE',
  PENDING_APPROVAL: 'PENDING_APPROVAL'
};

export const CampaignStatusInfo = {
  ACTIVE: {
    icon: playCircleO,
    class: 'text-blue hover:text-dark-blue',
    name: 'Active'
  },
  CANCEL: {
    icon: cancel,
    class: 'text-red hover:text-dark-red',
    name: 'Cancel'
  },
  CANCELED: {
    icon: cancel,
    class: 'text-red hover:text-dark-red',
    name: 'Canceled'
  },
  COMPLETE: {
    icon: ok,
    class: 'text-green hover:text-dark-green',
    name: 'Complete'
  },
  DELETE: {
    icon: cancel,
    class: 'text-dark-gray hover:text-dark-red',
    name: 'Delete'
  },
  PENDING_APPROVAL: {
    icon: attention,
    class: 'text-yellow hover:text-dark-yellow',
    name: 'Pending Approval'
  },
  RESUME: {
    icon: resumeIcon,
    class: 'text-blue hover:text-dark-blue',
    name: 'Resume'
  }
};

export const CampaignStatusInfoSuper = {
  ...CampaignStatusInfo,
  DELETE: {
    icon: trashEmpty,
    class: 'text-red hover:text-dark-red',
    name: 'Delete'
  }
};

export const CampaignActions = {
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  RESUME: 'RESUME'
};

export const ProposalSummarySections = [
  {
    id: 'overview',
    href: '#overview',
    label: 'Overview'
  },
  {
    id: 'media',
    href: '#media',
    label: 'Media'
  },
  {
    id: 'audience',
    href: '#audience',
    label: 'Audience'
  },
  {
    id: 'dates',
    href: '#dates',
    label: 'Dates'
  },
  {
    id: 'terms',
    href: '#terms',
    label: 'Fine Print'
  }
];
