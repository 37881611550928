<script>
import {
  addCustomerRep,
  removeCustomerRep
} from '@/graphql/customer/mutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        // eslint-disable-next-line
        addCustomerRep(customer, user_id) {
          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateCustomer: {
                __typename: 'CustomerMutation',
                addCustomerRep: true,
                customer: {
                  __typename: 'Customer',
                  id: customer.id,
                  buyerReps: [
                    ...customer.buyerReps,
                    {
                      id: user_id,
                      name: null,
                      first_name: null,
                      last_name: null,
                      email: null,
                      phone: null,
                      created_at: null,
                      profilePicture: null,
                      __typename: 'User'
                    }
                  ]
                }
              }
            }
          };

          return this.post(addCustomerRep, { id: customer.id, user_id });
        },
        // eslint-disable-next-line
        removeCustomerRep(id, user_id) {
          return this.post(removeCustomerRep, { id, user_id });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
