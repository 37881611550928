import Vue from 'vue';
import Vuex from 'vuex';

import ads from './modules/ads';
import adShop from './modules/adShop';
import audience from './modules/audience';
import auth from './modules/auth';
import common from './modules/common';
import customers from './modules/customers';
import financeTrackerAds from './modules/financeTrackerAds';
import invoices from './modules/invoices';
import nprogress from './modules/nprogress';
import orderLineItems from './modules/orderLineItems';
import orders from './modules/orders';
import verifications from './modules/verifications';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ads,
    adShop,
    audience,
    auth,
    common,
    customers,
    financeTrackerAds,
    invoices,
    nprogress,
    orderLineItems,
    orders,
    verifications
  }
});

export default store;

// Add the global $can helper function for easy permission checking
Vue.prototype.$can = slugs => {
  return store.getters['auth/can'](slugs);
};

// Add the global $feature helper function for easy feature enabled checking
Vue.prototype.$feature = feature => {
  return store.getters['auth/feature'](feature);
};
