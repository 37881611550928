<script>
import Vue from 'vue';
import { renderless } from '@/utils/helpers';

export default Vue.component('tween', {
  props: {
    value: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 500
    },
    interval: {
      type: Number,
      default: 16 // about 60 fps
    },
    threshold: {
      type: Number,
      default: 0.99
    }
  },

  data() {
    return {
      startValue: 0,
      tweeningValue: 0
    };
  },

  computed: {
    increment() {
      return (this.interval * (this.value - this.startValue)) / this.duration;
    },

    isAtTweenLimit() {
      if (this.startValue > this.value) {
        return this.tweeningValue < this.value;
      } else {
        return this.tweeningValue > this.value;
      }
    }
  },

  watch: {
    value(newValue, oldValue) {
      this.startValue = oldValue;
      this.tweenValue();
    }
  },

  mounted() {
    this.tweenValue();
  },

  methods: {
    tweenValue() {
      if (!this.increment) return;

      this.tweeningValue += this.increment;

      if (this.isAtTweenLimit) {
        this.tweeningValue = this.value;
      } else {
        setTimeout(this.tweenValue, this.interval);
      }
    }
  },

  render(c) {
    return renderless.call(this, c, {
      value: this.tweeningValue
    });
  }
});
</script>
