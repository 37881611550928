import { objectMap } from '@/utils/helpers';
import { cancel, clock, docText, tag } from '@/vendor/icons';

export const OrderLineItemType = {
  AD: 'AD',
  DISCOUNT: 'DISCOUNT'
};

export const OrderSubLineItemType = {
  DISCOUNT: {
    label: 'Discount',
    value: 'DISCOUNT'
  },
  FEE: {
    label: 'Fee',
    value: 'FEE'
  },
  ADJUSTMENT: {
    label: 'Adjustment',
    value: 'ADJUSTMENT'
  }
};

export const OrderLineItemStatus = {
  APPLIED: {
    label: 'Applied',
    icon: {
      icon: tag,
      class: 'text-green hover:text-dark-green',
      name: 'Applied'
    }
  },
  CANCELED: {
    label: 'Canceled',
    icon: {
      icon: cancel,
      class: 'text-red hover:text-dark-red',
      name: 'Canceled'
    }
  },
  INVOICE_SCHEDULED: {
    label: 'Invoice Scheduled',
    icon: {
      icon: clock,
      class: 'text-dark-silver hover:gunmetal',
      name: 'Invoice Scheduled'
    }
  },
  INVOICED: {
    label: 'Invoiced',
    icon: {
      icon: docText,
      class: 'text-blue hover:text-dark-blue',
      name: 'Invoiced'
    }
  },
  PARTIALLY_APPLIED: {
    label: 'Partially Applied',
    icon: {
      icon: tag,
      class: 'text-dark-yellow hover:text-yellow',
      name: 'Partially Applied'
    }
  },
  PARTIALLY_INVOICED: {
    label: 'Partially Invoiced',
    icon: {
      icon: docText,
      class: 'text-yellow hover:text-dark-yellow',
      name: 'Partially Invoiced'
    }
  },
  UNAPPLIED: {
    label: 'Unapplied',
    icon: {
      icon: tag,
      class: 'text-gray hover:text-dark-gray',
      name: 'Unapplied'
    }
  },
  UNINVOICED: {
    label: 'Uninvoiced',
    icon: {
      icon: docText,
      class: 'text-gray hover:text-dark-silver',
      name: 'Uninvoiced'
    }
  }
};

export const OrderLineItemStatusKeys = objectMap(OrderLineItemStatus, key => [
  key,
  key
]);

export const OrderLineItemStatusLabels = objectMap(
  OrderLineItemStatus,
  (key, value) => [key, value.label]
);

export const OrderLineItemStatusIcons = objectMap(
  OrderLineItemStatus,
  (key, value) => [key, value.icon]
);

export const BillableLineItemStatuses = [
  OrderLineItemStatusKeys.APPLIED,
  OrderLineItemStatusKeys.INVOICED,
  OrderLineItemStatusKeys.INVOICE_SCHEDULED,
  OrderLineItemStatusKeys.PARTIALLY_INVOICED,
  OrderLineItemStatusKeys.PARTIALLY_APPLIED,
  OrderLineItemStatusKeys.UNAPPLIED,
  OrderLineItemStatusKeys.UNINVOICED
];

/**
 * Describes which statuses can flow to which other statuses
 */
export const OrderLineItemStatusWorkflow = {
  [OrderLineItemStatusKeys.CANCELED]: [
    OrderLineItemStatusKeys.INVOICED,
    OrderLineItemStatusKeys.PARTIALLY_INVOICED,
    OrderLineItemStatusKeys.UNINVOICED
  ],
  [OrderLineItemStatusKeys.INVOICED]: [
    OrderLineItemStatusKeys.CANCELED,
    OrderLineItemStatusKeys.INVOICED,
    OrderLineItemStatusKeys.PARTIALLY_INVOICED,
    OrderLineItemStatusKeys.UNINVOICED
  ],
  [OrderLineItemStatusKeys.PARTIALLY_INVOICED]: [
    OrderLineItemStatusKeys.CANCELED,
    OrderLineItemStatusKeys.INVOICED,
    OrderLineItemStatusKeys.UNINVOICED
  ],
  [OrderLineItemStatusKeys.UNINVOICED]: [
    OrderLineItemStatusKeys.CANCELED,
    OrderLineItemStatusKeys.INVOICED,
    OrderLineItemStatusKeys.PARTIALLY_INVOICED
  ]
};
