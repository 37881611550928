<script>
import { getBuyerAds } from '@/graphql/buyer/getBuyerAds.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    pager: {
      type: Object,
      required: true
    }
  },

  query(props) {
    return {
      query: getBuyerAds,
      variables: {
        buyer_id: this.$store.getters['auth/buyerId'],
        ...props.pager
      }
    };
  }
};
</script>
