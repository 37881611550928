<script>
import {
  addInvoicePayment,
  createSubLineItem,
  deleteSubLineItem,
  exportOrderLineItems,
  lineItemsSelectByFilter,
  lineItemsSelectByIds,
  supplierCreateLineItemsInvoice,
  updateLineItem,
  voidInvoicePayment
} from '@/graphql/billing/supplierBillingMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

import { remove } from '@/utils/helpers';

const paymentFields = {
  paymentMethod: {
    id: String,
    name: String
  },
  lineItems: { id: String },
  amount: Number,
  payment_date: Date,
  description: String
};

export default {
  mixins: [MutationHelper],
  props: {
    supplierId: {
      type: String,
      default: null
    },
    paginatorInfo: {
      type: Object,
      default: null
    },
    displayedLineItems: {
      type: Array,
      default: () => []
    },
    selectionsSummary: {
      type: Object,
      default: () => {}
    }
  },

  mutation() {
    return {
      mutations: {
        async addInvoicePayment(invoice, input) {
          await this.post(addInvoicePayment, { id: invoice.id, input });
        },
        async voidInvoicePayment(invoice, transaction) {
          await this.post(voidInvoicePayment, {
            invoice_id: invoice.id,
            transaction_id: transaction.id
          });
        },
        updateLineItem(orderId, originalData, newData) {
          // Note currently only allowing status field update here
          // DO NOT modify supplier_price / buyer_price here. Use the Sub Line Items
          let input = this.castFields(newData, {
            status: String
          });

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateOrder: {
                __typename: 'OrderMutation',
                mutateOrderLineItem: {
                  __typename: 'OrderLineItemMutation',
                  update: true,
                  orderLineItem: {
                    __typename: 'OrderLineItem',
                    ...originalData,
                    ...newData
                  }
                }
              }
            }
          };

          return this.post(updateLineItem, {
            orderId,
            id: originalData.id,
            input
          });
        },
        createInvoiceForLineItems(input) {
          this.refetchCache('getSupplierInvoices');

          return this.post(supplierCreateLineItemsInvoice, {
            input,
            ...this.$store.getters['orderLineItems/pager']
          });
        },
        createSubLineItem(order, lineItem, input) {
          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateOrder: {
                __typename: 'OrderMutation',
                mutateOrderLineItem: {
                  __typename: 'OrderLineItemMutation',
                  createSubLineItem: true,
                  orderLineItem: {
                    __typename: 'OrderLineItem',
                    ...lineItem,
                    supplier_price: +lineItem.supplier_price + +input.amount,
                    subLineItems: [
                      ...lineItem.subLineItems,
                      {
                        __typename: 'OrderSubLineItem',
                        id: 'new-sub-line-item',
                        is_hidden: false,
                        created_at: lineItem.created_at,
                        user: null,
                        ...input
                      }
                    ]
                  }
                }
              }
            }
          };

          let variables = {
            order_id: order.id,
            line_item_id: lineItem.id,
            input
          };

          // Sometimes as a Super Admin we will not be logged into the correct supplier (ie: Campaign Tracker)
          if (order.supplier_id && this.$can('takeover_suppliers')) {
            variables.supplier_id = order.supplier_id;
          }

          return this.post(createSubLineItem, variables);
        },
        deleteSubLineItem(order, lineItem, subLineItem) {
          const subLineItems = lineItem.subLineItems;

          remove(subLineItems, { id: subLineItem.id });

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateOrder: {
                __typename: 'OrderMutation',
                mutateOrderLineItem: {
                  __typename: 'OrderLineItemMutation',
                  deleteSubLineItem: true,
                  orderLineItem: {
                    __typename: 'OrderLineItem',
                    ...lineItem,
                    subLineItems
                  }
                }
              }
            }
          };

          let variables = {
            order_id: order.id,
            line_item_id: lineItem.id,
            id: subLineItem.id
          };

          // Sometimes as a Super Admin we will not be logged into the correct supplier (ie: Campaign Tracker)
          if (order.supplier_id && this.$can('takeover_suppliers')) {
            variables.supplier_id = order.supplier_id;
          }

          return this.post(deleteSubLineItem, variables);
        },
        clearSelections() {
          return this.post(lineItemsSelectByFilter, {
            filter: {},
            select: false
          });
        },
        selectByFilter(select, filter) {
          filter = filter || this.pager.filter;

          this.setSelectionOptimisticResponse(select, null, filter);

          return this.post(lineItemsSelectByFilter, { select, filter });
        },
        selectByIds(select, ids) {
          this.setSelectionOptimisticResponse(select, ids);

          return this.post(lineItemsSelectByIds, {
            ids,
            select
          });
        },
        exportOrderLineItems(filter) {
          filter = filter || this.pager.filter;

          return this.post(exportOrderLineItems, { filter });
        }
      },
      variables: {
        supplier_id: this.supplierId || this.$store.getters['auth/supplierId']
      }
    };
  },
  computed: {
    pager() {
      return this.$store.getters['orderLineItems/pager'];
    }
  },
  methods: {
    setSelectionOptimisticResponse(select, ids, filter) {
      let total_selected;
      let affectedOrderLineItems;

      if (filter) {
        total_selected = Math.min(
          this.paginatorInfo.total,
          (this.selectionsSummary ? this.selectionsSummary.total_selected : 0) +
            (select ? this.paginatorInfo.total : -this.paginatorInfo.total)
        );

        affectedOrderLineItems = [...this.displayedLineItems];
      } else {
        total_selected =
          (this.selectionsSummary ? this.selectionsSummary.total_selected : 0) +
          (select ? ids.length : -ids.length);

        affectedOrderLineItems = [
          ...this.displayedLineItems.filter(li => ids.includes(li.id))
        ];
      }

      affectedOrderLineItems = affectedOrderLineItems.map(li => {
        return {
          ...li,
          is_selected: select,
          __typename: 'OrderLineItem'
        };
      });

      this.optimisticResponse = {
        mutateSupplier: {
          mutateOrderLineItemTracker: {
            selectByIds: true,
            selectByFilter: true,
            affectedOrderLineItems,
            orderLineItemTracker: {
              id: 'supplier-line-items',
              warnings: [],
              lineItemsSelectionSummary: {
                ...this.selectionsSummary,
                total_selected,
                total_items: total_selected,
                __typename: 'LineItemsSelectionSummary'
              },
              __typename: 'OrderLineItemTracker'
            },
            __typename: 'OrderLineItemTrackerMutation'
          },
          __typename: 'SupplierMutation'
        }
      };
    }
  }
};
</script>
