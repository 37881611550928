import {
  attention,
  basket,
  cancel,
  chartLine,
  disable,
  ok
} from '@/vendor/icons';

export const SkuStatus = {
  AVAILABLE: {
    id: 'AVAILABLE',
    icon: ok,
    class: 'text-blue hover:text-dark-blue',
    name: 'Available'
  },
  UNAVAILABLE: {
    id: 'UNAVAILABLE',
    icon: cancel,
    class: 'text-dark-silver hover:text-silver',
    name: 'Unavailable'
  },
  SOLD_OUT: {
    id: 'SOLD_OUT',
    icon: disable,
    class: 'text-red hover:text-dark-red',
    name: 'Sold Out'
  },
  FULL_RESERVED: {
    id: 'FULL_RESERVED',
    icon: basket,
    class: 'text-red hover:text-dark-red',
    name: 'Fully Reserved'
  },
  OVERBOOKED: {
    id: 'OVERBOOKED',
    icon: attention,
    class: 'text-red hover:text-dark-red',
    name: 'Overbooked'
  },
  OVER_SELECTED: {
    id: 'OVER_SELECTED',
    icon: attention,
    class: 'text-yellow hover:text-dark-yellow',
    name: 'Selected Quantity Unavailable'
  },
  OVERBOOKED_IMPRESSIONS: {
    id: 'OVERBOOKED_IMPRESSIONS',
    icon: chartLine,
    class: 'text-orange hover:text-dark-orange',
    name: 'Booked Impressions Unavailable'
  },

  OVER_SELECTED_IMPRESSIONS: {
    id: 'OVER_SELECTED_IMPRESSIONS',
    icon: chartLine,
    class: 'text-yellow hover:text-dark-yellow',
    name: 'Selected Impressions Unavailable'
  }
};
