<template>
  <div class="loader-grid flex flex-wrap items-stretch">
    <div
      v-for="n in count"
      :key="`loader-grid-${n}`"
      class="block p-3"
      :class="columnClass"
    >
      <loader-box :width="itemWidth" :height="itemHeight" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { LoaderBox } from '@/components/Core/Loaders';

export default Vue.component('loaderGrid', {
  components: {
    LoaderBox
  },
  props: {
    columnClass: {
      type: [String, Object],
      default: 'is-narrow'
    },
    count: {
      type: Number,
      default: 6
    },
    itemWidth: {
      type: [Number, String],
      default: 15
    },
    itemHeight: {
      type: Number,
      default: 15
    }
  }
});
</script>
