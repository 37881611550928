<script>
import { getMediakitUnits } from '@/graphql/mediakit/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  query() {
    return {
      query: getMediakitUnits,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
