import PlacementRoutes from '@/routes/supplier/mediakit/placement';
import TheFullPageLayout from '@/components/Core/Layout/TheFullPageLayout';

export default rootPath => [
  {
    path: rootPath,
    component: TheFullPageLayout,
    children: [...PlacementRoutes(rootPath + '/supplier/mediakit')]
  }
];
