<template>
  <div>
    <el-dialog
      v-if="takeoverType"
      :title="dialogTitle"
      :visible="true"
      width="35%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      @open="focusInput"
      @close="blurInput"
    >
      <takeover-input ref="input" :takeover-type="takeoverType" />
    </el-dialog>
  </div>
</template>

<script>
import TakeoverInput from '@/components/Core/TakeoverInput.vue';

export default {
  components: {
    TakeoverInput
  },
  data() {
    return {
      takeoverType: false
    };
  },
  computed: {
    dialogTitle() {
      return `Takeover ${
        this.takeoverType === 'buyer' ? 'Advertiser' : 'Supplier'
      }`;
    }
  },
  created() {
    this.$onKeyEvent('takeover-buyer', () => {
      if (this.$can('takeover_buyers')) {
        this.takeoverType = 'buyer';
        this.focusInput();
      }
    });
    this.$onKeyEvent('takeover-supplier', () => {
      if (this.$can('takeover_suppliers')) {
        this.takeoverType = 'supplier';
        this.focusInput();
      }
    });
  },

  methods: {
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.input) {
          this.$refs.input.focus();
        }
      });
    },

    blurInput() {
      this.takeoverType = false;

      if (this.$refs.input) {
        this.$refs.input.clear();
        this.$refs.input.blur();
      }
    }
  }
};
</script>
