import store from '@/store';

const TheAdShopLayout = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/Layout/TheAdShopLayout'
  );
const PageStorefront = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/PageStorefront/PageStorefront'
  );
const PageStoreProduct = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/PageStoreProduct/PageStoreProduct'
  );

const LayoutCart = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/Cart/LayoutCart'
  );
const PageCart = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/Cart/PageCart'
  );
const PagePayment = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/Cart/PagePayment'
  );
const PageSubmitted = () =>
  import(
    /* webpackChunkName: 'AdShop', webpackPrefetch: true */ '@/components/AdShop/Cart/PageSubmitted'
  );

export default rootPath => {
  return {
    path: `${rootPath}/:shopSlug/:cart_id?`,
    component: TheAdShopLayout,
    beforeEnter(to, from, next) {
      store.commit('adShop/setSlug', to.params.shopSlug);
      next();
    },
    children: [
      {
        path: '',
        name: 'adShop',
        component: PageStorefront,
        meta: {
          title: 'Ad Shop'
        }
      },
      {
        path: 'product/:product_id',
        name: 'adShop.product',
        component: PageStoreProduct,
        meta: {
          title: 'Ad Shop Product'
        }
      },
      {
        path: 'cart',
        component: LayoutCart,
        children: [
          {
            path: '',
            name: 'adShop.cart',
            component: PageCart,
            meta: {
              title: 'Cart'
            }
          },
          {
            path: 'payment',
            name: 'adShop.payment',
            component: PagePayment,
            meta: {
              title: 'Payment'
            }
          },
          {
            path: 'submitted',
            name: 'adShop.orderSubmitted',
            component: PageSubmitted,
            meta: {
              title: 'Order Submitted'
            }
          }
        ]
      }
    ]
  };
};
