import { authGuards } from './auth';
import { errorGuard } from './error';
import { NProgressGuard } from './nprogress';
import { headGuards } from './head';

export function addGuards(store, router) {
  NProgressGuard(store, router);
  authGuards(store, router);
  headGuards(router);
  errorGuard(store, router);
}
