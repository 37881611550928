<script>
import { getTakeoverSuppliers } from '@/graphql/supplier/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  query() {
    return {
      query: getTakeoverSuppliers
    };
  }
};
</script>
