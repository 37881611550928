<script>
import { getReachTypes } from '@/graphql/mediakit/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  name: 'ReachTypesQuery',

  mixins: [QueryHelper],

  query() {
    return {
      query: getReachTypes,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
