import pager from '../includes/pager';

export const state = {
  pager: {
    preference: {},
    sortCampuses: [{ column: 'name', order: 'ascending' }],
    filterCampuses: {},
    perPageCampuses: 10,
    pageCampuses: 1,
    filterAvailableCampuses: {},
    sortAvailableCampuses: [{ column: 'data.enrollment', order: 'descending' }],
    perPageAvailableCampuses: 10,
    pageAvailableCampuses: 1
  }
};

const mutations = {
  setPager(state, value) {
    state.pager = { ...state.pager, ...value };
  }
};

const actions = {
  ...pager.actions
};

const getters = {
  ...pager.getters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
