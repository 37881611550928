<script>
import {
  inviteUserToBuyerAccount,
  rejectBuyerMemberRequest,
  removeBuyerMember,
  requestBuyerMemberAccess,
  sendBuyerMemberInvite,
  setBuyerContacts,
  setBuyerMemberRole
} from '@/graphql/buyer/teamMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        removeBuyerMember(user_id) {
          this.refetchCache('getBuyerTeam');
          this.post(removeBuyerMember, { user_id });
        },
        rejectBuyerMemberRequest(invite_id) {
          this.refetchCache('getBuyerTeam');
          this.post(rejectBuyerMemberRequest, { invite_id });
        },
        requestBuyerMemberAccess(email) {
          this.post(requestBuyerMemberAccess, { email });
        },
        setBuyerContacts(user_id, contactTypes) {
          this.refetchCache('getBuyerTeam');
          this.post(setBuyerContacts, { user_id, contactTypes });
        },
        sendBuyerMemberInvite(input) {
          this.refetchCache('getBuyerTeam');
          this.post(sendBuyerMemberInvite, { input });
        },
        async inviteUserToBuyerAccount(buyer_id, email, name) {
          return this.post(inviteUserToBuyerAccount, { buyer_id, email, name });
        },
        setBuyerMemberRole(user_id, role) {
          this.refetchCache('getBuyerTeam');
          this.post(setBuyerMemberRole, { user_id, role });
        }
      },
      variables: {
        buyer_id: this.$store.getters['auth/buyerId'],
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
