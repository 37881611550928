<template>
  <div class="loading-page has-content-centered">
    <slot>
      <template v-if="!failed">
        <loading-icon class="icon-xl"></loading-icon>
      </template>

      <div v-else class="loading-page-failed">
        <slot name="failed">
          <div class="has-error">
            <icon :icon="attention" />
            <slot name="failed-text">
              Page was not found
            </slot>
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
import LoadingIcon from './LoadingIcon';
import { attention } from '@/vendor/icons';

export default {
  name: 'LoadingPage',

  components: {
    LoadingIcon
  },
  props: {
    failed: Boolean
  },

  data() {
    return { attention };
  }
};
</script>

<style lang="scss" type="text/scss" scoped>
@import '~@/scss/_variables';

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: white;
}

.loading-page-failed {
  .has-error {
    color: $color-red;
  }
}
</style>
