<script>
import { getBuyerCustomer } from '@/graphql/customer/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    supplierId: {
      type: String,
      required: true
    }
  },

  query() {
    return {
      query: getBuyerCustomer,
      variables: {
        buyerId: this.$store.getters['auth/buyerId'],
        supplierId: this.supplierId
      }
    };
  }
};
</script>
