<script>
import { getCampaignReps } from '@/graphql/campaign/queries.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  name: 'CampaignRepsQuery',

  mixins: [QueryHelper],

  props: {
    buyerId: {
      type: [String, Number],
      required: true
    },
    campaignId: {
      type: String,
      required: true
    }
  },

  query() {
    return {
      query: getCampaignReps,
      variables: {
        supplierId: this.$store.getters['auth/supplierId'],
        buyerId: this.buyerId,
        campaignId: this.campaignId
      }
    };
  }
};
</script>
