<script>
import { MutationHelper } from '@/components/Core/Helpers';
import {
  completeRegistration,
  createBuyerUser,
  createSupplierUser,
  updateUserAccount,
  updateUserNotificationType,
  updateUserSetting
} from '@/graphql/user/mutations.graphql';
import store from '@/store';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        completeRegistration(id, input) {
          return this.post(completeRegistration, { id, input });
        },
        createBuyerUser(input) {
          return this.post(createBuyerUser, { input });
        },
        createSupplierUser(input) {
          return this.post(createSupplierUser, { input });
        },
        async updateUserAccount(input) {
          await this.post(updateUserAccount, { input });

          const user = store.getters['auth/user'];
          if (
            input.first_name &&
            input.last_name &&
            (!user.first_name || !user.last_name)
          ) {
            window.location.reload();
          }
        },
        updateUserSetting(slug, value) {
          return this.post(updateUserSetting, { slug, value });
        },
        updateUserNotificationType(userNotificationType, input) {
          this.optimisticResponse = {
            mutateUser: {
              __typename: 'UserMutation',
              mutateUserNotificationType: {
                __typename: 'UserNotificationTypeMutation',
                update: true,
                userNotificationType: {
                  __typename: 'UserNotificationType',
                  ...userNotificationType,
                  ...input
                }
              }
            }
          };

          return this.post(updateUserNotificationType, {
            id: userNotificationType.id,
            input
          });
        }
      }
    };
  }
};
</script>
