<script>
import {
  createProperty,
  deleteProperty,
  updateProperty
} from '@/graphql/mediakit/propertyMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],
  props: {
    mediumId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isDeleting: false
    };
  },

  mutation() {
    return {
      mutations: {
        createProperty(input) {
          input = this.mapInput(input);
          input.medium_id = this.mediumId;
          return this.post(createProperty, { input });
        },
        updateProperty(property, input) {
          input = this.mapInput(input);

          this.optimisticResponse = {
            mutateSupplier: {
              __typename: 'SupplierMutation',
              mutateMediakit: {
                __typename: 'MediakitMutation',
                mutateProperty: {
                  __typename: 'PropertyMutation',
                  update: true,
                  property: {
                    __typename: 'Property',
                    ...property,
                    ...input,
                    scheduleType: {
                      __typename: 'ScheduleType',
                      ...property.scheduleType,
                      ...input.scheduleType
                    },
                    typeEdges: property.typeEdges
                  }
                }
              }
            }
          };

          return this.post(updateProperty, { id: property.id, input });
        },
        async deleteProperty({ id }) {
          this.isDeleting = true;
          try {
            await this.post(deleteProperty, { id });
          } catch (e) {
            this.isDeleting = false;
            throw e;
          }
        }
      },
      variables: {
        supplier_id: this.supplierId
      }
    };
  },

  computed: {
    supplierId() {
      return this.$store.getters['auth/supplierId'];
    }
  },

  methods: {
    mapInput(property) {
      const fields = {
        medium_id: String,
        name: String,
        is_enabled: Boolean,
        typeEdges: {
          _map: type => type.type.value
        },
        are_photos_visible: Boolean,
        has_dimensions: Boolean,
        is_digital_content: Boolean,
        website_url: String,
        digital_seen_on: String,
        min_impressions: Number,
        min_days: Number,
        blocked_content: String,
        schedule_type_id: String,
        scheduleType: {
          id: String
        }
      };

      if (this.$can('manage_super_objects')) {
        fields.is_super = Boolean;
      }

      return this.castFields(property, fields);
    }
  }
};
</script>
