/**
 * Matches a string or RegExp to a DOM node by class or node name
 *
 * @param node
 * @param name
 * @returns boolean
 */
export function nodeMatches(node, name) {
  let search = name instanceof RegExp ? name : new RegExp(name, 'i');

  return (
    node.nodeName.match(search) ||
    (node.className && node.className.match && node.className.match(search))
  );
}

/**
 * Recursively search down a DOM tree to find a child node matching the nodeName to
 * a regular expression
 *
 * @param node
 * @param name
 * @returns {*}
 */
export function findChildNode(node, name) {
  if (!node) return false;

  if (nodeMatches(node, name)) {
    return node;
  }

  for (let child of node.children) {
    let found = findChildNode(child, name);

    if (found) {
      return found;
    }
  }

  return false;
}

/**
 * Find a parent up the DOM by matching the nodeName or className to a regular expression
 *
 * @param node
 * @param name
 * @returns {*}
 */
export function findParentNode(node, name) {
  if (!node) return false;

  if (nodeMatches(node, name)) {
    return node;
  }

  return findParentNode(node.parentNode, name);
}

/**
 * Selects all Text inside of the given element
 * @param el
 */
export function selectText(el) {
  if (el) {
    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

/**
 * Copies text to the clipboard
 *
 * @param str
 */
export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

/**
 * Prints a rendered HTML element (ie: a rendered vue component given its root element)
 * in the browser's default print utility
 *
 * @param el
 * @param options
 */
export function printElement(el, options) {
  return new Promise(resolve => {
    let o = {
      width: 800,
      height: 900,
      left: 0,
      top: 0,
      toolbar: 0,
      scrollbars: 0,
      status: 0,
      ...options
    };

    // Get all stylesheets HTML
    let stylesHtml = '';

    for (const node of [
      ...document.querySelectorAll('link[rel="stylesheet"], style')
    ]) {
      stylesHtml += node.outerHTML;
    }

    // Open the print window
    const printWindow = window.open(
      '',
      '',
      `left=${o.left},top=${o.top},width=${o.width},height=${o.height},toolbar=${o.toolbar},scrollbars=${o.scrollbars},status=${o.status}`
    );

    printWindow.document.write(
      `<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
  <div id="app">
    ${el.innerHTML}
  </div>
  </body>
</html>`
    );

    printWindow.onafterprint = () => {
      printWindow.close();
      resolve();
    };

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  });
}

export function onAllImagesLoaded(cb) {
  // Get all images in the DOM
  const images = Array.from(document.querySelectorAll('img'));

  // If there are no images, directly add the class
  if (images.length === 0) {
    return cb();
  }

  // Counter to keep track of loaded images
  let loadedImagesCount = 0;

  // Function to check if all images are loaded
  const checkAllImagesLoaded = () => {
    loadedImagesCount++;
    if (loadedImagesCount === images.length) {
      cb();
    }
  };

  // Attach load event listener to each image
  images.forEach(img => {
    // If the image is already loaded (from browser cache), directly increase the counter
    if (img.complete) {
      checkAllImagesLoaded();
    } else {
      // Otherwise, listen for the load event
      img.addEventListener('load', checkAllImagesLoaded);
    }
  });
}
