<template>
  <div
    :class="borderClass"
    class="flex items-center justify-center cursor-pointer"
    @click="$emit('click', $event)"
  >
    <div class="relative">
      <span
        :class="{
          [`bg-${circleColor}`]:
            circleColor && isActive && !transparentBackground,
          [`w-${size} h-${size}`]: size
        }"
        class="inline-block rounded-circle flex justify-center items-center"
      >
        <icon
          v-if="icon"
          :icon="icon"
          :class="{
            [`text-${color}`]: color && isActive,
            'text-dark-silver': !isActive
          }"
          class="text-xl"
        />
      </span>
      <div
        v-if="count"
        :class="[
          {
            [`bg-${color}`]: isActive && color && !transparentBackground,
            [`bg-dark-silver`]: !isActive && !transparentBackground
          },
          counterClass
        ]"
        class="flex justify-center items-center min-w-1 h-4 rounded-xxl"
      >
        <span class="text-xs" :class="textClass">{{ count }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('notificationIcon', {
  props: {
    size: {
      type: Number,
      default: 8
    },
    color: {
      type: String,
      default: 'text-blue'
    },
    transparentBackground: Boolean,
    counterBottom: Boolean,
    circleColor: { type: String, default: null },
    icon: { type: Object, default: null },
    count: { type: [Number, String], default: null },
    isActive: Boolean,
    borderSide: { type: String, default: null }
  },
  computed: {
    counterClass() {
      if (this.counterBottom) {
        return '';
      } else {
        return 'absolute -mr-1 -mt-1 px-1 right-0 top-0';
      }
    },
    textClass() {
      if (this.transparentBackground) {
        return this.isActive ? 'text-blue' : 'text-dark-silver';
      } else {
        return 'text-white';
      }
    },
    borderClass() {
      if (this.isActive && this.borderSide) {
        return `border-${this.borderSide} border-2 border-${this.color} p-2`;
      } else if (!this.isActive && this.borderSide) {
        return `border-${this.borderSide} border-2 border-transparent p-2`;
      }
      return null;
    }
  }
});
</script>
