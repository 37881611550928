<template>
  <div class="overflow-hidden">
    <loader-box
      v-if="isLoading"
      height="100%"
      class="h-10"
      :class="selectClass"
    />
    <el-select
      v-else
      autocomplete="off"
      :allow-create="allowCreate"
      :clearable="clearable"
      :class="[selectClass, { 'read-only': readOnly }]"
      :disabled="isDisabled"
      :multiple="!singular"
      :value="value"
      :value-key="valueByObject || valueById ? 'id' : 'value'"
      filterable
      :collapse-tags="!expandTags"
      popper-append-to-body
      :placeholder="placeholder"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <el-option
        v-for="option in options"
        :key="
          `filter-status-icon-${option.name ||
            option.id ||
            option.value ||
            option[labelKey]}`
        "
        :label="option.label || option.name || option.value || option[labelKey]"
        :value="optionValue(option)"
        :class="optionClass"
        :title="'title' + option.label"
      >
        <slot :option="option" />
        <status-icon v-if="!hideIcons && option.icon" :icon="option" inline />
      </el-option>

      <template v-slot:tag-text="{ item }">
        <slot name="tag-text">
          <div :title="item.currentLabel">{{ item.currentLabel }}</div>
        </slot>
      </template>
    </el-select>
  </div>
</template>

<script>
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

export default {
  components: {
    StatusIcon
  },
  props: {
    allowCreate: Boolean,
    expandTags: Boolean,
    hideIcons: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,
    readOnly: Boolean,
    singular: Boolean,
    valueByObject: Boolean,
    valueByKey: Boolean,
    valueById: Boolean,
    clearable: Boolean,
    selectClass: {
      type: String,
      default: ''
    },
    value: {
      type: [Object, Array, String, Number],
      default: () => []
    },
    labelKey: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    optionClass: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'All Statuses'
    }
  },
  methods: {
    optionValue(option) {
      let value = this.valueByObject
        ? option
        : this.valueByKey
        ? option.key
        : this.valueById
        ? option.id
        : option.value;

      if (!value && value !== 0 && value !== '0') {
        value = option.name;
      }

      return value;
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

/deep/ .el-select.read-only {
  pointer-events: none;

  .el-input__inner {
    border-color: transparent;
    background: transparent;

    &:active,
    &:focus {
      border-color: $color-gray;
      background: white;
    }
  }

  .el-input__suffix {
    display: none;
    opacity: 0;
    transition: all 0.3s;
  }
}
</style>
