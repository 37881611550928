<script>
import {
  approveNationalVerification,
  bulkRescheduleAds,
  bulkUpdateCreative,
  bulkUpdatePricingForAds,
  bulkTogglePauseForAds,
  bulkUpdateAds,
  campaignTrackerSelectByFilter,
  campaignTrackerSelectByIds,
  cancelAd,
  importAdUpdates,
  rejectNationalVerification,
  rescheduleAd,
  resumeCanceledAd,
  saveVerification,
  setAdNote,
  setAdTags,
  setOrderNote,
  updateCampaignTrackerAd,
  updateCampaignTrackerLineItem
} from '@/graphql/campaign/campaignTrackerMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';
import { formatFormResponseInput } from '@/utils/helpers';
import uuid from 'uuid';

export default {
  mixins: [MutationHelper],

  props: {
    selectionGroupId: {
      type: String,
      default: uuid
    },
    displayedAds: {
      type: Array,
      default: () => []
    },
    selectionsSummary: {
      type: Object,
      default: () => {}
    },
    pager: {
      type: Object,
      default: () => {}
    }
  },

  mutation() {
    return {
      mutations: {
        setAdNote(id, note) {
          return this.post(setAdNote, { id, note });
        },
        setOrderNote(id, note) {
          return this.post(setOrderNote, { id, note });
        },
        setTags(id, tags) {
          this.refetchCache('getNationalAds');
          return this.post(setAdTags, { id, tags });
        },
        bulkUpdateCreative(input, merge) {
          input = {
            ...input,
            append: true,
            formResponse: formatFormResponseInput(input.formResponse, merge)
          };

          return this.post(bulkUpdateCreative, { input });
        },
        bulkRescheduleAds(input) {
          return this.post(bulkRescheduleAds, { input });
        },
        bulkUpdatePricingForAds(input) {
          return this.post(bulkUpdatePricingForAds, { input });
        },
        bulkTogglePauseForAds(isPaused) {
          return this.post(bulkTogglePauseForAds, { isPaused });
        },
        bulkUpdateAds(input) {
          return this.post(bulkUpdateAds, { input });
        },
        clearSelections() {
          this.refetchCache('getNationalAds');
          this.setSelectionOptimisticResponse(0);
          return this.post(campaignTrackerSelectByFilter, {
            filter: {},
            select: false
          });
        },
        selectByFilter(select, filter) {
          let totalSelected = Math.min(
            this.pager.total,
            this.selectionsSummary.total_selected +
              (select ? this.pager.total : -this.pager.total)
          );

          this.setSelectionOptimisticResponse(totalSelected, select);

          return this.post(campaignTrackerSelectByFilter, { select, filter });
        },
        selectByIds(select, ids) {
          let totalSelected =
            this.selectionsSummary.total_selected +
            (select ? ids.length : -ids.length);

          this.setSelectionOptimisticResponse(totalSelected, select, ids);

          return this.post(campaignTrackerSelectByIds, {
            groupId: this.selectionGroupId,
            ids,
            select
          });
        },
        approveVerification(ad) {
          return this.post(approveNationalVerification, { id: ad.id });
        },
        cancelAd(ad, cancelLineItem) {
          return this.post(cancelAd, { id: ad.id, cancelLineItem });
        },
        rejectVerification(ad, note) {
          return this.post(rejectNationalVerification, { id: ad.id, note });
        },
        rescheduleAd(ad, input) {
          return this.post(rescheduleAd, { id: ad.id, input });
        },
        resumeCanceledAd(ad, status) {
          return this.post(resumeCanceledAd, { id: ad.id, status });
        },
        saveVerification(ad, input) {
          input = {
            ...input,
            formResponse: formatFormResponseInput(input.formResponse)
          };

          return this.post(saveVerification, {
            ad_id: ad.id,
            input
          });
        },
        updateLineItem(orderId, id, input) {
          return this.post(updateCampaignTrackerLineItem, {
            orderId,
            id,
            input
          });
        },
        updateAd(ad, input) {
          return this.post(updateCampaignTrackerAd, {
            supplierId: ad.supplier.id,
            id: ad.id,
            input
          });
        },
        importAdUpdates(file) {
          return this.post(importAdUpdates, { file });
        }
      }
    };
  },

  methods: {
    setSelectionOptimisticResponse(total_selected, select, ids) {
      let affectedAds = [];
      let start_date = null;
      let end_date = null;

      for (let ad of this.displayedAds) {
        let is_selected = select;

        if (ids) {
          is_selected = ids.includes(ad.id) ? select : ad.is_selected;
        }

        affectedAds.push({
          ...ad,
          is_selected,
          __typename: 'Ad'
        });

        if (is_selected) {
          start_date =
            ad.start_date < start_date || !start_date
              ? ad.start_date
              : start_date;
          end_date =
            ad.end_date > end_date || !end_date ? ad.end_date : end_date;
        }
      }

      this.optimisticResponse = {
        mutateCampaignTracker: {
          selectByIds: true,
          selectByFilter: true,
          affectedAds,
          nationalCampaignTracker: {
            id: 'national-campaign-tracker',
            nationalAdsSelectionsSummary: {
              total_selected,
              start_date,
              end_date,
              __typename: 'NationalAdsSelectionsSummary'
            },
            __typename: 'NationalCampaignTracker'
          },
          __typename: 'CampaignTrackerMutation'
        }
      };
    }
  }
};
</script>
