<script>
import { getDigitalAdUnits } from '@/graphql/digital/getDigitalAdUnits.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],

  props: {
    propertyId: {
      type: String,
      required: true
    }
  },

  query() {
    return {
      query: getDigitalAdUnits,
      variables: {
        property_id: this.propertyId,
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
