<template>
  <div class="loader-menu">
    <div v-for="n in count" :key="`loader-menu-${n}`">
      <loader-text :lines="1" dark />
      <div class="pad-left">
        <loader-text :lines="3" />
      </div>
    </div>
  </div>
</template>

<script>
// Bug w/ ES6 loader, cannot find LoaderText for some reason (when importing w/ statement above)
import LoaderText from '@/components/Core/Loaders/LoaderText';

export default {
  components: {
    LoaderText
  },
  props: {
    count: {
      type: Number,
      default: 2
    }
  }
};
</script>
