<template>
  <el-button :class="buttonClass" class="hover:opacity-75 p-4 ml-3">
    <div class="h-full flex justify-between">
      <icon v-if="isTest" :icon="testIcon" class="mr-1" />
      <icon v-if="icon" :icon="icon" class="mr-3" />
      <span class="name">{{ name }}</span>
    </div>
  </el-button>
</template>

<script>
import { lab as testIcon } from '@/vendor/icons';

export default {
  props: {
    isTest: Boolean,
    name: {
      type: String,
      required: true
    },
    icon: { type: Object, default: null },
    buttonClass: {
      type: String,
      default: null
    }
  },
  data() {
    return { testIcon };
  }
};
</script>
