import { objectMap } from '@/utils/helpers';

export const DocumentLineItemType = {
  DISCOUNT: {
    label: 'Discount'
  },
  FEE: {
    label: 'Fee'
  },
  SALE: {
    label: 'Sale'
  }
};

export const DocumentLineItemTypeKeys = objectMap(DocumentLineItemType, key => [
  key,
  key
]);

export const DocumentLineItemTypeLabels = objectMap(
  DocumentLineItemType,
  (key, value) => [key, value.label]
);
