<script>
import { getSupplierAds } from '@/graphql/supplier/queries.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    pager: {
      type: Object,
      required: true
    }
  },

  query(props) {
    return {
      query: getSupplierAds,
      variables: {
        supplier_id: this.$store.getters['auth/supplierId'],
        ...(props.pager || this.$store.getters['ads/pager'])
      }
    };
  }
};
</script>
