import { download } from '@/vendor/download';

export async function exportCsv(name, url) {
  const response = await fetch(url).then(response => {
    if (!response.ok) {
      throw new Error('HTTP error, status = ' + response.status);
    }
    return response;
  });
  let data = await response.blob();
  download(data, name, 'csv');
}
