<template>
  <div class="user-notifications-subscription">
    <user-notifications-query @data="onData">
      <template v-slot="{ isLoading }">
        <user-new-notification-subscription @data="onSubscriptionData" />
        <slot :is-loading="isLoading" :notifications="notifications" />
      </template>
    </user-notifications-query>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('UserNotificationsSubscription', {
  data() {
    return {
      notifications: []
    };
  },
  methods: {
    onSubscriptionData(data) {
      if (data.newNotification) {
        this.notifications.push(data.newNotification);
      }
    },
    onData(user) {
      if (user && user.pendingNotifications) {
        this.notifications = user.pendingNotifications;

        this.$emit('data', this.notifications);
      }
    }
  }
});
</script>
