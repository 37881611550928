<script>
import { buyerAcceptOrder } from '@/graphql/order/mutations.graphql';
import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],
  props: {
    buyerId: {
      type: String,
      default: null
    }
  },

  mutation() {
    return {
      mutations: {
        async acceptChanges(order, input) {
          return this.post(buyerAcceptOrder, { id: order.id, input });
        }
      },
      variables: {
        buyer_id: this.buyerId || this.$store.getters['auth/buyerId']
      }
    };
  }
};
</script>
