const PageData = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageData');
const PageDates = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageDates');
const PageDevGuide = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageDevGuide');
const PageInstallation = () =>
  import(
    /* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageInstallation'
  );
const PageDevOps = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageDevOps');
const PagePhpUnit = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PagePhpUnit');
const PageElementUI = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageElementUI');
const PageIcons = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageIcons');
const PageLogging = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageLogging');
const PageSortAndFilter = () =>
  import(
    /* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageSortAndFilter'
  );
const PageTailwind = () =>
  import(/* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageTailwind');
const PageInvitesAndContacts = () =>
  import(
    /* webpackChunkName: 'DevGuide' */ '@/components/Dev/PageInvitesAndContacts'
  );
const TheDevGuideLayout = () =>
  import(
    /* webpackChunkName: 'DevGuide' */ '@/components/Dev/TheDevGuideLayout'
  );

export default rootPath => {
  return {
    path: rootPath,
    component: TheDevGuideLayout,
    meta: { auth: true },
    children: [
      {
        path: 'home',
        name: 'dev.guide',
        component: PageDevGuide,
        meta: { title: "Developer's Guide" }
      },
      {
        path: 'installation',
        name: 'dev.installation',
        component: PageInstallation,
        meta: { title: 'Platform Installation' }
      },
      {
        path: 'php-unit',
        name: 'dev.php-unit',
        component: PagePhpUnit,
        meta: { title: 'PHP Unit Integration' }
      },
      {
        path: 'tailwind',
        name: 'dev.tailwind',
        component: PageTailwind,
        meta: { title: 'Tailwind CSS w/ flytedesk' }
      },
      {
        path: 'element-ui',
        name: 'dev.element-ui',
        component: PageElementUI,
        meta: { title: 'Element UI Components' }
      },
      {
        path: 'dates',
        name: 'dev.dates',
        component: PageDates,
        meta: { title: 'Dates and Date Pickers' }
      },
      {
        path: 'sortAndFilter',
        name: 'dev.sortAndFilter',
        component: PageSortAndFilter,
        meta: { title: 'Sorting and Filtering' }
      },
      {
        path: 'logging',
        name: 'dev.logging',
        component: PageLogging,
        meta: { title: 'Logging' }
      },
      {
        path: 'devops',
        name: 'dev.devops',
        component: PageDevOps,
        meta: { title: 'Development Operations' }
      },
      {
        path: 'icons',
        name: 'dev.icons',
        component: PageIcons,
        meta: { title: 'flytedesk Icons' }
      },
      {
        path: 'data',
        name: 'dev.data',
        component: PageData,
        meta: { title: 'Data' }
      },
      {
        path: 'invitesAndContacts',
        name: 'dev.invitesAndContacts',
        component: PageInvitesAndContacts,
        meta: { title: 'Invites and Contacts' }
      }
    ]
  };
};
