let intercomScript = null;

export function injectIntercom(intercomSettings) {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', intercomSettings);
  } else {
    if (!intercomScript) {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;

      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/ht6eyst8';
      intercomScript = d.getElementsByTagName('script')[0];
      intercomScript.parentNode.insertBefore(s, intercomScript);
    }

    // Keep trying to inject intercom until there is an update
    setTimeout(() => injectIntercom(intercomSettings), 300);
  }
}

export function removeIntercom() {
  if (intercomScript) {
    intercomScript.remove();
    intercomScript = null;
    delete window.Intercom;

    let icContainer = document.getElementById('intercom-container');
    let icCss = document.getElementById('intercom-css-container');
    let icFrame = document.getElementById('intercom-frame');
    let icApps = document.getElementsByClassName('intercom-lightweight-app');

    if (icContainer) {
      icContainer.remove();
    }

    if (icCss) {
      icCss.remove();
    }

    if (icFrame) {
      icFrame.remove();
    }

    if (icApps) {
      for (let app of icApps) {
        app.remove();
      }
    }
  }
}
