<template>
  <div v-if="selectedIcon" class="status-icon" :class="className">
    <template v-if="loading">
      <loading-icon />
    </template>

    <div v-else class="status-icon-content">
      <icon :icon="selectedIcon.icon" :class="{ 'icon-xl': !inline }" />
      <div class="name leading-normal">
        {{ name }}
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcon from '@/components/Core/Loading/LoadingIcon';

/**
 * Renders a uniform Icon for any set of statuses (ie: Campaigns, Ads, Line Items, etc.)
 * You can either set an individual icon with the 'icon' prop, or you can set a dynamic icon
 * based on the current status by providing the 'icons' and 'status' props. Changing 'status'
 * will change the selected icon
 *
 * 'inline' - this will render the status icon as inline text, instead of the block form typically
 * used in the tables
 */
export default {
  components: { LoadingIcon },
  props: {
    icon: { type: Object, default: null },
    icons: { type: Object, default: null },
    inline: Boolean,
    status: { type: String, default: () => null },
    statuses: { type: Object, default: null },
    loading: Boolean
  },

  computed: {
    selectedIcon() {
      if (this.loading) {
        return true;
      }

      if (this.icon) {
        return this.icon;
      }

      if (this.icons) {
        return (
          this.icons[this.status] ||
          Object.values(this.icons).find(i => i.value === this.status)
        );
      }

      return false;
    },

    name() {
      let name = this.selectedIcon.name;

      if (!this.inline && name && name.length > 12) {
        name = name.replace(/ /, '\n');
      }

      return name;
    },

    className() {
      let className = this.selectedIcon ? this.selectedIcon.class : '';

      if (this.inline) {
        className += ' icon-inline';
      }

      return className;
    }
  }
};
</script>

<style scoped lang="scss">
.status-icon {
  .status-icon-content {
    text-align: center;
  }

  &.icon-inline {
    display: inline-block;

    .status-icon-content {
      display: flex;
      align-items: center;
      justify-content: left;

      .name {
        white-space: nowrap;
        flex-grow: 1;
        margin-left: 0.3em;
      }
    }
  }
}
</style>
