<template>
  <div class="loader-content" :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    rounded: Boolean,
    centered: Boolean,
    animated: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classObject() {
      return {
        'loader-is-rounded': this.rounded,
        'loader-is-centered': this.centered,
        'loader-is-animated': this.animated
      };
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/components/loaders';
</style>
