// Child Routes
import DevRoutes from './dev';
import mediaKitRoutes from './mediaKitRoutes';

const TheBlankLayout = () =>
  import(
    /* webpackChunkName: 'Admin', webpackPrefetch: true */ '@/components/Admin/TheBlankLayout'
  );
const PageCampaignTrackerFast = () =>
  import(
    /* webpackChunkName: 'Admin', webpackPrefetch: true */ '@/components/Admin/Campaigns/PageCampaignTrackerFast'
  );
const PageFinanceTracker = () =>
  import(
    /* webpackChunkName: 'AdminFinance', webpackPrefetch: true */ '@/components/Admin/Accounting/FinanceTracker/PageFinanceTracker'
  );
const PageFeatureFlags = () =>
  import(
    /* webpackChunkName: 'PageFeatureFlags' */
    '@/modules/Admin/FeatureFlags/PageFeatureFlags'
  );
const PageLocations = () =>
  import(
    /* webpackChunkName: 'Admin', webpackPrefetch: true */ '@/components/Admin/Dashboard/PageLocations'
  );
const TheLayoutAdmin = () =>
  import(
    /* webpackChunkName: 'Admin', webpackPrefetch: true */ '@/components/Admin/TheLayoutAdmin'
  );

export default rootPath => {
  return {
    path: rootPath,
    component: TheBlankLayout,
    meta: { auth: true, roles: ['super_admin'] },
    children: [
      {
        path: '',
        name: 'admin',
        redirect: {
          name: 'admin.campaigns-fast'
        },
        component: TheLayoutAdmin,
        children: [
          {
            path: 'campaigns-fast',
            name: 'admin.campaigns-fast',
            component: PageCampaignTrackerFast
          },
          {
            path: 'finance-tracker',
            name: 'admin.finance-tracker',
            component: PageFinanceTracker,
            meta: {
              title: 'Finances'
            }
          },
          {
            path: 'locations',
            name: 'admin.locations',
            component: PageLocations
          },
          {
            path: 'feature-flags',
            name: 'dev.feature-flags',
            component: PageFeatureFlags,
            meta: {
              title: 'Feature Flags'
            }
          },
          DevRoutes('/dev')
        ]
      },
      mediaKitRoutes('/admin/mediakit')
    ]
  };
};
