<script>
import { mapGetters } from 'vuex';
import { injectIntercom, removeIntercom } from '@/vendor/intercom';
import { renderless } from '@/utils/helpers';

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
      supplier: 'auth/supplier',
      buyer: 'auth/isNationalBuyer',
      isAdmin: 'auth/isAdmin',
      supplierId: 'auth/supplierId',
      buyerId: 'auth/buyerId',
      isSupplier: 'auth/isSupplier',
      isNationalBuyer: 'auth/isNationalBuyer',
      campuses: 'auth/campuses'
    }),

    isIntercomEnabled() {
      // Only enable for Production environments
      // Only enable for Pubs and Natl Buyers (FDEV-2321 && FDEV-3137)
      return (
        process.env.VUE_APP_ENABLE_INTERCOM === 'true' &&
        !this.isAdmin &&
        (this.isSupplier || this.isNationalBuyer)
      );
    },

    intercomSettings() {
      // eslint-disable-next-line
      let app_id = 'ht6eyst8';

      if (this.user) {
        const supplierPrimaryAddress =
          (this.supplier && this.supplier.primaryAddress) || {};

        const buyerPrimaryAddress =
          (this.buyer && this.buyer.primaryAddress) || {};

        let campus = this.campuses[0] || {};

        return {
          app_id,

          // User Info
          name: this.user.name,
          email: this.user.email,
          created_at: this.user.created_at,

          // Supplier Info
          supplier_id: this.supplierId,
          organization_type: this.supplier
            ? this.supplier.organization_type
            : null,
          supplier_name: this.supplier ? this.supplier.display_name : null,
          is_member: this.supplier ? !!this.supplier.member_since : null,
          member_since: this.supplier ? this.supplier.member_since : null,

          // Primary Address
          supplier_address: supplierPrimaryAddress.address || null,
          supplier_city: supplierPrimaryAddress.city || null,
          supplier_state: supplierPrimaryAddress.state || null,
          supplier_zip_code: supplierPrimaryAddress.zip_code || null,

          // Campus Info
          campus_name: campus.name,
          campus_city: campus.city,
          campus_state: campus.state,
          campus_zip_code: campus.zip_code,

          // Buyer Info
          buyer_id: this.buyerId,
          buyer_company: this.buyer ? this.buyer.buyer_company : null,
          buyer_type: this.buyer ? this.buyer.campaign_type_id : null,

          // Buyer Primary Address
          buyer_address: buyerPrimaryAddress.address || null,
          buyer_city: buyerPrimaryAddress.city || null,
          buyer_state: buyerPrimaryAddress.state || null,
          buyer_zip_code: buyerPrimaryAddress.zip_code || null
        };
      } else {
        return { app_id };
      }
    }
  },
  watch: {
    isIntercomEnabled() {
      if (this.isIntercomEnabled) {
        injectIntercom(this.intercomSettings);
      } else {
        removeIntercom();
      }
    }
  },
  mounted() {
    if (this.isIntercomEnabled) {
      injectIntercom(this.intercomSettings);
    }
  },
  render(c) {
    return renderless.call(this, c);
  }
};
</script>
