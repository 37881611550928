<script>
import {
  rejectSupplierMemeberRequest,
  removeSupplierMember,
  requestSupplierMemberAccess,
  sendSupplierMemberInvite,
  setSupplierContacts,
  setSupplierMemberRole,
  setSupplierMemberTags
} from '@/graphql/supplier/supplierTeamMutations.graphql';

import { MutationHelper } from '@/components/Core/Helpers';

export default {
  mixins: [MutationHelper],

  mutation() {
    return {
      mutations: {
        requestSupplierMemberAccess(email) {
          this.post(requestSupplierMemberAccess, { email });
        },
        sendSupplierMemberInvite(input) {
          this.refetchCache('getSupplierTeam');
          this.post(sendSupplierMemberInvite, { input });
        },
        rejectSupplierMemeberRequest(invite_id) {
          this.refetchCache('getSupplierTeam');
          this.post(rejectSupplierMemeberRequest, { invite_id });
        },
        setSupplierMemberRole(user_id, role) {
          this.refetchCache('getSupplierTeam');
          this.post(setSupplierMemberRole, { user_id, role });
        },
        setSupplierMemberTags(user_id, tags) {
          this.refetchCache('getSupplierTeam');
          this.post(setSupplierMemberTags, { user_id, tags });
        },
        setSupplierContacts(user_id, contactTypes) {
          this.refetchCache('getSupplierTeam');
          this.post(setSupplierContacts, { user_id, contactTypes });
        },
        removeSupplierMember(user_id) {
          this.refetchCache('getSupplierTeam');
          this.post(removeSupplierMember, { user_id });
        }
      },
      variables: {
        supplier_id:
          this.$store.getters['auth/supplierId'] ||
          this.$store.getters['auth/signupInfo'].supplier.uuid
      }
    };
  }
};
</script>
