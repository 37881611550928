<script>
import { getAdShop } from '@/graphql/adshop/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    buyerId: { type: String, default: null },
    slug: { type: String, default: null }
  },

  query(props) {
    return {
      query: getAdShop,
      variables: {
        buyer_id: props.buyerId || undefined,
        slug: this.$store.getters['adShop/slug'] || props.slug
      }
    };
  }
};
</script>
