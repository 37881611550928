<script>
import {
  AcceptAdShopTerms,
  updateAdShop
} from '@/graphql/adshop/mutations.graphql';
import { MutationHelper } from '@/components/Core/Helpers';

const adShopFields = {
  supplier_id: String,
  is_enabled: Boolean,
  theme_color: String,
  use_logo_on_billing_docs: Boolean,
  allow_credit_card_payment: Boolean,
  require_verifications: Boolean,
  shop_name: String,
  description: String,
  email: String,
  phone: String,
  name: String,
  header_photo_id: String,
  profile_photo_id: String,
  policies_file_id: String
};

export default {
  mixins: [MutationHelper],
  props: {
    adShop: {
      type: Object,
      required: true
    }
  },
  mutation() {
    return {
      mutations: {
        async updateAdShop(data) {
          if (!data.supplier_id && data.supplier) {
            data.supplier_id = data.supplier.id;
          }

          let input = this.castFields(data, adShopFields);

          await this.post(updateAdShop, { input });

          // Make sure we get all updated messages, and the terms_acceptance_date if we are enabling the Ad Shop
          if (input.is_enabled) {
            this.$store.dispatch('auth/getUser');
          }
        },

        acceptAdShopTerms(accepted) {
          return this.post(AcceptAdShopTerms, { accepted });
        }
      },
      variables: {
        supplier_id: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
